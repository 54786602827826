import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';

import { UserProvider } from '@app/providers';
import { UserData } from '@app/models';
import { decrypt, encrypt } from '@app/lib';
import { AuthService } from '@app/services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    public loginForm!: UntypedFormGroup;

    public returnUrl: string = '/counting';
    public messageError: string = '';

    public submitted: boolean = false;
    public loading: boolean = false;
    public isAlreadyLocalRegistered: boolean = false;
    public isError: boolean = false;
    public isBadLogin: boolean = false;

    private userDataSub!: Subscription;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userProvider: UserProvider,
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.userDataSub = this.userProvider
            .isUserDataExist()
            .subscribe((userData: UserData) => {
                this.loginForm = this.formBuilder.group({
                    login: [userData.login, Validators.required],
                    password: [
                        decrypt(userData.password as string),
                        Validators.required,
                    ],
                    rememberMe: [userData.rememberOption],
                });
            });
    }

    public updateFormByKey(key: string, value: any) {
        this.loginForm.patchValue({ [key]: value });
    }

    public onSubmit(): void {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;

        this.authService
            .login(this.loginForm.value.login, this.loginForm.value.password)
            .subscribe(
                (value: any) => {
                    const _callback = () => {
                        this.router.navigate([this.returnUrl]);
                    };
                    this.userProvider.updateUserdata(
                        {
                            login: this.loginForm.value.rememberMe
                                ? this.loginForm.value.login
                                : '',
                            password: encrypt(
                                this.loginForm.value.rememberMe
                                    ? this.loginForm.value.password
                                    : ''
                            ),
                            rememberOption: this.loginForm.value.rememberMe,
                            jwt: value['token'],
                            isLogged: true,
                            isFirstStart: false,
                        },
                        _callback.bind(this)
                    );
                },
                (e: any) => {
                    switch (e.status) {
                        case 401:
                            this.loading = false;
                            this.isError = true;
                            this.messageError = `error.internalError`;
                            this.hideErrorMessage();
                            break;
                        case 403:
                            this.loading = false;
                            this.isError = true;
                            this.messageError = `error.incorrectData`;
                            this.hideErrorMessage();
                            break;
                        default:
                            this.loading = false;
                            this.isError = true;
                            this.messageError = `error.incorrectData`;

                            this.hideErrorMessage();
                            break;
                    }
                }
            );
    }

    public resetPassword() {
        this.router.navigate(['login/reset-password']);
    }

    public hideErrorMessage() {
        setTimeout(() => {
            this.isError = false;
        }, 5000);
    }

    ngOnDestroy(): void {
        this?.userDataSub.unsubscribe();
    }
}
