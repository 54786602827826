import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({
    name: 'labelsHoursPlusOne',
})
export class LabelsHoursPlusOne implements PipeTransform {
    transform(hour?: string): number {
        return Number(hour) + 1;
    }
}

@Pipe({
    name: 'labelsDay',
})
export class LabelsDay implements PipeTransform {
    transform(day: string): String {
        return moment(day).format('dd Do MMM');
    }
}
