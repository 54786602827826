import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CustomDateObject } from '@app/models/Calendar';
import {
    DateProvider,
    ModalProvider,
    StoreProvider,
    SwiperProvider,
} from '@app/providers';
import { AppStore } from '@app/models';
import { Subscription } from 'rxjs';
import moment from 'moment';
@Component({
    selector: 'app-calendar-slider',
    templateUrl: './calendar-slider.component.html',
    styleUrls: ['./calendar-slider.component.scss'],
})
export class CalendarSliderComponent
    implements OnInit, AfterViewInit, OnChanges
{
    @Input() dates?: CustomDateObject;
    @Input() lightMode?: boolean = false;

    @ViewChild('actionBarSlider') actionBarSlider!: ElementRef;

    public isToday = false;
    public isFormatDay = false;
    public hour!: string;
    public min!: string;

    public app!: AppStore;
    private $appSubscription!: Subscription;

    public allowGoNext = true;
    public allowGoPrev = true;

    constructor(
        private modalProvider: ModalProvider,
        private dateProvider: DateProvider,
        private $store: StoreProvider,
        private swiperProvider: SwiperProvider
    ) {}

    ngOnInit() {
        this.$appSubscription = this.$store
            .listen('app')
            .subscribe((app: AppStore) => {
                this.app = app;
                this.displayHourIsToday();
                this.allowGoNext = this.blocFutureDates();
                this.allowGoPrev = this.blocPasstDates();
            });
    }

    ngOnChanges() {
        this.$appSubscription = this.$store
            .listen('app')
            .subscribe((app: AppStore) => {
                this.app = app;
                this.displayHourIsToday();
                this.allowGoNext = this.blocFutureDates();
                this.allowGoPrev = this.blocPasstDates();
            });
    }

    public displayHourIsToday() {
        const isToday = this.dateProvider.checkIfToday(
            this.dates!.select!,
            this.dates!.current!.start.asUnix
        );

        if (isToday) {
            this.isToday = true;
            this.hour = moment().tz('Europe/Paris').format('HH[h]mm');
            if (this.app.props?.dates?.select === 'day') {
                this.isFormatDay = true;
            } else {
                this.isFormatDay = false;
            }
        } else {
            this.isToday = false;
            this.isFormatDay = false;
        }
    }

    public openCalendar(): void {
        this.modalProvider.open('calendar');
    }

    ngAfterViewInit(): void {
        this.swiperProvider.create(this.actionBarSlider.nativeElement, {
            freeMode: true,
            followFinger: true,
            preventClicks: true,
            slideToClickedSlide: false,
        });
        this.listenSwiper();
    }

    public goPrev() {
        return this.dateProvider.goStepLeft(
            this.dates as CustomDateObject,
            this.app.props?.dates?.type
        );
    }

    public goNext() {
        return this.dateProvider.goStepRight(
            this.dates as CustomDateObject,
            this.app.props?.dates?.type
        );
    }

    public emitDateObject(dates: any) {
        this.$store.emitReloadAll(true);

        this.$store.emit({
            app: {
                props: {
                    dates: {
                        ...this.dates,
                        current: {
                            start: this.dateProvider.fromDateToStore(
                                dates.current.start
                            ),
                            end: this.dateProvider.fromDateToStore(
                                dates.current.end,
                                true
                            ),
                        },
                        versus: {
                            start: this.dateProvider.fromDateToStore(
                                dates.versus.start
                            ),
                            end: this.dateProvider.fromDateToStore(
                                dates.versus.end,
                                true
                            ),
                        },
                    },
                },
            },
        } as AppStore);
    }

    private listenSwiper() {
        this.actionBarSlider.nativeElement.swiper.on(
            'touchEnd',
            this.swipperLogic.bind(this)
        );
    }

    private swipperLogic(ctx: any) {
        var out: any = false;
        if (ctx.swipeDirection === 'prev') {
            out = this.goPrev();
        }
        if (ctx.swipeDirection === 'next') {
            out = this.goNext();
        }
        if (out) {
            this.emitDateObject(out);
        }
    }

    public blocFutureDates() {
        let today = moment();
        let date = moment(this.dates?.current?.start.asMoment);

        return this.dateProvider.verifDate(
            date,
            today,
            this.app.props!.dates!.select!
        );
    }

    public blocPasstDates() {
        let date = moment(this.dates?.current?.start.asMoment);
        let installDate = moment(
            this.app.props?.selectedLocalBusiness?.instalationDate?.asMoment
        );

        return this.dateProvider.verifDate(
            date,
            installDate,
            this.app.props!.dates!.select!
        );
    }
}
