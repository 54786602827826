import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DateProvider } from '../providers/date.provider';

@Injectable({ providedIn: 'root' })
export class JourneyDetailsService {
    constructor(private http: HttpClient, private dateProvider: DateProvider) {}
    public getPassageHeatmap(boxId: number, date: string) {
        date = this.dateProvider.removeZero(date);

        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/crossingheatmap/video/${date}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }
    public getAnimatedStopHeatmap(boxId: number, date: string) {
        date = this.dateProvider.removeZero(date);

        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/stops/video/${date}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }

    public getDominantOrientationsHeatmap(boxId: number, date: string) {
        date = this.dateProvider.removeZero(date);

        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/dominantorientations/static/${date}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }
    public getZoneToZone(boxId: number, endDate: number) {
        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/backgrounds/lastmidday?_format=json&data=1&end=${endDate}`
        );
    }

    public getAllTransitionsForZones(
        boxId: number,
        range: any,
        reverse: boolean
    ) {
        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/alltransitions?begin=${range.begin}&end=${range.end}&reversed=${reverse}`
        );
    }

    public getStaticPassage(boxId: number, date: string) {
        date = this.dateProvider.removeZero(date);

        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/crossingheatmap/static/${date}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }

    public getTrajectories(boxId: number, date: string) {
        date = this.dateProvider.removeZero(date);

        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/trajectories/video/${date}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }

    public getStaticStops(boxId: number, date: string) {
        date = this.dateProvider.removeZero(date);

        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/stops/static/${date}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }

    public getHeatmapAnimation(boxId: number, date: string) {
        date = this.dateProvider.removeZero(date);

        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/heatmaps/video/${date}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }

    public getHeatmapStatic(boxId: number, date: string) {
        date = this.dateProvider.removeZero(date);

        return this.http.get(
            `${environment.backendDomain}/boxes/${boxId}/heatmaps/static/${date}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }
}
