<div
    class="d-flex flex-column align-items-center justify-content-center row p-0 m-0"
>
    <app-button-default
        content="button.back"
        _class="btn-light m-0 p-0 mb-5"
        logo="/assets/svg/arrow/circle-arrow-left.svg"
        (btnClick)="back()"
    ></app-button-default>
    <div class="col-12">
        <div class="title">
            <div class="dark h3">{{ 'resetPassword' | translate }}</div>
        </div>
    </div>
    <div class="col-12">
        <app-input-form-default
            placeHolder="input.nameOrEmail"
            (inputChange)="updateFormByKey('usernameOrEmail', $event)"
        ></app-input-form-default>
    </div>
    <div class="col-12 pb-2">
        <button type="button" class="btn" (click)="onSubmit()">
            {{ 'send' | translate }}
        </button>
    </div>
    <div class="col-12">
        <div class="long-text text-start">
            {{ 'resetPasswordInfo' | translate }}
        </div>
    </div>
</div>
