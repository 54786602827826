import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MathProvider {
    constructor() {}

    public getPercent(v1: any, v2: any) {
        return this.isARealNumber((v1 / v2) * 100);
    }

    public getPercentOf(percent: number, total: number) {
        return this.isARealNumber((percent / 100) * total);
    }

    public getVariationRate(v1: any, v2: any) {
        if (v1 === 0 && v2 !== 0) {
            return 100;
        }

        return this.isARealNumber(((v2 - v1) / v1) * 100);
    }

    public getSubstract(v1: any, v2: any) {
        return this.isARealNumber(v2 - v1);
    }

    public isARealNumber(n: number) {
        if (!n) {
            return 0;
        }
        if (n == Number.POSITIVE_INFINITY || n == Number.NEGATIVE_INFINITY) {
            return 0;
        }
        return n;
    }
}
