import { of } from 'rxjs';

export function getUser() {
    return of({
        id: 1,
        username: 'Foo',
        roles: ['ROLE_FOO', 'ROLE_BAR'],
    });
}

export function postResetPassword() {
    return of(true);
}

export function postAskNewPassword() {
    return of(true);
}

export function postNewPasswordToken() {
    return of(true);
}

export function getLocalbusiness() {
    return of({
        id: 1,
        username: 'Foo',
        roles: ['ROLE_FOO', 'ROLE_BAR'],
    });
}

export function getFavorite() {
    of({
        localBusiness: {
            id: 1,
            legalName: 'foo',
            timezone: 'foo/bar',
            organization: 1,
            enabled: false,
            zipcode: 'foo',
            city: 'bar',
            background_2d: 'foo #bar',
            attractivityEnabled: true,
            groupsCountingEnabled: true,
            localBusinessFiancial: { relational_id: 1, type: 'foo:bar' },
        },
        settings: {
            IsAttractivityVideo: true,
            openingCalendar: {
                Monday: { openingTime: 'foo:bar', closingTime: 'foo:bar' },
                Tuesday: { openingTime: 'foo:bar', closingTime: 'foo:bar' },
                Wednesday: {
                    openingTime: 'foo:bar',
                    closingTime: 'foo:bar',
                },
                Thursday: {
                    openingTime: 'foo:bar',
                    closingTime: 'foo:bar',
                },
                Friday: { openingTime: 'foo:bar', closingTime: 'foo:bar' },
                Saturday: {
                    openingTime: 'foo:bar',
                    closingTime: 'foo:bar',
                },
                Sunday: 'foo',
            },
        },
    });
}

export function getLocalbusinesses() {
    return of({
        localBusiness: [
            {
                id: 1,
                legalName: 'foo',
                timezone: 'foo/bar',
                organization: 1,
                enabled: true,
                zipcode: 'foo',
                attractivityEnabled: true,
                groupsCountingEnabled: true,
            },
            {
                id: 2,
                legalName: 'foo',
                timezone: 'foo/bar',
                organization: 2,
                enabled: true,
                zipcode: 'foo',
                attractivityEnabled: true,
                groupsCountingEnabled: true,
            },
        ],
        boxes: [
            {
                localBusiness: 1,
                id: 1,
                name: 'foo',
                lastLogin: 'bar',
                enabled: true,
                visible: true,
            },
            {
                localBusiness: 2,
                id: 2,
                name: 'foo',
                lastLogin: 'bar',
                enabled: true,
                visible: true,
            },
        ],
    });
}

export function getOrganizations() {
    return of([
        { id: 1, legalName: 'foo' },
        { id: 2, legalName: 'bar' },
    ]);
}
