import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppStore } from '@app/models';
import { LocalBusinesses } from '@app/models/LocalBusiness';
import { ModalProvider, StoreProvider } from '@app/providers';
import { LocalBusinessesServices } from '@app/services';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
    selector: 'app-modal-localbusinesses-search',
    templateUrl: './modal-localbusinesses-search.component.html',
    styleUrls: ['./modal-localbusinesses-search.component.scss'],
})
export class ModalLocalbusinessesSearchComponent {
    public $app: Observable<AppStore> = this.$store.listen('app');
    constructor(
        private $store: StoreProvider,
        private modalProvider: ModalProvider,
        private localBusinessesService: LocalBusinessesServices,
        private router: Router
    ) {}

    public selectLocalBusiness(localBusiness: LocalBusinesses) {
        firstValueFrom(
            this.localBusinessesService.getHours(localBusiness)
        ).then((time) => {
            localBusiness.openingCalendar = time;
            this.$store.emitReloadAll(true);
            if (localBusiness.localBusinessFiancial === undefined) {
                localBusiness.localBusinessFiancial = false;
            }
            this.$store.emit({
                app: { props: { selectedLocalBusiness: localBusiness } },
            });
            this.modalProvider.close('localBusinesses');
            localStorage.removeItem('selectedBox');
            if (this.router.url === '/setting') {
                this.router.navigate(['/counting']);
            }
        });
    }
    public close() {
        this.modalProvider.close('localBusinesses');
    }
}
