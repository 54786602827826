<div #swiper class="w-100 justify-content-between fadeInFast">
    <div swiperSlide class="swiper-wrapper">
        <div
            class="swiper-slide radius-tertiary center"
            *ngFor="let item of items"
        >
            <img
                src="/assets/svg/{{ item.icon }}.svg"
                height="20px"
                class="{{ item.icon }}"
            />
        </div>
    </div>
</div>
