import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CALENDAR_SELECT } from '@app/models/Calendar';

@Component({
    selector: 'app-calendar-select',
    templateUrl: './calendar-select.component.html',
    styleUrls: ['./calendar-select.component.scss'],
})
export class CalendarSelectComponent {
    @Input() calendar: string | any;
    @Output() calendarSelected = new EventEmitter();

    public calendars = Object.values(CALENDAR_SELECT);
}
