<div class="w-100 h-100 card_basic position-relative" [class]="class">
    <div class="card_header _mh-5" *ngIf="logoIvstore">
        <div class="d-flex flex-column">
            <p class="dark mb-1">Application</p>
            <div class="logo position-relative">
                <img
                    src="/assets/svg/logo-black.svg"
                    width="auto"
                    height="40px"
                    class="position-absolute start-0"
                />
            </div>
        </div>
    </div>
    <div class="card_content">
        <ng-content></ng-content>
    </div>
</div>
