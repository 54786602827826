import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-button-default',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() loading = false;
    @Input() content!: string;
    @Input() _class?: string;
    @Input() _contentClass?: string;
    @Input() logo?: string;

    @Output() btnClick: EventEmitter<string> = new EventEmitter();

    public onBtnClick() {
        this.btnClick.emit('click');
    }
}
