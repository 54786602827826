<div class="text-center">
    <div *ngIf="($isError | async) === false; else confirmResetError">
        <div *ngIf="($showWaitingToken | async) === true; else tokenVerified">
            <div class="d-flex flex-column align-items-center">
                <div class="p-1">
                    <div class="spinner-border" role="status"></div>
                </div>
                <div>{{ 'checkMailbox' | translate }}</div>
                <div>{{ 'waitingConfirmationLink' | translate }}</div>
            </div>
        </div>
        <ng-template #tokenVerified>
            <div class="d-flex flex-column align-items-center">
                <div class="p-1">
                    <div class="spinner-border" role="status"></div>
                </div>
                <div>{{ 'confirmationIdentitySuccessful' | translate }}</div>
                <div>{{ 'willBeAbleToChangePassword' | translate }}</div>
            </div>
        </ng-template>

        <div *ngIf="resetStatus === 3" class="red mt-3">
            {{ 'error.intenalErrorDuringResetPassword' | translate }}
        </div>
    </div>
    <ng-template #confirmResetError>
        <div class="d-flex flex-column p-1 w-100 text-center">
            <div class="col-12 py-2">
                {{ 'errorDuringSendingMail' | translate }}
            </div>

            <div>
                <div class="py-1 float-right">
                    <button
                        type="button"
                        class="btn btn_black"
                        (click)="quit()"
                    >
                        {{ 'quit' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>
