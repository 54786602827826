<div class="calendar_slider swiper" #actionBarSlider>
    <div
        class="swiper-wrapper text-center d-flex flex-row align-items-center justify-content-between"
    >
        <div
            class="arrow_left pe-1"
            (click)="emitDateObject(goPrev())"
            *ngIf="allowGoPrev; else avoidPrev"
        >
            <img height="23px" src="/assets/svg/arrow/arrow-left.svg" />
        </div>
        <ng-template #avoidPrev>
            <img
                class="opacity-0"
                height="23px"
                src="/assets/svg/arrow/arrow-left.svg"
            />
        </ng-template>
        <div class="selected_date mx-2" (click)="openCalendar()">
            <div class="selected_date_current ref-data fw-bold fadeInFast">
                {{
                    dates?.current?.start?.asMoment
                        | formatDateByPeriodType : dates?.select
                }}
            </div>
            <div
                class="selected_date_versus comp-data fw-bold pb-1 fadeInFast"
                *ngIf="!lightMode"
            >
                {{
                    dates?.versus?.start?.asMoment
                        | formatDateByPeriodType : dates?.select
                }}
                <span
                    class="selected_date_versus comp-data fw-bold pb-1 fadeInFast"
                    *ngIf="isToday && isFormatDay"
                >
                    ({{ this.hour }})</span
                >
            </div>
        </div>
        <div
            class="arrow_right ps-1"
            (click)="emitDateObject(goNext())"
            *ngIf="allowGoNext; else avoidNext"
        >
            <img height="23px" src="/assets/svg/arrow/arrow-right.svg" />
        </div>
        <ng-template #avoidNext>
            <img
                class="opacity-0"
                height="23px"
                src="/assets/svg/arrow/arrow-right.svg"
            />
        </ng-template>
    </div>
</div>
