import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { StoreProvider, UserProvider } from '@app/providers';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private $store: StoreProvider,
        private userProvider: UserProvider
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.userProvider.isUserDataExist().pipe(
            map((value: any) => {
                if (!value.isLogged && !value.jwt) {
                    this.router.navigate(['login']);
                    return false;
                }
                this.userProvider.updateUserdata({ isLogged: true });
                this.$store.emit({
                    app: {
                        props: {
                            user: {
                                isLogged: true,
                            },
                        },
                    },
                });
                return true;
            })
        );
    }
}
