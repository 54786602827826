<div
    class="container-fluid h-100 w-100 row p-0 m-0 text-right d-flex flex-row justify-content-start align-items-start"
>
    <div class="col-12 d-flex flex-column p-0">
        <div class="form-title w-100">
            <h3>{{ 'connexion' | translate }}</h3>
        </div>
        <form
            [formGroup]="loginForm"
            (ngSubmit)="onSubmit()"
            *ngIf="loginForm"
            class="w-100"
        >
            <app-input-form-default
                class="w-100"
                [value]="loginForm.value.login"
                placeHolder="input.username"
                (inputChange)="updateFormByKey('login', $event)"
            ></app-input-form-default>
            <app-input-form-default
                class="w-100"
                type="password"
                [value]="loginForm.value.password"
                placeHolder="input.password"
                (inputChange)="updateFormByKey('password', $event)"
            ></app-input-form-default>
            <div *ngIf="isError" class="red">
                {{ messageError | translate }}
            </div>
            <app-checkbox-default
                (cliked)="updateFormByKey('rememberMe', $event)"
                [checked]="loginForm.value.rememberMe"
                label="rememberMe"
            >
            </app-checkbox-default>
        </form>
    </div>

    <div class="col-12 p-0">
        <app-button-default
            (btnClick)="onSubmit()"
            [loading]="loading"
            [content]="'button.login'"
        ></app-button-default>
    </div>

    <div class="col-12 d-flex align-self-end p-0">
        <div
            class="pb-1"
            (click)="resetPassword()"
            style="top: auto; bottom: 0"
        >
            <a class="text-decoration-underline dark">
                {{ 'forgotPassword' | translate }}
            </a>
        </div>
    </div>
</div>
