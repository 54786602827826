<div class="form-label border-0 p-1 ms-0 ps-0 text-start">
    <input
        [id]="'static_' + label + '_' + _index"
        type="text"
        class="form-control input_default"
        [ngClass]="classCustom"
        aria-describedby="emailHelp"
        [type]="type"
        [placeholder]="placeHolder | translate"
        [value]="value"
        (input)="emit(getValue($event))"
    />
    <ng-template>
        <div
            class="valid-tooltip"
            *ngIf="showIsValidLabel"
            [innerHtml]="isValidFeedBack || label + '_valid'"
        ></div>
        <div
            class="invalid-tooltip"
            *ngIf="showIsInValidLabel"
            [innerHtml]="isInvalidFeedBack || label + '_invalid'"
        ></div>
    </ng-template>
</div>
