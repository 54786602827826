import { CALENDAR_TYPE } from '@app/models/Calendar';

export class ActionBarVersusCanvasProvider {
    private type!: keyof CALENDAR_TYPE;
    private el!: HTMLCanvasElement;
    private ctx!: CanvasRenderingContext2D;

    constructor() {}

    private init(type: keyof CALENDAR_TYPE, el: HTMLCanvasElement) {
        this.type = type;
        this.el = el;
        this.ctx = el.getContext('2d') as CanvasRenderingContext2D;
    }

    private render() {}
}
