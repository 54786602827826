import { Injectable } from '@angular/core';
import { ActionBarStore, AppStore, DefaultStore, Store } from '@app/models';
import { CALENDAR_SELECT, CALENDAR_TYPE } from '@app/models/Calendar';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import merge from 'ts-deepmerge';
import { InternRouter } from '@app/const';
import moment, { Moment } from 'moment';

@Injectable({ providedIn: 'root' })
export class StoreProvider {
    public current: Moment = moment();
    public versus: Moment = moment().subtract(1, 'week');
    public versusVs: Moment = moment().subtract(1, 'day');
    public lastTypeSelected: string = CALENDAR_TYPE.DAY;

    private startingPage = '';
    private startingSubNav: any = [];

    private redBg$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    private redBg: Observable<any> = this.redBg$.asObservable();

    private actionBar$: BehaviorSubject<ActionBarStore> =
        new BehaviorSubject<ActionBarStore>({
            isFull: true,
        });
    private actionBar: Observable<ActionBarStore> =
        this.actionBar$.asObservable();

    private reloadAll$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    private reloadAll: Observable<any> = this.redBg$.asObservable();

    private defaultStore = {
        app: {
            props: {
                user: {
                    roles: ['ROLE_USER'],
                },
                favorite: {},
                dates: {
                    current: {
                        start: {
                            asHuman: this.current
                                .format('dddd DD/MM/YYYY')
                                ._Capitalize(),
                            asMoment: this.current,
                            asUnix: this.toUnix(this.current),
                        },
                        end: {
                            asHuman: this.current
                                .format('dddd DD/MM/YYYY')
                                ._Capitalize(),
                            asMoment: this.current,
                            asUnix: this.toUnix(this.current, true),
                        },
                    },
                    versus: {
                        start: {
                            asHuman: this.versus
                                .format('dddd DD/MM/YYYY')
                                ._Capitalize(),
                            asMoment: this.versus,
                            asUnix: this.toUnix(this.versus),
                        },
                        end: {
                            asHuman: this.versus
                                .format('dddd DD/MM/YYYY')
                                ._Capitalize(),
                            asMoment: this.versus,
                            asUnix: this.toUnix(this.versus, true),
                        },
                    },
                    type: CALENDAR_TYPE.WEEK,
                    select: CALENDAR_SELECT.DAY,
                },
                selectedLocalBusiness: {},
                localBusinesses: [],
                detailNav: {
                    items: this.startingSubNav,
                    active: this.startingSubNav[
                        Math.trunc(this.startingSubNav.length / 2)
                    ],
                },
                layout: {
                    radiant: {
                        background: {
                            isNegative: true,
                        },
                        circle: {
                            isNegative: true,
                        },
                    },
                },
                mainDataIsFeteched: false,
            },
            type: 'Global App Store',
            reloadAll: true,
        },
    };

    private store: BehaviorSubject<Store | any> = new BehaviorSubject(
        this.defaultStore
    );
    private store$: Observable<Store | AppStore | DefaultStore | any> =
        this.store.asObservable();

    public emitRedBg(isRed: boolean) {
        this.redBg$.next(isRed);
    }
    public listenRedBg() {
        return this.redBg;
    }

    public emitActionBar(actionBar: ActionBarStore) {
        const combined = merge.withOptions(
            { mergeArrays: false },
            this.actionBar$.value,
            actionBar
        );
        this.actionBar$.next(combined as never);
    }
    public listenActionBar() {
        return this.actionBar;
    }

    public emitReloadAll(reloadAll: boolean) {
        this.reloadAll$.next(reloadAll);
    }
    public listenReloadAll() {
        return this.reloadAll;
    }

    public _getReloadAll() {
        return this.reloadAll$.value;
    }

    public refreshRoutingSubNav(useDefaultStore?: boolean) {
        const splittedUrl = document.URL.split('/');
        this.startingPage = '/' + splittedUrl[splittedUrl.length - 1];

        if (this.startingPage === '/' || this.startingPage === '/login') {
            this.startingPage = '/counting';
        }

        this.startingSubNav =
            InternRouter.find(
                (routingElement) => routingElement.url === this.startingPage
            )?.navDetail || [];

        const refreshedNav = {
            items: this.startingSubNav,
            active: this.startingSubNav[
                Math.trunc(this.startingSubNav.length / 2)
            ],
        };

        if (useDefaultStore) {
            this.defaultStore.app.props.detailNav = refreshedNav;

            this.store.next(this.defaultStore);
        } else {
            const _store = this.store.value;
            _store.app.props.detailNav = refreshedNav;

            this.store.next(_store);
        }
    }

    public emit(store: any): void {
        if (store?.app && !store?.app?.reloadAll) {
            store.app.reloadAll = false;
        }

        const combined = merge.withOptions(
            { mergeArrays: false },
            this.store.value,
            store
        );
        this.store.next(combined as never);
    }

    public listen(
        store?: string
    ): Observable<Store | DefaultStore | AppStore | any> {
        return store
            ? this.store$.pipe(
                  filter((value) => value && value[store]),
                  map((value) => value[store])
              )
            : this.store$.pipe(filter((value) => value));
    }

    public toUnix(instanciedDate: Moment, isEnd?: boolean) {
        const clonedDate = instanciedDate.clone();

        clonedDate.hour(isEnd ? 23 : 0);
        clonedDate.minute(0);
        clonedDate.second(0);
        clonedDate.millisecond(0);
        return clonedDate.valueOf() * 1000;
    }

    public clearStore(): void {
        Reflect.deleteProperty(this.store.value, 'app');
        this.store.next(this.defaultStore);
    }

    public $ = this.store;
}
