<div class="action_bar">
    <div class="row pt-2 px-2">
        <div class="col-2">
            <div class="counting_ivs_logo position-relative">
                <img
                    src="/assets/svg/logo-white.svg"
                    class="position-absolute start-0"
                    height="15px"
                />
            </div>
        </div>
        <div
            class="col-8"
            [ngClass]="{ 'offset-e-2': lightMode, 'pt-2': lightMode }"
        >
            <div class="d-flex flex-column align-items-center w-100">
                <div class="calendar_slider pb-1 w-100">
                    <app-calendar-slider
                        [dates]="dates"
                        [lightMode]="lightMode"
                    ></app-calendar-slider>
                </div>
                <div
                    (click)="openSelectLocalBusiness()"
                    class="action_info d-flex flex-column text-light align-items-center"
                >
                    <div class="actual_business fw-bold">
                        {{ localBusiness.legalName }}
                    </div>
                    <div class="data_type">
                        {{ 'routing.' + route | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!lightMode" class="col-2 _z-i-2">
            <div
                class="d-flex flex-column align-items-center justify-content-center fadeInFast"
            >
                <div class="calendar_menu pb-1">
                    <app-calendar-select
                        [calendar]="dates?.select"
                        (calendarSelected)="
                            setSelectedDate({ select: $event }, true)
                        "
                    ></app-calendar-select>
                </div>
                <div class="type_menu px-2 pt-1">
                    <app-calendar-type
                        [type]="dates?.type"
                        [calendarSelected]="dates?.select"
                        (typeSelected)="setSelectedDate({ type: $event }, true)"
                        *ngIf="show"
                    ></app-calendar-type>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center text" style="line-height: 0">
        <img
            *ngIf="app.props?.detailNav?.active?.name"
            src="/assets/svg/{{ dataInfoIcon }}.svg"
            height="20px"
            class="me-1"
        />
        <i class="info"
            >{{ 'detailPageCounting.title.' + dataInfoPath | translate }}
        </i>
    </div>
</div>
