import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { ICanvasOptions, Polyline } from 'fabric/fabric-impl';

@Injectable()
export class ZonesProvider {
    public strokeWidth: number;
    public strokeColor: string;
    public circleRadius: number;
    public circleFill: string;
    public lastPolyline!: any;
    public zoneTozoneArrowFirst = true;

    protected _canvas!: fabric.Canvas;

    protected _points: Array<fabric.Circle>;
    protected _polylines: Record<string, fabric.Polyline>;
    protected _arrows: Record<string, fabric.Polyline>;
    protected _texts: Record<string, fabric.IText>;
    protected _background!: fabric.Image;

    constructor() {
        this.strokeWidth = 4;
        this.strokeColor = '#000000';
        this.circleFill = '#0000ff';
        this.circleRadius = 2;

        this._points = new Array<fabric.Circle>();
        this._polylines = {};
        this._arrows = {};
        this._texts = {};
    }

    public getBackgroundSize(): {
        width: number;
        height: number;
    } {
        return this._background.getOriginalSize();
    }

    public setBackgroundScale(scale: any) {
        this._background.scaleX = this._background.scaleY = scale;
    }

    public generate(canvas: HTMLCanvasElement, opts?: ICanvasOptions) {
        this._canvas = new fabric.Canvas(canvas, {
            selection: false,
            preserveObjectStacking: true,
            hoverCursor: 'pointer',
            ...opts,
        });
        this._canvas.selection = false;
        return this._canvas;
    }

    public setCanvasSize(width: number, height: number, scale?: number) {
        this._canvas.setHeight(height * (scale ?? 1));
        this._canvas.setWidth(width * (scale ?? 1));
        this._canvas.calcOffset();
        Object.keys(this._polylines).forEach((polyKey) => {
            this._polylines[polyKey].setCoords();
        });
        this._canvas.renderAll();
    }

    public addPolyline(
        points: any,
        clear: boolean = true,
        color: string,
        fillColor: string,
        id: number
    ): Polyline {
        const polyLine: fabric.Polyline = new fabric.Polyline(points, {
            strokeWidth: 5,
            stroke: color,
            fill: fillColor,
            selectable: false,
            evented: true,
            opacity: 0.8,
            perPixelTargetFind: true,
            canvas: this._canvas,
            //@ts-ignore
            zoneId: id,
        });

        this._polylines[id] = polyLine;

        this._polylines[id].selectable = false;

        return this._polylines[id];
    }

    public addReactivePolyline(
        points: any,
        clear: boolean = true,
        color: string,
        fillColor: string,
        id: number,
        transitionsValues: any[],
        transitionsValuesInverse: any[]
    ): Polyline {
        const polyLine: fabric.Polyline = new fabric.Polyline(points, {
            strokeWidth: this.strokeWidth,
            stroke: color,
            fill: 'rgba(0, 0, 0, 0.2)',
            selectable: false,
            evented: true,
            opacity: 0.9,
            perPixelTargetFind: true,
            canvas: this._canvas,
            //@ts-ignore
            zoneId: id,
            transitionsValues: transitionsValues,
            transitionsValuesInverse: transitionsValuesInverse,
        });

        this._polylines[id] = polyLine;

        this._polylines[id].selectable = false;

        return this._polylines[id];
    }

    public addAndRender(element: any) {
        this._canvas.add(element);
        this._canvas.renderAll();
    }

    public render() {
        this._canvas.renderAll();
    }

    public background(base64: string, withoutUrl?: boolean) {
        return new Promise((res, err) => {
            if (!base64) {
                err('No image provided');
            }
            let img = new Image();

            img.onload = () => {
                this._background = new fabric.Image(img);
                this?._canvas?.setBackgroundImage(
                    this._background,
                    this._canvas?.renderAll.bind(this._canvas),
                    {}
                );

                res(true);
            };
            var imgUrl = base64;
            img.src = withoutUrl ? imgUrl : 'data:image/jpeg;base64,' + imgUrl;
        });
    }

    public backgroundBlobTo64(blob: Blob) {
        return new Promise((res, err) => {
            if (!blob) {
                err('No image provided');
            }
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                let img = new Image();
                img.width = 1000;
                img.height = 1000;
                img.src = reader.result as string;
                setTimeout(() => {
                    this._background = new fabric.Image(img);
                    this?._canvas?.setBackgroundImage(
                        this._background,
                        this._canvas?.renderAll.bind(this._canvas),
                        {}
                    );
                    res(true);
                });
            };
        });
    }

    public getNumberOfPoly(): number {
        return Object.keys(this._polylines).length;
    }

    public getIdZoneOfPoly(polyline: any): number {
        return polyline.zoneId;
    }

    public zoneToZone(polyline: any, inverse: boolean) {
        this.cleanArrow();

        if (
            polyline.transitionsValues !== null &&
            polyline.transitionsValues !== undefined
        ) {
            const centerOfOrigin =
                this._polylines[polyline.zoneId].getCenterPoint();

            inverse
                ? this.zoneToZoneArrowInverse(polyline, centerOfOrigin)
                : this.zoneToZoneArrow(polyline, centerOfOrigin);
        }
    }

    zoneToZoneArrow(polyline: any, centerOfOrigin: any) {
        Object.keys(polyline.transitionsValues).forEach((_id: string) => {
            const centerOfTarget = this._polylines[_id].getCenterPoint();
            const { arrow, text } = this.arrowAndText(
                centerOfOrigin.x,
                centerOfOrigin.y,
                centerOfTarget.x,
                centerOfTarget.y,
                polyline.zoneId,
                this._polylines[polyline.zoneId]!.stroke,
                this._polylines[_id]!.stroke,
                Math.trunc(polyline.transitionsValues[_id]),
                false
            );
            this._arrows[_id] = arrow;
            this._texts[_id] = text;
            this.addAndRender(arrow);
            this.addAndRender(text);
        });
    }

    zoneToZoneArrowInverse(polyline: any, centerOfOrigin: any) {
        Object.keys(polyline.transitionsValuesInverse).forEach(
            (_id: string) => {
                const centerOfTarget = this._polylines[_id].getCenterPoint();
                const { arrow, text } = this.arrowAndText(
                    centerOfTarget.x,
                    centerOfTarget.y,
                    centerOfOrigin.x,
                    centerOfOrigin.y,
                    polyline.zoneId,
                    this._polylines[polyline.zoneId]!.stroke,
                    this._polylines[_id]!.stroke,
                    Math.trunc(polyline.transitionsValuesInverse[_id]),
                    true
                );
                this._arrows[_id] = arrow;
                this._texts[_id] = text;
                this.addAndRender(arrow);
                this.addAndRender(text);
            }
        );
    }

    public arrowZoneToZone(
        points: any,
        clear: boolean = true,
        color: string,
        fillColor: string,
        id: number
    ) {
        const polyLine: fabric.Polyline = new fabric.Polyline(points, {
            strokeWidth: this.strokeWidth,
            stroke: color,
            fill: fillColor,
            selectable: false,
            evented: false,
            opacity: 0.6,
            perPixelTargetFind: true,
            canvas: this._canvas,
        });

        this._polylines[id] = polyLine;

        this._polylines[id].selectable = false;

        return this._polylines[id];
    }

    public destroyAndClean(): void {
        this._canvas.clear();

        this._polylines = {};
        this._arrows = {};
        this._points = new Array<fabric.Circle>();
    }

    public arrowAndText(
        fromx: any,
        fromy: any,
        tox: any,
        toy: any,
        id: any,
        colorFrom: any,
        colorTo: any,
        value: any,
        inverse: boolean
    ) {
        var angle = Math.atan2(toy - fromy, tox - fromx);
        var headlen = 10; // arrow head size
        // bring the line end back some to account for arrow head.
        tox = tox - headlen * Math.cos(angle);
        toy = toy - headlen * Math.sin(angle);
        // calculate the points.
        var points = [
            {
                x: fromx, // start point
                y: fromy,
            },
            {
                x: tox - (headlen / 4) * Math.cos(angle - Math.PI / 2),
                y: toy - (headlen / 4) * Math.sin(angle - Math.PI / 2),
            },
            {
                x: tox - headlen * Math.cos(angle - Math.PI / 3),
                y: toy - headlen * Math.sin(angle - Math.PI / 3),
            },
            {
                x: tox + headlen * Math.cos(angle), // tip
                y: toy + headlen * Math.sin(angle),
            },
            {
                x: tox - headlen * Math.cos(angle + Math.PI / 3),
                y: toy - headlen * Math.sin(angle + Math.PI / 3),
            },
            {
                x: tox - (headlen / 4) * Math.cos(angle + Math.PI / 2),
                y: toy - (headlen / 4) * Math.sin(angle + Math.PI / 2),
            },
            {
                x: fromx,
                y: fromy,
            },
        ];

        var shadow = new fabric.Shadow({
            color: 'black',
            blur: 11,
            offsetX: 1,
            offsetY: 1,
        });

        var pline = new fabric.Polyline(points, {
            fill: colorTo + '99',
            stroke: colorTo,
            opacity: 1,
            strokeWidth: 2,
            originX: 'left',
            originY: 'top',
            selectable: false,
            evented: false,
            shadow: shadow,
        });

        var gradient = new fabric.Gradient({
            type: 'linear',
            gradientUnits: 'pixels', // or 'percentage'
            coords: { x1: fromx, y1: fromy, x2: tox, y2: toy },
            colorStops: [
                { offset: 1, color: colorTo },
                { offset: 0, color: colorTo },
            ],
        });

        // pline.set('fill', colorTo);

        const _IText = new fabric.IText(value + ' %', {
            originX: 'left',
            originY: 'top',
            left: inverse ? fromx : tox,
            top: inverse ? fromy : toy,
            fontSize: 25,
            fill: 'rgba(0, 0, 0, 0.2)',
            stroke: colorTo,
            strokeWidth: 1.3,
            evented: true,
            selectable: false,
            opacity: 1,
            // backgroundColor: '#000000',
            padding: 0,
            //@ts-ignore
            versusValue: true, //@ts-ignore
            id: id,
            fontFamily: 'Avenir',
            fontSizeMult: 1.2,
            shadow: shadow,
        });

        return { arrow: pline, text: _IText };
    }

    public cleanArrow() {
        Object.keys(this._arrows).forEach((id) => {
            this._canvas.remove(this._arrows[id]);
            this._canvas.remove(this._texts[id]);
            this._canvas.renderAll();
        });

        this._arrows = {};
    }
}
