import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserProvider } from '@app/providers';
import { switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private userProvider: UserProvider) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return this.userProvider.isUserDataExist().pipe(
            switchMap((user) => {
                if (user && user['jwt']) {
                    if (req.url.includes(environment.backendDomain)) {
                        req = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${user['jwt']}`,
                            },
                        });
                    }
                }
                return next.handle(req);
            })
        );
    }
}
