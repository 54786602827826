import { Component } from '@angular/core';
import { AppStore, User } from '@app/models';
import { LocalBusinesses } from '@app/models/LocalBusiness';
import { StoreProvider } from '@app/providers';
import { UserServices } from '@app/services';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-edit-favorite-localbusiness',
    templateUrl: './edit-favorite-localbusiness.component.html',
    styleUrls: ['./edit-favorite-localbusiness.component.scss'],
})
export class EditFavoriteLocalbusinessComponent {
    public $app: Observable<AppStore> = this.$store.listen('app');
    constructor(
        private $store: StoreProvider,
        private userService: UserServices
    ) {}

    public editFavoriteLocalBusiness(
        user: User,
        localBusiness: LocalBusinesses
    ) {
        this.userService
            .postUserFavoriteLobalBusiness(user, localBusiness)
            .subscribe(() =>
                this.$store.emit({
                    app: { props: { favorite: localBusiness } },
                })
            );
    }
}
