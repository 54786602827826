<div
    class="container-fluid p-0 d-flex h-100 w-100"
    [class]="globalTheme[route]"
>
    <div
        class="position-absolute"
        [class]="
            !redBg || hideBg ? defaultBackground[route] : 'negative_radiant'
        "
        @backgroundIsChange
        [@.disabled]="hideBg"
    ></div>
    <div
        class="d-flex flex-column align-items-center justify-content-start h-100 w-100 row p-0 m-0"
    >
        <div class="col-12 p-0 m-0 d-flex align-items-center h-15">
            <app-action-bar
                class="h-100 w-100"
                [dates]="app.props?.dates"
                [localBusiness]="app.props?.selectedLocalBusiness"
                (dateSelection)="onDateChange($event)"
                [route]="router.url.split('/')[1]"
                [lightMode]="!actionBar.isFull"
            ></app-action-bar>
        </div>
        <div class="col-12 p-0 m-0 d-flex align-items-center h-65 fadeInFast">
            <app-nested-card-default class="py-1 px-2">
                <router-outlet
                    *ngIf="$mainData | async; else loadingSpinner"
                ></router-outlet>
                <ng-template #loadingSpinner>
                    <div
                        class="align-self-center m-auto fadeInFast"
                        [ngClass]="
                            globalTheme[route] === 'dark_theme'
                                ? 'text-white'
                                : ''
                        "
                    >
                        <app-loader-default></app-loader-default>
                    </div>
                </ng-template>
            </app-nested-card-default>
        </div>
        <div
            class="col-12 p-0 m-0 d-flex align-items-center h-10"
            *ngIf="app.props?.detailNav?.items !== undefined"
        >
            <app-detail-nav
                class="h-100 w-100"
                [items]="app.props?.detailNav?.items"
                [active]="app.props?.detailNav?.active"
            ></app-detail-nav>
        </div>
        <div class="col-12 p-0 m-0 d-flex align-items-center h-10">
            <app-nav-bar-tabs
                class="h-100 w-100"
                [isLogged]="app!.props?.user?.isLogged"
            ></app-nav-bar-tabs>
        </div>
    </div>
</div>
