<div class="col-2 position-absolute ps-2">
    <div class="counting_ivs_logo">
        <img src="/assets/svg/logo-white.svg" height="15px" />
        <div class="version p-0 m-0">v{{ version }}</div>
    </div>
</div>
<div
    class="container-fluid p-0 h-100 d-flex flex-column"
    [class]="globalTheme[route]"
>
    <div class="basic_radiant position-fixed fadeIn"></div>
    <div class="center flex-column h-90 w-100 position-fixed">
        <div class="col-12 p-0 m-0 d-flex align-items-center h-15"></div>
        <div
            class="col-12 p-0 m-0 d-flex align-items-center h-80 fadeInFast position-fixed overflow-scroll"
        >
            <app-nested-card-default class="p-3">
                <div class="position-absolute">
                    <app-button-default
                        content="button.back"
                        _class="btn-light m-0 p-0"
                        logo="/assets/svg/arrow/circle-arrow-left.svg"
                        *ngIf="
                            app?.props?.user?.isLogged &&
                            router.url !== '/setting'
                        "
                        (btnClick)="backToSettings()"
                    ></app-button-default>
                </div>
                <div
                    class="logo d-flex align-items-center justify-content-start flex-column w-100"
                    *ngIf="app?.props?.user?.isLogged; else notLogged"
                >
                    <div
                        class="user-avatar"
                        style="
                            border: none;
                            background: transparent;
                            width: 4rem;
                            height: 4rem;
                        "
                    >
                        <img
                            src="/assets/svg/avatar.svg"
                            height="100%"
                            class="start-0"
                        />
                    </div>

                    <p>{{ app!.props?.user?.username }}</p>
                </div>
                <ng-template #notLogged>
                    <div
                        class="logo d-flex flex-column align-items-start w-100 pb-2"
                        *ngIf="!app?.props?.user?.isLogged"
                    >
                        {{ 'app' | translate }}

                        <div
                            class="_login-logo"
                            style="
                                border: none;
                                background: transparent;
                                width: 1.75rem;
                                height: 1.75rem;
                            "
                        >
                            <img
                                src="/assets/svg/logo-black.svg"
                                height="100%"
                                class="start-0"
                            />
                        </div>
                    </div>
                </ng-template>

                <router-outlet></router-outlet>
            </app-nested-card-default>
        </div>

        <div class="col-12 h-10"></div>

        <div class="col-12 p-0 m-0 d-flex align-items-center h-10">
            <app-nav-bar-tabs
                *ngIf="app?.props?.user?.isLogged"
                class="h-100 w-100"
            ></app-nav-bar-tabs>
        </div>
    </div>
</div>
