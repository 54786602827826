<button
    type="button"
    class="btn d-flex flex-row align-items-center"
    [class]="_class || ''"
    (click)="onBtnClick()"
>
    <div
        class="pe-1 d-flex align-items-center"
        *ngIf="logo"
        style="width: 1.25rem; height: 1.25rem"
    >
        <img [src]="logo" width="100%" />
    </div>
    <span *ngIf="loading" class="spinner-border spinner-border-sm me-2"></span>
    <div [class]="_contentClass">
        {{ content | translate }}
    </div>
</button>
