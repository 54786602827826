import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractSettingsComponent } from '@app/pages';
import { AuthService } from '@app/services';

@Component({
    selector: 'app-compare-data',
    templateUrl: './compare-data.component.html',
    styleUrls: ['./compare-data.component.scss'],
})
export class CompareDataComponent extends AbstractSettingsComponent {
    constructor(auth: AuthService, router: Router, route: ActivatedRoute) {
        super(auth, router, route);
    }
}
