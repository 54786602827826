<div class="form-check">
    <input
        class="form-check-input"
        type="checkbox"
        id="flexCheckChecked"
        [(ngModel)]="checked"
        (ngModelChange)="onClick($event)"
    />
    <label class="form-check-label" for="flexCheckChecked">
        {{ label | translate }}
    </label>
</div>
