import { Injectable } from '@angular/core';
import {
    CALENDAR_SELECT,
    CALENDAR_TYPE,
    CustomDateObject,
} from '@app/models/Calendar';
import moment, { Moment } from 'moment-timezone';

@Injectable({ providedIn: 'root' })
export class DateProvider {
    public current: Moment = moment();
    public versus: Moment = moment().subtract(1, 'day');
    public versusVs: Moment = moment().subtract(1, 'day');
    public lastTypeSelected: string = CALENDAR_TYPE.DAY;

    private mappedCalendarTypeToMoment: {
        [key: string]: {
            _s: string;
            _t: string;
            _t_amount: number;
            _s_amount: number;
        };
    } = {
        [CALENDAR_TYPE.DAY]: {
            _s: 'd',
            _t: 'weeks',
            _s_amount: 1,
            _t_amount: 1,
        },
        [CALENDAR_TYPE.WEEK]: {
            _s: 'd',
            _t: 'weeks',
            _s_amount: 7,
            _t_amount: 1,
        },
        [CALENDAR_TYPE.MONTH]: {
            _s: 'months',
            _t: 'months',
            _s_amount: 1,
            _t_amount: 1,
        },
        [CALENDAR_TYPE.YEAR]: {
            _s: 'y',
            _t: 'y',
            _s_amount: 1,
            _t_amount: 1,
        },
    };

    public isPast(dateAsMoment: Moment) {
        return moment().isSameOrAfter(dateAsMoment);
    }

    public goStepLeft(
        dates: CustomDateObject,
        typeDate: CALENDAR_TYPE | undefined
    ) {
        return this.goStep(dates, typeDate, 'getPrev');
    }

    public goStepRight(
        dates: CustomDateObject,
        typeDate: CALENDAR_TYPE | undefined
    ) {
        return this.goStep(dates, typeDate, 'getNext');
    }

    public goStep(
        dates: CustomDateObject,
        typeDate: CALENDAR_TYPE | undefined,
        _fn: 'getPrev' | 'getNext'
    ) {
        var clonedStart = dates.current!.start.asMoment.clone();
        var clonedEnd = dates.current!.end.asMoment.clone();
        var clonedStartVs = dates.versus!.start.asMoment.clone();
        var clonedEndVs = dates.versus!.end.asMoment.clone();
        switch (this.lastTypeSelected) {
            case CALENDAR_TYPE.DAY:
                clonedStart = this[_fn](clonedStart, 'd', 1);
                clonedEnd = this[_fn](clonedEnd, 'd', 1);
                clonedStartVs = this[_fn](clonedStartVs, 'd', 1);
                clonedEndVs = this[_fn](clonedEndVs, 'd', 1);
                break;

            case CALENDAR_TYPE.WEEK:
                if (typeDate === 'year') {
                    clonedStart = this[_fn](clonedStart, 'w', 1);
                    clonedEnd = this[_fn](clonedEnd, 'w', 1);
                    clonedStartVs = this.getDateFromWeek(clonedStart, 'year');
                    clonedEndVs = this.getLastDayOfWeek(clonedStartVs);
                } else {
                    clonedStart = this[_fn](clonedStart, 'w', 1);
                    clonedEnd = this[_fn](clonedEnd, 'w', 1);
                    clonedStartVs = this[_fn](clonedStartVs, 'w', 1);
                    clonedEndVs = this[_fn](clonedEndVs, 'w', 1);
                }
                break;

            case CALENDAR_TYPE.MONTH:
                clonedStart = this[_fn](clonedStart, 'months', 1);
                clonedEnd = this.getLastDayOfMonth(clonedStart);
                clonedStartVs = this[_fn](clonedStartVs, 'months', 1);
                clonedEndVs = this.getLastDayOfMonth(clonedStartVs);
                break;

            case CALENDAR_TYPE.YEAR:
                clonedStart = this[_fn](clonedStart, 'y', 1);
                clonedEnd = this[_fn](clonedEnd, 'y', 1);
                clonedStartVs = this[_fn](clonedStartVs, 'y', 1);
                clonedEndVs = this[_fn](clonedEndVs, 'y', 1);
                break;

            default:
                break;
        }

        return {
            current: {
                start: clonedStart,
                end: clonedEnd,
            },
            versus: {
                start: clonedStartVs,
                end: clonedEndVs,
            },
        };
    }

    public onTypeSelect(
        dates: CustomDateObject,
        futur?: boolean,
        followStart?: boolean,
        followType?: boolean
    ): {
        current: {
            start: Moment | null;
            end: Moment | null;
        };
        versus: {
            start: Moment | null;
            end: Moment | null;
        };
    } {
        const fn = futur ? 'getNext' : 'getPrev';
        const cleanClone = this.onDateSelect(
            dates.current!.start.asMoment.clone(),
            dates.select as string,
            dates.type as string
        );
        let clonedStart = cleanClone.current?.start?.clone() ?? null;
        let clonedEnd = cleanClone.current?.end?.clone() ?? null;

        if (followStart) {
            const type = this.mappedCalendarTypeToMoment[dates.type as never];
            clonedStart = this[fn](
                dates.current?.start?.asMoment.clone() as Moment,
                type._s,
                type._s_amount
            );
            clonedEnd = this[fn](
                dates.current?.end?.asMoment.clone() as Moment,
                type._s,
                type._s_amount
            );
        }

        let clonedStartVs: Moment | null = null;
        let clonedEndVs: Moment | null = null;

        const type = this.mappedCalendarTypeToMoment[dates.type as never];
        const today = moment();
        if (dates.type === 'year' && dates.select === 'year') {
            clonedStartVs = cleanClone.current.start
                .clone()
                .subtract(1, 'year');
            let endOfMonth = today.clone().endOf('month');
            clonedEndVs = endOfMonth.clone().subtract(1, 'year');
            console.log('clonedEndVs', clonedEndVs);
        } else if (dates.type === 'month' && dates.select === 'month') {
            clonedStartVs = cleanClone.current.start
                .clone()
                .subtract(1, 'month');
            clonedEndVs = today.clone().subtract(1, 'month').set('hour', 23);
        } else if (dates.type === 'year' && dates.select === 'month') {
            clonedStartVs = cleanClone.current.start
                .clone()
                .subtract(1, 'year');
            clonedEndVs = today.clone().subtract(1, 'year').set('hour', 23);
        } else if (
            (dates.type === 'month' && dates.select === 'week') ||
            (dates.type === 'year' && dates.select === 'week') ||
            (dates.type === 'week' && dates.select === 'week')
        ) {
            const start = cleanClone.current.start.clone();
            clonedStartVs = cleanClone.versus.start.clone();
            const daysDifference = today.diff(start, 'days');
            const starDiff = clonedStartVs
                .clone()
                .add(daysDifference, 'days')
                .set('hour', 23)
                .clone();

            clonedEndVs = starDiff.clone();
        } else if (dates.type === 'month' && dates.select === 'day') {
            const oneMonthEarlier = clonedStart.clone().subtract(1, 'month');
            const originalDayOfWeek = clonedStart.day();
            let dayToFind = oneMonthEarlier.clone().day(originalDayOfWeek);
            while (dayToFind.month() !== oneMonthEarlier.month()) {
                dayToFind.subtract(7, 'days');
            }
            clonedStartVs = dayToFind;
            clonedEndVs = dayToFind;
        } else if (dates.type === 'year' && dates.select === 'day') {
            const oneYearEarlier = clonedStart.clone().subtract(1, 'year');
            const originalDayOfWeek = clonedStart.day();
            let dayToFind = oneYearEarlier.clone().day(originalDayOfWeek);
            while (dayToFind.year() !== oneYearEarlier.year()) {
                dayToFind.subtract(7, 'days');
            }
            clonedStartVs = dayToFind;
            clonedEndVs = dayToFind;
        } else {
            clonedStartVs = this[fn](
                cleanClone.versus.start.clone(),
                type._s,
                type._s_amount
            );
            clonedEndVs = this[fn](
                cleanClone.versus.end.clone(),
                type._s,
                type._s_amount
            );
        }

        return {
            current: {
                start: clonedStart,
                end: clonedEnd,
            },
            versus: {
                start: clonedStartVs,
                end: clonedEndVs,
            },
        };
    }

    public onDateSelect(
        startDate: Moment,
        calendarSelect: string,
        calendarType: string,
        isDirty?: boolean
    ) {
        var clonedStart = startDate.clone();
        var clonedEnd = startDate.clone();
        var clonedStartVs = startDate.clone();
        var clonedEndVs = startDate.clone();

        switch (calendarSelect) {
            case CALENDAR_SELECT.DAY:
                if (isDirty) {
                    clonedStartVs = this.getPrev(
                        clonedStartVs.clone(),
                        this.mappedCalendarTypeToMoment[calendarType]._s,
                        this.mappedCalendarTypeToMoment[calendarType]._s_amount
                    );

                    clonedEndVs = this.getPrev(
                        clonedEndVs.clone(),
                        this.mappedCalendarTypeToMoment[calendarType]._s,
                        this.mappedCalendarTypeToMoment[calendarType]._s_amount
                    );
                }
                if (calendarType === 'month') {
                    const oneMonthEarlier = clonedStart
                        .clone()
                        .subtract(1, 'month');
                    const originalDayOfWeek = clonedStart.day();
                    let dayToFind = oneMonthEarlier
                        .clone()
                        .day(originalDayOfWeek);
                    while (dayToFind.month() !== oneMonthEarlier.month()) {
                        dayToFind.subtract(7, 'days');
                    }

                    clonedStartVs = dayToFind;
                    clonedEndVs = dayToFind;
                } else if (calendarType === 'year') {
                    const oneYearEarlier = clonedStart
                        .clone()
                        .subtract(1, 'year');
                    const originalDayOfWeek = clonedStart.day();
                    let dayToFind = oneYearEarlier
                        .clone()
                        .day(originalDayOfWeek);
                    while (dayToFind.year() !== oneYearEarlier.year()) {
                        dayToFind.subtract(7, 'days');
                    }
                    clonedStartVs = dayToFind;
                    clonedEndVs = dayToFind;
                }

                break;

            case CALENDAR_SELECT.WEEK:
            case CALENDAR_SELECT.MONTH:
            case CALENDAR_SELECT.YEAR:
                clonedStart = this.getStartOfPeriode(
                    clonedStart,
                    this.mappedCalendarTypeToMoment[calendarSelect]._t
                );
                clonedEnd = this.getLimitOfPeriode(
                    clonedEnd,
                    this.mappedCalendarTypeToMoment[calendarSelect]._t
                );

                if (
                    calendarSelect === CALENDAR_SELECT.WEEK &&
                    (calendarType === 'year' || calendarType === 'month')
                ) {
                    clonedStartVs = this.getDateFromWeek(
                        clonedStart,
                        calendarType
                    );
                    clonedEndVs = this.getLastDayOfWeek(clonedStartVs);
                } else {
                    if (isDirty) {
                        clonedStartVs = this.getPrev(
                            clonedStartVs.clone(),
                            this.mappedCalendarTypeToMoment[calendarType]._s,
                            this.mappedCalendarTypeToMoment[calendarType]
                                ._s_amount
                        );

                        clonedEndVs = this.getPrev(
                            clonedEndVs.clone(),
                            this.mappedCalendarTypeToMoment[calendarType]._s,
                            this.mappedCalendarTypeToMoment[calendarType]
                                ._s_amount
                        );
                    }

                    clonedStartVs = this.getStartOfPeriode(
                        clonedStartVs,
                        this.mappedCalendarTypeToMoment[calendarSelect]._t
                    );
                    clonedEndVs = this.getLimitOfPeriode(
                        clonedEndVs,
                        this.mappedCalendarTypeToMoment[calendarSelect]._t
                    );
                }

                break;

            default:
                break;
        }

        this.versus = clonedStartVs;
        this.versusVs = clonedEndVs;
        this.lastTypeSelected = calendarSelect;

        return {
            current: {
                start: clonedStart,
                end: clonedEnd,
            },
            versus: {
                start: clonedStartVs,
                end: clonedEndVs,
            },
        };
    }

    public toUnix(instanciedDate: Moment, isEnd?: boolean) {
        const clonedDate = instanciedDate.clone();

        clonedDate.hour(isEnd ? 23 : 0);
        clonedDate.minute(0);
        clonedDate.second(0);
        clonedDate.millisecond(0);
        return clonedDate.valueOf() * 1000;
    }

    public getPrev(date: Moment, limit: any, duration: number): Moment {
        return date.subtract(duration, limit);
    }

    public getNext(date: Moment, limit: any, duration: number): Moment {
        return date.add(duration, limit);
    }

    public fromDateToStore = (date: Moment, isEnd?: boolean) => {
        const instanciedDate = date.clone();
        return {
            asHuman: instanciedDate.format('dddd DD/MM/YYYY')._Capitalize(),
            asMoment: instanciedDate,
            asUnix: this.toUnix(instanciedDate, isEnd),
        };
    };

    /**
     * Get limit of period (exemple month, of week)
     * Get fix for month like February
     */
    public getLimitOfPeriode(date: Moment, limit: any): Moment {
        return date.clone().endOf(limit);
    }

    /**
     * Get Start of period (exemple month, of week)
     */
    public getStartOfPeriode(date: Moment, limit: any): Moment {
        return date.clone().startOf(limit);
    }

    public unixDurationToHuman(
        value: number,
        truncToSuperior?: boolean
    ): string {
        const duration = moment.duration(Math.abs(value) / 1000 || 0);

        if (
            truncToSuperior &&
            Number(duration.milliseconds().toString()[0]) > 5
        ) {
            duration.add(1, 'seconds');
        }

        return (
            (Math.sign(value) === -1 ? '-' : '') +
            moment.utc(duration.asMilliseconds()).format('mm [min] ss [sec]')
        );
    }

    public unixDurationToHumanFromSecond(
        value: number,
        truncToSuperior?: boolean
    ): string {
        const duration = moment.duration(
            Math.abs(Math.trunc(value)) || 0,
            'seconds'
        );

        if (
            truncToSuperior &&
            Number(duration.milliseconds().toString()[0]) > 5
        ) {
            duration.add(1, 'seconds');
        }

        return (
            (Math.sign(value) === -1 ? '-' : '') +
            moment
                .utc(
                    moment(duration.asMilliseconds())
                        .set('millisecond', 0)
                        .startOf('second')
                )
                .format('mm [min] ss [sec]')
        );
    }

    public selectedDateToActionBar(
        dateAsMoment: Moment,
        calendarType?: keyof CALENDAR_TYPE
    ): string {
        var formated = '';
        switch (calendarType) {
            case CALENDAR_TYPE.DAY:
                formated = dateAsMoment.format('dddd DD/MM/YYYY')._Capitalize();
                break;

            case CALENDAR_TYPE.WEEK:
                formated = 'S ' + dateAsMoment.format('WW, YYYY')._Capitalize();
                break;

            case CALENDAR_TYPE.MONTH:
                formated = dateAsMoment.format('MMMM, YYYY')._Capitalize();
                break;

            case CALENDAR_TYPE.YEAR:
                formated = dateAsMoment.format('YYYY')._Capitalize();
                break;

            default:
                break;
        }
        return formated;
    }

    public isSameDay(date: Moment) {
        return date.isSameOrAfter(moment(), 'day');
    }

    public getDateFromWeek(date: Moment, type: string) {
        var dateVs = date;
        if (type === 'year') {
            const year = moment(date, 'MMDDYYYY').year() - 1;
            const week = moment(date, 'MMDDYYYY').isoWeek();
            dateVs = moment(date).isoWeekYear(year).isoWeeks(week);
        }

        if (type === 'month') {
            dateVs = moment(date).subtract(4, 'week');
        }
        return dateVs;
    }

    public getLastDayOfMonth(date: Moment) {
        return moment(date, 'YYYY-MM-DD h:m').endOf('month');
    }

    public getLastDayOfWeek(date: Moment) {
        return moment(date, 'YYYY-MM-DD h:m').endOf('week');
    }

    public checkIfToday(select: string, start: number) {
        let today = moment();
        let date = moment(start / 1000);

        let todayVerif;
        let dateVerif;

        if (select === 'day') {
            todayVerif = moment(today).format('YYYY-MM-DD');
            dateVerif = moment(date).format('YYYY-MM-DD');
        } else {
            //@ts-ignore
            todayVerif = today.get(select);
            //@ts-ignore
            dateVerif = date.get(select);
        }

        return todayVerif === dateVerif;
    }

    public checkTypeIfDayOrWeek(select: string) {
        return select === 'day' || select === 'week';
    }
    public setHourCustom(date: any, hour: any) {
        return date.set('hour', hour).unix() * 1000000;
    }

    public verifDate(select: any, verif: any, type: string) {
        let dateSelect;
        let dateForVerif;

        if (type === 'day') {
            dateSelect = moment(select).format('YYYY-MM-DD');
            dateForVerif = moment(verif).format('YYYY-MM-DD');
        } else {
            //@ts-ignore
            dateSelect = select.get(type);
            //@ts-ignore
            dateForVerif = verif.get(type);
        }

        return dateSelect !== dateForVerif;
    }

    public setHourInTimeStamp(ts: any, hour: any) {
        const date = moment(ts / 1000)
            .set('hour', hour + moment().utcOffset() / 60) // hour - offset Client Timezone
            .set('minute', 0)
            .set('second', 0);

        return date.unix() * 1000000;
    }

    public setHourInTimeStampCustom(hour: any) {
        const date = moment()
            .set('hour', hour + moment().utcOffset() / 60) // hour - offset Client Timezone
            .set('minute', 0)
            .set('second', 0);

        return date.unix() * 1000000;
    }

    public getOpeningTime(dateTS: any, openingCalendar: any): number {
        let day = moment(dateTS / 1000);
        if (
            openingCalendar[day.locale('en').format('dddd')].openingTime !=
            false
        ) {
            return Number(
                openingCalendar[
                    day.locale('en').format('dddd')
                ].openingTime.split(':')[0]
            );
        } else {
            return 0;
        }
    }

    public getClosingTime(dateTS: any, openingCalendar: any): any {
        let day = moment(dateTS / 1000);
        if (
            openingCalendar[day.locale('en').format('dddd')].closingTime !=
            false
        ) {
            const hour =
                openingCalendar[
                    day.locale('en').format('dddd')
                ].closingTime.split(':');

            const date = moment()
                .hour(hour[0])
                .minute(hour[1])
                .subtract(1, 'minute');

            return date.hour();
        } else {
            return 0;
        }
    }

    public checkIsOpen(closingTime: number) {
        let today = moment();
        return today.get('hour') > closingTime;
    }

    public getTodayLessType(type: any) {
        let today = moment();
        return today.subtract(1, type).unix() * 1000000;
    }

    public dayLessOneType(day: any, type: string) {
        return day.subtract(1, type).unix() * 1000000;
    }

    public setDayHourOfDay(day: any, day2: any) {
        return day.set('hour', day2.get('hour')).unix() * 1000000;
    }

    public toTimestamp(day: any) {
        return day.unix() * 1000000;
    }

    public setDayOfWeek(
        timestamp: any,
        numberOfDay: number,
        numberOfWeek: number
    ) {
        let day = moment(timestamp / 1000);
        return (
            day
                .set('day', numberOfDay)
                .set('week', numberOfWeek)
                .set('hour', 23)
                .set('minute', 0)
                .set('second', 0)
                .subtract(1, 'day')
                .unix() * 1000000
        );
    }

    public checkIfFirstDay(select: string) {
        let today = moment();
        switch (select) {
            case 'week':
                return today.get('day') === 1;
            case 'month':
                return today.get('date') === 1;
            case 'year':
                return today.format('DD:MM') === '01:01';
            default:
                return false;
        }
    }

    /**
     * Remove 0 in a date. 2023/3/09 => 2023/3/9
     */
    public removeZero(date: string) {
        const days = date.split('').slice(7, 8);

        if (days.includes('0')) return date.slice(0, 7) + date.slice(8);
        return date;
    }
}
