import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollProvider {
    public isElementInViewPort(
        el: HTMLElement,
        wrapElement: HTMLElement
    ): boolean {
        const rect = el.getBoundingClientRect();
        const wrapRect = wrapElement.getBoundingClientRect();

        if (
            wrapRect.x > rect.width + rect.x ||
            rect.x > wrapRect.width + wrapRect.x ||
            wrapRect.y > rect.height + rect.y ||
            rect.y > wrapRect.height + wrapRect.y
        ) {
            return false;
        }
        return true;
    }
}
