import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-data-main',
    templateUrl: './data-main.component.html',
    styleUrls: ['./data-main.component.scss'],
})
export class DataMainComponent implements OnInit {
    @Input() showRedBg!: boolean;
    @Input() current?: number = 0;
    @Input() versus?: number = 0;
    @Input() icon?: string;
    @Input() variation!: number;
    ngOnInit(): void {
        this.variation = Math.abs(this.variation);
    }
}
