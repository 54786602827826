import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AppStore } from '@app/models';
import { LocalBusinesses } from '@app/models/LocalBusiness';
import { ZonesApi } from '@app/models/Zones';
import { DateProvider, StoreProvider, ZonesProvider } from '@app/providers';
import { JourneyDetailsService, ZoneServices } from '@app/services';
import { Canvas } from 'fabric/fabric-impl';
declare var videojs: any;
@Component({
    selector: 'app-journey-details',
    templateUrl: './journey-details.component.html',
    styleUrls: ['./journey-details.component.scss'],
})
export class JourneyDetailsComponent implements OnInit, OnDestroy {
    @Input() localBusiness?: LocalBusinesses;
    @Input() boxes?: any;
    @Input() dates: any;
    @Input() data?:
        | any
        | {
              name?: string;
              path?: string;
              icon?: string;
              apiFn?: string;
              type?: string;
              fabricFn?: string;
          };
    public selectedBox!: any;
    public selectedZone!: any;

    public blob!: Blob;

    public type = {
        image: 'image/png',
        image_open_cv: '8UC3',
        video: 'video/mp4',
    };

    public player: any;

    public componentCanLoad: boolean = false;

    private scale = 0;
    @Output() dateIsNotValid: EventEmitter<any> = new EventEmitter();
    @Output() dayIsNotValid: EventEmitter<any> = new EventEmitter();

    private _canvas!: Canvas;

    public state: { loading: boolean; error: boolean } = {
        loading: true,
        error: false,
    };

    public $app = this.$store.listen('app');
    public app!: AppStore;

    public errorNotTransitions = true;
    public selectedZoneId!: number;
    public transitionsInverse = false;

    public isTransitionExist = false;
    public isTransitionInverseExist = false;

    public noTransitionForThisZone = false;

    @ViewChild('JourneyDetailCanvas')
    journeyDetailCanvas!: ElementRef<HTMLCanvasElement>;

    @ViewChild('JourneyDetailCanvasWrap')
    Wrap!: ElementRef<HTMLElement>;

    constructor(
        private journeyDetailsService: JourneyDetailsService,
        private zonesProvider: ZonesProvider,
        private dateProvider: DateProvider,
        public $store: StoreProvider,
        private zoneService: ZoneServices
    ) {}

    ngOnInit(): void {
        this.$app.subscribe((app) => {
            this.app = app;
        });

        if (this.app.props?.dates?.select === 'week') {
            this.dayIsNotValid.emit(true);
            return;
        }

        if (this.app?.props?.detailNav?.active?.name !== 'zone-to-zone') {
            if (
                this.dateProvider.isSameDay(this.dates.current.start.asMoment)
            ) {
                this.dateIsNotValid.emit(true);
                return;
            }
        }

        this.componentCanLoad = true;

        if (localStorage.getItem('selectedBox') !== null) {
            this.boxes.forEach((box: any) => {
                if (box.id === Number(localStorage.getItem('selectedBox'))) {
                    this.selectedBox = box;
                }
            });
            if (this.selectedBox === undefined) {
                this.selectedBox = this.boxes[0];
                localStorage.setItem(
                    'selectedBox',
                    String(this.selectedBox.id)
                );
            }
        } else {
            this.selectedBox = this.boxes[0];
            localStorage.setItem('selectedBox', String(this.selectedBox.id));
        }

        this.loadData();
    }

    public loadData() {
        this.state = { loading: true, error: false };

        var date = this.dates.current.start.asMoment.format('YYYY/M/DD');
        if (this.data.name === 'zone-to-zone') {
            date = this.dates.current.start.asUnix;
        }
        //@ts-ignore
        this.journeyDetailsService[this.data.apiFn](
            this.selectedBox.id,
            date
        ).subscribe(
            (blob: any) => {
                this.blob = blob;
                this.state.loading = false;
                setTimeout(() => {
                    if (blob.type === this.type.video) {
                        this.initVideo(blob);
                    }

                    if (
                        blob.type === this.type.image ||
                        blob.type === this.type.image_open_cv
                    ) {
                        const isFromOpenCV =
                            blob.type === this.type.image_open_cv;
                        const _blob = isFromOpenCV ? blob['data'] : blob;
                        this.initCanvasAndBackground(_blob, isFromOpenCV);
                        if (this.data.name === 'zone-to-zone') {
                            this.initZoneToZone();
                        }
                        this.renderCanvas();
                    }
                });
            },
            () => {
                this.state.loading = false;
                this.state.error = true;
            }
        );
    }

    private filterZones(zones: ZonesApi[]) {
        const out: any = {};
        Object.keys(zones).forEach((_key: any) => {
            if (
                zones[_key].flags !== 4 &&
                zones[_key].flags !== 5 &&
                zones[_key].active
            ) {
                out[_key] = zones[_key];
            }
        });
        return out;
    }

    private filterPassageToZones(zones: ZonesApi[], passages: any) {
        const out: any = [];

        Object.keys(zones).forEach((_key: any) => {
            const zone = zones[_key];
            if (zone.flags !== 4 && zone.flags !== 5 && zone.active) {
                var parsedZone = JSON.parse(zone.data as any);
                parsedZone.push(parsedZone[0]);
                out.push(
                    parsedZone.map((val: any) => {
                        return { x: val.x * this.scale, y: val.y * this.scale };
                    })
                );
            }
        });
        return out;
    }
    public initZoneToZone() {
        this.journeyDetailsService
            .getAllTransitionsForZones(
                this.selectedBox.id,
                {
                    begin: this.dates.current.start.asUnix,
                    end: this.dates.current.end.asUnix,
                },
                false
            )
            .subscribe((zonesWithTransitions: any) => {
                this.journeyDetailsService
                    .getAllTransitionsForZones(
                        this.selectedBox.id,
                        {
                            begin: this.dates.current.start.asUnix,
                            end: this.dates.current.end.asUnix,
                        },
                        true
                    )
                    .subscribe((zonesWithTransitionsReverse: any) => {
                        this.addZonesToCanvas(
                            this.filterZones(zonesWithTransitions),
                            this.filterZones(zonesWithTransitionsReverse)
                        );
                    });
            });
    }

    public onZoneClick(id: string) {
        this.zonesProvider.zoneToZone(id, this.transitionsInverse);
    }

    private addZonesToCanvas(
        zonesWithTransitions: ZonesApi[],
        zonesWithTransitionsReverse: ZonesApi[]
    ) {
        Object.keys(zonesWithTransitions).forEach((_key: any, index) => {
            const zoneId = _key;
            const transitionsValues: any =
                zonesWithTransitions[_key]['transitions'];
            const transitionsValuesReverse: any =
                zonesWithTransitionsReverse[_key]['transitions'];

            if (
                transitionsValues !== undefined ||
                transitionsValuesReverse !== undefined
            ) {
                this.errorNotTransitions = false;
            }

            const zone = zonesWithTransitions[_key];
            if (zonesWithTransitions[_key].flags !== 2) {
                var parsedZone = JSON.parse(zone.data as any);
                parsedZone.push(parsedZone[0]);
                parsedZone = parsedZone.map((val: any) => {
                    return { x: val.x * this.scale, y: val.y * this.scale };
                });

                const polyline = this.zonesProvider.addReactivePolyline(
                    parsedZone,
                    true,
                    zone.color,
                    zone.color,
                    zoneId,
                    transitionsValues,
                    transitionsValuesReverse
                );

                this.zonesProvider.addAndRender(polyline);

                polyline.on('mousedown', (event) => {
                    if (event.target) {
                        let idZone = this.zonesProvider.getIdZoneOfPoly(
                            event.target
                        );

                        if (this.selectedZoneId === idZone) {
                            if (!this.transitionsInverse) {
                                this.isTransitionExist = this.isTransitions(
                                    event.target,
                                    false
                                );
                                this.transitionsInverse = true;
                            } else {
                                this.isTransitionInverseExist =
                                    this.isTransitions(event.target, true);
                                this.transitionsInverse = false;
                            }
                        } else {
                            this.transitionsInverse = false;
                            this.isTransitionExist = this.isTransitions(
                                event.target,
                                false
                            );

                            this.isTransitionInverseExist = this.isTransitions(
                                event.target,
                                true
                            );
                            this.selectedZoneId = idZone;
                        }

                        if (!this.transitionsInverse) {
                            this.noTransitionForThisZone =
                                !this.isTransitionExist;
                        } else {
                            this.noTransitionForThisZone =
                                !this.isTransitionInverseExist;
                        }

                        if (!this.errorNotTransitions) {
                            this.zoneService
                                .getZoneById(idZone)
                                .subscribe((zone) => {
                                    this.selectedZone = zone.name;
                                });

                            this.zonesProvider.zoneToZone(
                                event.target,
                                this.transitionsInverse
                            );
                        }
                    }
                });
            }
        });
    }

    public isTransitions(poly: any, inverse: boolean): boolean {
        return inverse
            ? poly.transitionsValuesInverse != undefined
            : poly.transitionsValues != undefined;
    }

    public initCanvasAndBackground(blob: Blob, already64: boolean) {
        this._canvas = this.zonesProvider.generate(
            this.journeyDetailCanvas.nativeElement,
            {
                containerClass:
                    'w-100 h-100 position-relative d-flex align-content-center justify-content-center',
            }
        );
        this.zonesProvider[already64 ? 'background' : 'backgroundBlobTo64'](
            blob as any
        ).then(() => {
            const bgSize = this.zonesProvider.getBackgroundSize();
            this.scale = Math.min(
                (this.Wrap.nativeElement.clientWidth as number) / bgSize.width,
                (this.Wrap.nativeElement.clientHeight as number) / bgSize.height
            );
            this.zonesProvider.setBackgroundScale(this.scale);

            setTimeout(() => {
                this.zonesProvider.setCanvasSize(
                    bgSize.width,
                    bgSize.height,
                    this.scale
                );
            });
        });
    }

    public renderCanvas() {
        this._canvas.renderAll();
    }

    public initVideo(blob: Blob) {
        this.player = videojs(document.getElementById('video-player'), {
            sources: {
                src: URL.createObjectURL(blob),
                type: 'video/mp4',
            },
            autoplay: 'muted',
            fluid: true,
            loop: true,
        });
        this.customPlayer('.vjs-volume-panel');
        this.customPlayer('.vjs-remaining-time');
    }

    public toogleVideo() {
        this.player.play();
    }

    public switchBoxSelected(box: any) {
        let index = this.boxes.indexOf(box) + 1;
        index === this.boxes.length
            ? this.setBoxSelected(this.boxes[0])
            : this.setBoxSelected(this.boxes[index]);
    }

    public setBoxSelected(box: any) {
        this.selectedBox = box;
        localStorage.setItem('selectedBox', box.id);
        this.$store.emitReloadAll(true);
        this.$store.emit(this.app);
        this.loadData();
    }

    ngOnDestroy(): void {
        if (this._canvas) {
            this.zonesProvider.destroyAndClean();
        }
    }

    public customPlayer(elementToDisplayNone: string) {
        const playerIcon = document.querySelector(
            elementToDisplayNone
        ) as HTMLDivElement;
        playerIcon.style.display = 'none';
    }
}
