<div class="actions h-100 w-100">
    <div
        class="row"
        class="d-flex align-items-center flex-row"
        *ngFor="let item of list"
        (click)="selectSetting.emit(item)"
    >
        <div class="col-11">
            <button class="w-100 btn btn-light text-start my-2 p-0">
                {{ 'navSettings.' + item | translate }}
            </button>
        </div>
        <div class="col-1">
            <img
                src="/assets/svg/arrow/circle-arrow-right.svg"
                width="auto"
                height="auto"
            />
        </div>
    </div>
</div>
