<div
    class="container-fluid p-0 h-100 d-flex flex-column"
    *ngIf="$bucketData | async as bucketData; else loadingSpinner"
>
    <div
        class="d-flex flex-column justify-content-arround row p-0 m-0 h-100 fadeInFast"
        *ngIf="!loading; else loadingSpinner"
    >
        <div
            [ngSwitch]="app!.props?.detailNav?.active?.name"
            class="h-100 w-100"
        >
            <div *ngSwitchCase="'main'" class="h-100 w-100">
                <div class="col-12 p-0 m-0 position-relative center h-40">
                    <ng-container *ngIf="!isEmpty; else noZone">
                        <app-data-scatter
                            [data]="bucketData.scatterData"
                            [visibleRows]="visibleRows"
                        ></app-data-scatter>
                    </ng-container>
                </div>
                <div
                    class="col-12 p-0 m-0 position-relative h-60 border-0"
                    [ngSwitch]="app!.props?.detailNav?.active?.name"
                    *ngIf="!isEmpty"
                >
                    <app-journey-table
                        id="scroll-container"
                        [tableData]="bucketData.tableData"
                        [customers]="bucketData.customers"
                        [customersVs]="bucketData.customersVs"
                        [customersVariation]="bucketData.customersVariation"
                        [dates]="app.props?.dates"
                        (isScroll)="setVisiblesRows($event)"
                    ></app-journey-table>
                </div>
            </div>
            <div
                class="col-12 p-0 m-0 position-relative center h-100"
                *ngSwitchDefault
            >
                <app-journey-details
                    [data]="app!.props?.detailNav?.active"
                    [localBusiness]="app!.props?.selectedLocalBusiness"
                    [boxes]="bucketData.boxes"
                    [dates]="app.props!.dates"
                    (dateIsNotValid)="proposeGoodDate('date')"
                    (dayIsNotValid)="proposeGoodDate('day')"
                ></app-journey-details>
            </div>
        </div>

        <ng-template #noZone>
            <p class="darkOpacity pt-3">
                {{ 'noZone' | translate }}
            </p>
        </ng-template>
    </div>
</div>
<ng-template #loadingSpinner>
    <div class="h-100 w-100 d-flex fadeInFast">
        <div class="align-self-center m-auto text-white">
            <app-loader-default></app-loader-default>
        </div>
    </div>
</ng-template>
