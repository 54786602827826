import {
    Component,
    Input,
    OnChanges,
    Output,
    EventEmitter,
} from '@angular/core';

@Component({
    selector: 'app-dropdown-default',
    templateUrl: './dropdown-default.component.html',
    styleUrls: ['./dropdown-default.component.scss'],
})
export class DropdownDefaultComponent implements OnChanges {
    @Input() name: string = '';
    @Input() boxes?: any;
    @Input() box?: any;
    public boxSelected!: any;
    public dropDownArrow = 'bottom';

    @Output() selected: EventEmitter<any> = new EventEmitter();

    ngOnChanges() {
        this.boxSelected = this.box;
    }

    public changeBoxSelected(box: any) {
        this.boxSelected = box;
        this.dropDownArrow = 'bottom';
        this.selected.emit(this.boxSelected);
    }

    public openDropdown(): void {
        this.dropDownArrow === 'top'
            ? (this.dropDownArrow = 'bottom')
            : (this.dropDownArrow = 'top');
    }
}
