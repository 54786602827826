<div class="container-fluid p-0 h-100 w-100">
    <div class="col-12">
        <app-settings-list
            [list]="helpSettingsList"
            (selectSetting)="
                onItemSelect($event, _InternSettingsRouter.help.children)
            "
        ></app-settings-list>
    </div>
</div>
