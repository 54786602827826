<div class="d-flex flex-column justify-content-around h-100">
    <div
        class="w-100"
        *ngIf="
            selectedLocalBusiness.groupsCountingEnabled;
            else noGroupCountingData
        "
    >
        <app-counting-data
            [img]="'customers-group-dark'"
            [current]="principalData.current.nbGroups"
            [versus]="principalData.versus.nbGroups"
            [variation]="principalData.comparison.nbGroups"
            [variationUnit]="'%'"
        ></app-counting-data>
    </div>

    <div class="w-100">
        <app-counting-data
            [img]="
                selectedLocalBusiness.attractivityEnabled
                    ? 'localbusiness'
                    : 'localbusiness-warning'
            "
            [current]="
                selectedLocalBusiness.attractivityEnabled
                    ? principalData.current.passages
                    : principalData.current.attractivityWifi
            "
            [versus]="
                selectedLocalBusiness.attractivityEnabled
                    ? principalData.versus.passages
                    : principalData.versus.attractivityWifi
            "
            [variation]="principalData.comparison.passages"
            [variationUnit]="'%'"
        ></app-counting-data>
    </div>

    <div class="w-100">
        <app-counting-data
            [img]="'attractivity-video'"
            [current]="
                selectedLocalBusiness.attractivityEnabled
                    ? principalData.current.attractivenessRate
                    : principalData.current.attractivityWifi
            "
            [versus]="
                selectedLocalBusiness.attractivityEnabled
                    ? principalData.versus.attractivenessRate
                    : principalData.versus.attractivityWifi
            "
            [variation]="
                selectedLocalBusiness.attractivityEnabled
                    ? principalData.comparison.attractivenessRate
                    : principalData.comparison.attractivityWifi
            "
            [unit]="'%'"
            [decimalPosition]="1"
            [variationUnit]="' Pt'"
        ></app-counting-data>
    </div>

    <div
        class="w-100"
        *ngIf="
            selectedLocalBusiness.localBusinessFiancial;
            else noFinancialData
        "
    >
        <app-counting-data
            [img]="'euro'"
            [current]="principalData.current.turnover"
            [versus]="principalData.versus.turnover"
            [variation]="principalData.comparison.turnover"
            [unit]="'€'"
            [variationUnit]="'%'"
        ></app-counting-data>
    </div>

    <div class="w-100" *ngIf="selectedLocalBusiness.localBusinessFiancial">
        <app-counting-data
            [img]="'tickets-by-customers'"
            [current]="principalData.current.transformationRate"
            [versus]="principalData.versus.transformationRate"
            [variation]="principalData.comparison.transformationRate"
            [decimalPosition]="1"
            [unit]="'%'"
            [variationUnit]="' Pt'"
        ></app-counting-data>
    </div>

    <ng-template #noFinancialData>
        <div
            class="indic bg-greyOpacity radius-secondary p-3"
            *ngIf="displayRegisterDataInfo"
        >
            <div class="header">
                <div class="small">
                    <b>{{
                        'navSettings.transformationRateData' | translate
                    }}</b>
                </div>
                <img
                    (click)="deleteInfo()"
                    src="assets/svg/close-dark.svg"
                    alt=""
                    width="15px"
                />
            </div>

            <div
                class="small"
                [innerHTML]="'noFinancialDataInfo' | translate"
            ></div>
        </div>
    </ng-template>

    <ng-template #noGroupCountingData>
        <div
            class="indic bg-greyOpacity radius-secondary p-3"
            *ngIf="displayRegisterDataInfo"
        >
            <div class="header">
                <div class="small">
                    <b>{{ 'navSettings.groupData' | translate }}</b>
                </div>
                <img
                    (click)="deleteInfo()"
                    src="assets/svg/close-dark.svg"
                    alt=""
                    width="15px"
                />
            </div>

            <div class="small" [innerHTML]="'noGroupsData' | translate"></div>
        </div>
    </ng-template>
</div>
