<ng-template #content let-modal>
    <div class="center justify-content-around">
        <button
            (click)="onSelect(item)"
            class="btn-calandar btn-transparant"
            *ngFor="let item of data.slice().reverse()"
        >
            <img
                class="calendar_select"
                [src]="'/assets/svg/' + folder + '/' + item + '.svg'"
                width="100%"
            />
        </button>
        <button
            disabled
            class="btn-calandar btn-transparant"
            *ngFor="let item of disabledData.slice().reverse()"
        >
            <img
                class="calendar_select"
                [src]="'/assets/svg/' + folder + '/' + item + '_disabled.svg'"
                width="100%"
            />
        </button>
    </div>
</ng-template>

<button class="btn-calandar btn-transparant" (click)="open(content, folder)">
    <img
        class="calendar_select"
        [src]="'/assets/svg/' + folder + '/' + selected + '.svg'"
        width="100%"
    />
</button>
