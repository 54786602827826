<div class="calendar_type_dropdown">
    <div class="calendar_selected_type">
        <div
            class="calendar_type d-flex align-items-center justify-content-center"
        >
            <app-dropdown-horizontal-default
                [data]="filteredTypes"
                [selected]="type"
                (selectedItem)="typeSelected.emit($event)"
                [folder]="'type'"
                _i18nKey="dates.toComponents."
            ></app-dropdown-horizontal-default>
        </div>
    </div>
</div>
