import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AbstractSettingsComponent } from '../abstract-settings';
import * as _package from '@app/../../package.json';

@Component({
    selector: 'app-documentation',
    templateUrl: './documentation.component.html',
    styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent extends AbstractSettingsComponent {
    public version = _package;
    constructor(
        auth: AuthService,
        router: Router,
        route: ActivatedRoute,
        public translateService: TranslateService
    ) {
        super(auth, router, route);
    }
}
