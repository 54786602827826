<div class="d-flex flex-row h-100 row p-2 m-0 position-relative">
    <div class="col-10 p-0 m-0">
        <app-input-groups
            class="w-100"
            [value]="searchValue"
            [classCustom]="'border-0'"
            icon="/assets/svg/search.svg"
            placeHolder="searchLocalbusiness"
            (inputChange)="searchValue = $event"
        ></app-input-groups>
    </div>

    <div class="col-12 p-0 m-0 h-100 overflow-scroll position-relative">
        <div
            class="scrollable_items_navbar position-absolute w-100"
            id="scrollable_items_navbar"
            *ngIf="localBusinesses && localbusinessSelected"
        >
            <div
                class="list-group-item bg-transparent list-group-item-action border-0 d-flex flex-row d-flex py-1 m-0 px-0 _striped_zebra w-100"
                *ngFor="
                    let item of localBusinesses
                        | findItemByTerm: searchValue:fieldToShowAndSearch
                "
            >
                <div class="content d-flex flex-column px-1 w-100">
                    <div
                        class="text-start text_grey pointer d-flex align-items-center"
                        (click)="itemIsSelected.emit(item)"
                        [class]="
                            mode === 'list'
                                ? localbusinessSelected.id === item.id
                                    ? 'text-grey'
                                    : ''
                                : localbusinessSelected.id === item.id
                                ? 'text-grey'
                                : ''
                        "
                        [id]="item.id + '_localbusiness_list_scrollItem' + mode"
                    >
                        <div class="logo">
                            <img
                                class="me-2"
                                width="20px"
                                src="/assets/svg/stars-fav.svg"
                                *ngIf="item.id === localBusinessesFavorite?.id"
                            />
                            <img
                                class="me-2"
                                width="20px"
                                src="/assets/svg/stars.svg"
                                *ngIf="
                                    mode === 'select-new-favorite' &&
                                    item.id !== localBusinessesFavorite?.id
                                "
                            />
                        </div>
                        <div class="content">
                            {{ item[fieldToShowAndSearch] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
