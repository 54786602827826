import { Pipe, PipeTransform } from '@angular/core';
import { DateProvider } from '@app/providers';

@Pipe({
    name: 'unixDurationToHuman',
})
export class UnixDurationToHumanFilter implements PipeTransform {
    constructor(private dateProvider: DateProvider) {}
    transform(data: any): string {
        return this.dateProvider.unixDurationToHuman(data);
    }
}

@Pipe({
    name: 'formatDateByPeriodType',
})
export class FormatDateByPeriodType implements PipeTransform {
    constructor(private dateProvider: DateProvider) {}
    transform(data: any, type: any): string {
        return this.dateProvider.selectedDateToActionBar(data, type);
    }
}
