<div class="d-flex flex-column align-items-center justify-content-center w-100">
    <div
        class="spinner pb-2"
        role="status"
        [style.width]="width"
        [style.height]="height"
        *ngIf="state?.loading"
    >
        <img [src]="svg" />
    </div>
    <div>{{ label | translate }}</div>
</div>
