import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { AbstractSettingsComponent } from '../abstract-settings';

@Component({
    selector: 'app-legals',
    templateUrl: './legals.component.html',
    styleUrls: ['./legals.component.scss'],
})
export class LegalsComponent extends AbstractSettingsComponent {
    constructor(
        auth: AuthService,
        router: Router,
        route: ActivatedRoute,
        public translateService: TranslateService
    ) {
        super(auth, router, route);
    }
}
