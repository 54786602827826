import { Injectable } from '@angular/core';
import { Swiper, SwiperOptions } from 'swiper';
@Injectable({ providedIn: 'root' })
export class SwiperProvider {
    private swipper!: Swiper;
    public create(element: HTMLElement, opts?: SwiperOptions) {
        this.swipper = new Swiper(element, opts);
        return this.swipper;
    }

    public setIndex(index: number) {
        this.swipper.slideToLoop(index);
    }
}
