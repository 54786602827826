import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CALENDAR_TYPE } from '@app/models/Calendar';

@Component({
    selector: 'app-calendar-type',
    templateUrl: './calendar-type.component.html',
    styleUrls: ['./calendar-type.component.scss'],
})
export class CalendarTypeComponent {
    @Input() type: string | any;
    @Input() calendarSelected: string | any;
    @Output() typeSelected = new EventEmitter();

    public types: string[] = Object.values(CALENDAR_TYPE);

    get filteredTypes() {
        const filteredTypes = this.types.filter(
            (value, index) => index >= this.types.indexOf(this.calendarSelected)
        );
        return filteredTypes;
    }
}
