import { Moment } from 'moment';
import { Layout } from '.';

export interface DateSelected {
    asMoment: Moment;
    asHuman: string;
    asUnix: number;
}
export enum CALENDAR_TYPE {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}
export enum CALENDAR_SELECT {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export interface CustomDateObject {
    current?: { start: DateSelected; end: DateSelected };
    versus?: { start: DateSelected; end: DateSelected };
    type?: CALENDAR_TYPE;
    select?: CALENDAR_SELECT;
    fullActionBar?: boolean;
}
