<div class="h-100 w-100 position-relative">
    <app-localbusinesses-list
        *ngIf="$app | async; let app"
        [localBusinesses]="app.props?.localBusinesses"
        [localBusinessesFavorite]="app.props?.favorite"
        [localbusinessSelected]="app.props?.selectedLocalBusiness"
        [localBusinesses]="app.props?.localBusinesses"
        (itemIsSelected)="editFavoriteLocalBusiness(app.props?.user, $event)"
        mode="select-new-favorite"
    ></app-localbusinesses-list>
</div>
