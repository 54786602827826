import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roundedWithRules',
})
export class RoundedWihtRulesFilter implements PipeTransform {
    transform(number?: number, decimal_pos?: any): number {
        if (
            number === null ||
            number === undefined ||
            isNaN(number) ||
            !isFinite(number)
        ) {
            return decimal_pos
                ? Number(parseFloat('0').toFixed(decimal_pos))
                : 0;
        }

        var dp = decimal_pos == null ? 2 : decimal_pos,
            regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${dp}})?`);
        return ((number + '').match(regex) as any)[0];
    }
}

@Pipe({
    name: 'numberSuffix',
})
export class NumberSuffixFilter implements PipeTransform {
    suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];
    transform(number?: any, decimal_pos?: any, ops?: any): string {
        if (isNaN(number)) {
            return '';
        }
        if (!ops) {
            ops = {};
            if (ops?.limit === undefined) ops.limit = 5000;
        }
        if (number < ops?.limit) {
            return formatNumber(number, decimal_pos) + '';
        }

        const based = toBase10(number);

        return `${based.value}${based.key}`;
    }
}

const formatNumber = (number: any, decimal_pos?: any) => {
    const _number = Number(
        (number + '').replace(',', '.').replace(/[^0-9.-]/g, '')
    );
    if (_number === null || isNaN(_number) || !isFinite(_number)) {
        return decimal_pos
            ? '0,' + parseFloat('0').toFixed(decimal_pos).split('.')[1]
            : '0';
    }
    var dp = decimal_pos == null ? 2 : decimal_pos,
        regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${dp}})?`),
        f = Number(((_number + '') as any).match(regex)[0]),
        t = f.toLocaleString('FR-fr');
    return t;
};

export const toBase10 = (value: number, limit = 999) => {
    let key = '';

    let abs = Math.abs(value);
    const rounder = Math.pow(10, 1);
    const isNegative = value < 0;

    const powers = [
        { key: 'Q', value: Math.pow(10, 15) },
        { key: 'T', value: Math.pow(10, 12) },
        { key: 'B', value: Math.pow(10, 9) },
        { key: 'M', value: Math.pow(10, 6) },
        { key: 'K', value: 1000 },
    ];

    for (let i = 0; i < powers.length; i++) {
        let reduced = abs / powers[i].value;
        reduced = Math.round(reduced * rounder) / rounder;
        if (reduced >= 1) {
            abs = reduced;
            key = powers[i].key;
            break;
        }
    }
    return {
        isNegative,
        absoluteValue: abs,
        value: Number(`${isNegative ? '-' : '+'}${abs}`),
        key,
    };
};
