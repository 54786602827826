import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-journey-detail-zone',
    templateUrl: './journey-detail-zone.component.svg',
    styleUrls: ['./journey-detail-zone.component.scss'],
})
export class JourneyDetailZoneComponent implements OnInit {
    @Input() dataZone: any;
    @Input() customers: any;
    @Input() customersVs: any;
    @Input() customersVariation: any;

    public variation = {
        customersVariation: '',
        passagesVariation: '',
        stopsVariation: '',
    };

    constructor() {}

    ngOnInit() {
        this.variation = {
            customersVariation: this.getColorVariation(this.customersVariation),
            passagesVariation: this.getColorVariation(
                this.dataZone.passageVariation
            ),
            stopsVariation: this.getColorVariation(this.dataZone.stopVariation),
        };
    }

    public getColorVariation(data: number) {
        switch (true) {
            case data > 0:
                return '#34a76e';
            case data < 0:
                return '#e05a49';
            default:
                return '#5d5d63';
        }
    }
}
