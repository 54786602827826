<ng-container *ngIf="checkIfSubscribe(); else subscriptionPage">
    <div class="w-100 h-100 px-15 py-10" *ngIf="$data; else loadingSpinner">
        <div
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-between"
            *ngIf="!checkIfException($data); else emptyStateTemplate"
        >
            <app-pie-chart-gender
                [genderData]="$data.current.gender"
                class="w-100 h-55 position-relative d-flex align-items-center justify-content-center"
            ></app-pie-chart-gender>

            <app-pie-chart-age
                [ageData]="$data.current.age"
                class="w-100 h-45 position-relative d-flex justify-content-center pt-4"
            ></app-pie-chart-age>
        </div>
    </div>
</ng-container>

<ng-template #loadingSpinner>
    <div
        class="h-100 w-100 d-flex justify-content-center align-items-center overflow-hidden"
    >
        <app-loader-default></app-loader-default>
    </div>
</ng-template>

<ng-template #emptyStateTemplate>
    <div class="col-12">
        <div class="center indic bg-greyOpacity radius-secondary p-3 m-3">
            {{ 'noDataToShow' | translate }}
        </div>
    </div>
</ng-template>

<ng-template #subscriptionPage>
    <div class="indic bg-greyOpacity radius-secondary p-3 m-3 ng-star-inserted">
        <div class="small" [innerHTML]="'noAgeGenderData' | translate"></div>
    </div>
</ng-template>
