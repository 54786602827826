import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loader-default',
    templateUrl: './loader-default.component.html',
    styleUrls: ['./loader-default.component.scss'],
})
export class LoaderDefaultComponent {
    @Input() label: string = 'loading.default';
    @Input() svg: string = '/assets/svg/loader-grid.svg';
    @Input() width: string = '50px';
    @Input() height: string = '50px';

    @Input() state: { loading: boolean; error: boolean } = {
        loading: true,
        error: false,
    };
}
