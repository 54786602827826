import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SwiperModule } from 'swiper/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    HTTP_INTERCEPTORS,
    HttpClientModule,
    HttpClient,
} from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BasicAuthInterceptor, ErrorInterceptor } from '@interceptors';
import { environment } from '@environments/environment';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import {
    NgbDatepickerModule,
    NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';

import {
    LocalBusinessServices,
    LocalBusinessesServices,
    OrganizationServices,
    OrganizationsServices,
    UserServices,
    AuthService,
    AuthGuard,
    DataService,
    ZoneServices,
    JourneyDetailsService,
} from '@services';

import {
    StoreProvider,
    StorageProvider,
    UserProvider,
    ModalProvider,
    DateProvider,
    MathProvider,
    SwiperProvider,
    LayoutProvider,
    ActionBarVersusCanvasProvider,
    ZonesProvider,
    RefreshProvider,
    CollisionProvider,
    ScrollProvider,
} from '@providers';

import {
    CountingComponent,
    JourneyComponent,
    SettingsComponent,
    LoginComponent,
    ResetPasswordComponent,
    LayoutDataComponent,
    AboutComponent as AboutPageComponent,
    HelpComponent as HelpPageComponent,
    LegalsComponent as LegalsPageComponent,
    AccountSettingsComponent as UserSettingsPageComponent,
    AccountSettingsComponent as AccountSettingsPageComponent,
    AbstractSettingsComponent,
    LayoutWithoutDataComponent,
    EditFavoriteLocalbusinessComponent,
    EditPasswordComponent,
    UnderstandDataComponent,
    UnderstandDataDetailComponent,
    CompareDataComponent,
    CompareDataDetailComponent,
    UnderstandColorsComponent,
    UnderstandMapsComponent,
    UnderstandMapsDetailComponent,
} from '@pages';

import {
    InputFormComponent,
    DefaultCalendarComponent,
    SearchBoxComponent,
    JourneyTableComponent,
    NavBarTabsComponent,
    ButtonComponent,
    AbstractResetComponent,
    AskResetComponent,
    ConfirmResetComponent,
    DoResetComponent,
    NestedCardComponent,
    CountingDataComponent,
    CountingCircleComponent,
    TemplateComponent,
    CalendarSelectComponent,
    CalendarSliderComponent,
    CalendarTypeComponent,
    ModalCalendarDefaultComponent,
    ActionBarComponent,
    SelectPeriodComponent,
    DropdownHorizontalComponent,
    CheckboxComponent,
    LoaderDefaultComponent,
    LocalbusinessesListComponent,
    ModalLocalbusinessesSearchComponent,
    AboutComponent,
    HelpsComponent,
    HomeComponent,
    LegalsComponent,
    SettingsListComponent,
    DetailNavComponent,
    CountingMainComponent,
    InputGroupsComponent,
    DataMainComponent,
    ModalJourneyDetailComponent,
    JourneyDetailZoneComponent,
    DataScatterComponent,
    JourneyBackgroundComponent,
    JourneyDetailsComponent,
    DropdownDefaultComponent,
    ModalProposalDateComponent,
    AgeGenderRecognitionComponent,
    PieChartGenderComponent,
    PieChartAgeComponent,
    VisitRepartitionComponent,
} from '@components';

import {
    RoundedWihtRulesFilter,
    UnixDurationToHumanFilter,
    NumberSuffixFilter,
    ArrayFilterPipe,
    FormatDateByPeriodType,
    LabelsHoursPlusOne,
    LabelsDay,
} from '@pipes';
import { AppComponentResolverDirective } from './directives';
import { ModalProposalDayComponent } from './components/modals/modal-proposal-day/modal-proposal-day.component';
import { CountingGroupsComponent } from './components/counting-page/counting-groups/counting-groups.component';
import { TimePassedInStoreComponent } from './components/counting-page/time-passed-in-store/time-passed-in-store.component';
import { DocumentationComponent } from './pages/settings/documentation/documentation.component';

@NgModule({
    declarations: [
        AppComponent,
        SettingsComponent,
        CountingComponent,
        JourneyComponent,
        InputFormComponent,
        InputGroupsComponent,
        DataMainComponent,
        DefaultCalendarComponent,
        SearchBoxComponent,
        JourneyTableComponent,
        NavBarTabsComponent,
        ButtonComponent,
        AbstractResetComponent,
        AskResetComponent,
        ConfirmResetComponent,
        DoResetComponent,
        LoginComponent,
        ResetPasswordComponent,
        NestedCardComponent,
        CountingCircleComponent,
        CountingDataComponent,
        LayoutDataComponent,
        TemplateComponent,
        ModalCalendarDefaultComponent,
        ActionBarComponent,
        SelectPeriodComponent,
        CalendarSelectComponent,
        DropdownHorizontalComponent,
        CalendarSliderComponent,
        CheckboxComponent,
        LocalbusinessesListComponent,
        ModalLocalbusinessesSearchComponent,
        AboutComponent,
        EditFavoriteLocalbusinessComponent,
        EditPasswordComponent,
        HelpsComponent,
        HomeComponent,
        LegalsComponent,
        SettingsListComponent,
        AccountSettingsPageComponent,
        LoaderDefaultComponent,
        CalendarTypeComponent,
        RoundedWihtRulesFilter,
        ArrayFilterPipe,
        FormatDateByPeriodType,
        LabelsHoursPlusOne,
        LabelsDay,
        NumberSuffixFilter,
        UnixDurationToHumanFilter,
        AppComponentResolverDirective,
        DetailNavComponent,
        LayoutWithoutDataComponent,
        AbstractSettingsComponent,
        AboutPageComponent,
        HelpPageComponent,
        LegalsPageComponent,
        UserSettingsPageComponent,
        CountingMainComponent,
        DataScatterComponent,
        ModalJourneyDetailComponent,
        JourneyDetailZoneComponent,
        JourneyBackgroundComponent,
        DropdownDefaultComponent,
        UnderstandDataComponent,
        UnderstandDataDetailComponent,
        CompareDataComponent,
        CompareDataDetailComponent,
        UnderstandColorsComponent,
        UnderstandMapsComponent,
        UnderstandMapsDetailComponent,
        JourneyDetailsComponent,
        DropdownDefaultComponent,
        ModalProposalDateComponent,
        ModalProposalDayComponent,
        AgeGenderRecognitionComponent,
        PieChartGenderComponent,
        PieChartAgeComponent,
        VisitRepartitionComponent,
        CountingGroupsComponent,
        TimePassedInStoreComponent,
        DocumentationComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        ToastrModule,
        ToastContainerModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        ServiceWorkerModule.register('ngsw-config.json', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        SwiperModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        StoreProvider,
        MathProvider,
        LayoutProvider,
        LocalBusinessServices,
        LocalBusinessesServices,
        OrganizationServices,
        OrganizationsServices,
        UserServices,
        StorageProvider,
        UserProvider,
        ModalProvider,
        DateProvider,
        SwiperProvider,
        AuthService,
        DataService,
        ZoneServices,
        JourneyDetailsService,
        ActionBarVersusCanvasProvider,
        AuthGuard,
        ZonesProvider,
        RefreshProvider,
        CollisionProvider,
        ScrollProvider,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        registerLocaleData(localeFr, 'fr');
    }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
