import { Component, Input } from '@angular/core';
import { AppStore } from '@app/models';
import { DateProvider, StoreProvider } from '@app/providers';
import moment, { Moment } from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewEncapsulation } from '@angular/compiler';

@Component({
    selector: 'app-modal-proposal-date',
    templateUrl: './modal-proposal-date.component.html',
    styleUrls: ['./modal-proposal-date.component.scss'],
})
export class ModalProposalDateComponent {
    @Input() view!: string;

    private current: Moment = moment();
    private versus: Moment = moment().subtract(1, 'day');

    constructor(
        private $store: StoreProvider,
        private dateProvider: DateProvider,
        public activeModal: NgbActiveModal
    ) {}

    public async adjusteDate() {
        const current = this.current.subtract(1, 'd');
        const versus = this.versus.subtract(1, 'd');

        const begin = this.dateProvider.fromDateToStore(current);
        const end = this.dateProvider.fromDateToStore(current, true);

        const beginVs = this.dateProvider.fromDateToStore(versus);
        const endVs = this.dateProvider.fromDateToStore(versus, true);

        this.$store.emit({
            app: {
                props: {
                    dates: {
                        current: { start: begin, end: end },
                        versus: { start: beginVs, end: endVs },
                        select: 'day',
                        type: 'day',
                    },
                },
            } as AppStore,
        });

        this.activeModal.close();
    }
}
