<div class="calendar_dropdown">
    <div class="calendar_selected">
        <app-dropdown-horizontal-default
            [data]="calendars"
            [selected]="calendar"
            folder="calendar"
            (selectedItem)="calendarSelected.emit($event)"
            _i18nKey="dates.toComponents."
        ></app-dropdown-horizontal-default>
    </div>
</div>
