<div
    class="h-100 w-100 col-12 text-right d-flex flex-column justify-content-center align-items-center"
>
    <ng-template
        *ngIf="actualStep === 0"
        [ngComponentOutlet]="resetStepsComponents[0]"
    ></ng-template>
    <ng-template
        *ngIf="actualStep === 1"
        [ngComponentOutlet]="resetStepsComponents[1]"
    ></ng-template>
    <ng-template
        *ngIf="actualStep === 2"
        [ngComponentOutlet]="resetStepsComponents[2]"
    ></ng-template>
</div>
