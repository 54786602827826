<div
    class="container-fluid p-0 h-100 d-flex flex-column no-scrollable"
    *ngIf="$bucketData | async as bucketData; else loadingSpinner"
>
    <div
        class="d-flex flex-column row justify-content-between p-0 m-0 h-100 fadeInFast"
        *ngIf="!loading; else loadingSpinner"
    >
        <div
            class="col-12 p-0 m-0 position-relative center"
            [ngClass]="app!.props?.detailNav?.active?.name === 'main' ? 'h-40' : 'h-35'"
        >
            <ng-container
                *ngIf="app.props?.detailNav?.active?.name !== 'groups'"
            >
                <app-counting-circle
                    [current]="bucketData.principalData.current.nbPerson"
                    [versus]="bucketData.principalData.versus.nbPerson"
                    [variation]="bucketData.principalData.comparison.nbPerson"
                    [icon]="'/assets/svg/customers-dark.svg'"
                >
                </app-counting-circle>
            </ng-container>

            <ng-container
                *ngIf="app.props?.detailNav?.active?.name === 'groups'"
            >
                <app-counting-circle
                    [current]="bucketData.principalData.current.nbGroups"
                    [versus]="bucketData.principalData.versus.nbGroups"
                    [variation]="bucketData.principalData.comparison.nbGroups"
                    [icon]="'/assets/svg/customers-group-dark.svg'"
                ></app-counting-circle>
            </ng-container>
        </div>

        <div
            class="col-12 p-0 m-0 overflow-hidden"
            [ngSwitch]="app!.props?.detailNav?.active?.name"
            [ngClass]="
                app!.props?.detailNav?.active?.name === 'main' ? 'h-60' : 'h-65'
            "
        >
            <app-counting-main
                class="fadeInFast"
                *ngSwitchCase="'main'"
                [selectedLocalBusiness]="app.props!.selectedLocalBusiness!"
                [principalData]="bucketData.principalData"
            ></app-counting-main>
            <app-counting-visit-repartition
                class="fadeInFast"
                *ngSwitchCase="'customers-counting'"
                [data]="bucketData.visitsRepartitionData.current"
                [dataVs]="bucketData.visitsRepartitionData.versus"
                [calendarSelect]="app.props!.dates!.select!"
                [calendarType]="app.props!.dates!.type!"
            >
            </app-counting-visit-repartition>
            <app-counting-groups
                class="fadeInFast"
                *ngSwitchCase="'groups'"
                [data]="bucketData.groupsRepartitionData.current"
                [dataVs]="bucketData.groupsRepartitionData.versus"
                [calendarSelect]="app.props!.dates!.select!"
                [calendarType]="app.props!.dates!.type!"
            ></app-counting-groups>
            <app-time-passed-in-store
                class="fadeInFast"
                *ngSwitchCase="'repartition'"
                [data]="bucketData.timePassedInStoreData.current"
                [dataVs]="bucketData.timePassedInStoreData.versus"
                [calendarSelect]="app.props!.dates!.select!"
                [calendarType]="app.props!.dates!.type!"
            ></app-time-passed-in-store>
            <app-age-gender-recognition
                class="fadeInFast"
                *ngSwitchCase="'age-gender-recognition'"
                [calendarSelect]="app.props!.dates!.select!"
                [localBusiness]="app.props!.selectedLocalBusiness!"
                [range]="{
                    begin: app.props!.dates!.current!.start.asUnix,
                    end: app.props!.dates!.current!.end!.asUnix
                }"
            ></app-age-gender-recognition>
        </div>
    </div>
</div>
<ng-template #loadingSpinner>
    <div class="h-100 w-100 d-flex">
        <div class="align-self-center m-auto">
            <app-loader-default></app-loader-default>
        </div>
    </div>
</ng-template>
