<div
    class="h-100 w-100 col-12 text-right d-flex flex-column justify-content-center align-items-start"
>
    <h3>{{ 'navSettings.editPassword' | translate }}</h3>
    <form
        [formGroup]="resetPasswordform"
        (ngSubmit)="onSubmit()"
        *ngIf="resetPasswordform"
        class="w-100"
    >
        <!-- <app-input-form-default
            class="w-100"
            [value]="resetPasswordform.value.login"
            placeHolder="input.oldPassword"
            (inputChange)="updateFormByKey('oldPassword', $event)"
        ></app-input-form-default> -->
        <app-input-form-default
            class="w-100"
            type="password"
            [value]="resetPasswordform.value.password"
            placeHolder="input.newPassword"
            (inputChange)="updateFormByKey('password', $event)"
        ></app-input-form-default>

        <app-input-form-default
            class="w-100"
            type="password"
            [value]="resetPasswordform.value.confirmPassword"
            placeHolder="input.confirmPassword"
            (inputChange)="updateFormByKey('confirmPassword', $event)"
        ></app-input-form-default>
        <div *ngIf="isError" class="red">
            {{ messageError | translate }}
        </div>
        <div *ngIf="updatePasswordDone" class="green">
            {{ 'confirmPasswordResetDone' | translate }}
        </div>
        <app-button-default
            (btnClick)="onSubmit()"
            [loading]="loading"
            [content]="'button.submit'"
        ></app-button-default>
    </form>
    <div class="warning py-2">
        <img class="pe-2" src="/assets/svg/warning.svg" alt="" width="30px" />{{
            'warningResetPassword' | translate
        }}
    </div>
</div>
