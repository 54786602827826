import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreProvider } from '@app/providers';
import { MainService } from '@app/services/main.service';
import { AbstractThemeComponent } from './../theme-layout';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-layout-without-data',
    templateUrl: './layout-without-data.component.html',
    styleUrls: ['./layout-without-data.component.scss'],
})
export class LayoutWithoutDataComponent
    extends AbstractThemeComponent
    implements OnInit, OnDestroy
{
    public version = environment.version;

    public iconDisplay?: string;
    public sizeIcon?: string;

    constructor(
        $store: StoreProvider,
        router: Router,
        mainService: MainService
    ) {
        super(router, $store, mainService);
    }

    public backToSettings = () => window.history.back();
}
