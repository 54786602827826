import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppStore } from '@app/models';
import { CustomDateObject } from '@app/models/Calendar';
import { StoreProvider } from '@app/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-modal-calendar-default',
    templateUrl: './modal-calendar-default.component.html',
    styleUrls: ['./modal-calendar-default.component.scss'],
})
export class ModalCalendarDefaultComponent implements OnInit, OnDestroy {
    public app!: AppStore;
    private $storeSubs!: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private $store: StoreProvider
    ) {}

    ngOnInit(): void {
        this.$storeSubs = this.$store
            .listen('app')
            .subscribe((app) => (this.app = app));
    }

    public selectAndClose(date: CustomDateObject): void {
        this.$store.emitReloadAll(true);
        this.$store.emit({
            app: { props: { dates: { ...this.app.props?.dates, ...date } } },
        } as AppStore);
        this.activeModal.close(date);
    }

    ngOnDestroy(): void {
        this.$storeSubs.unsubscribe();
    }
}
