import {
    Component,
    Input,
    OnInit,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import { AppStore, TimePassedInStoreDatas } from '@app/models';
import { StoreProvider } from '@app/providers';
import { Subscription } from 'rxjs';
import { CALENDAR_SELECT, CALENDAR_TYPE } from '@app/models/Calendar';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-time-passed-in-store',
    templateUrl: './time-passed-in-store.component.html',
    styleUrls: ['./time-passed-in-store.component.scss'],
})
export class TimePassedInStoreComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input() data!: TimePassedInStoreDatas;
    @Input() dataVs!: TimePassedInStoreDatas;
    public _value: number[] = [];
    public _valueVs: number[] = [];
    public _labels: string[] = [];
    public _labelsVs: string[] = [];

    @Input() calendarSelect!: CALENDAR_SELECT;
    @Input() calendarType!: CALENDAR_TYPE;

    public emptyState = false;

    public app!: AppStore;
    private $storeSubs!: Subscription;
    private charts: Chart[] = [];

    public averageDisplay = {
        versus: '0',
        curent: '0',
    };

    constructor(private $store: StoreProvider) {}

    ngOnInit() {
        this.$storeSubs = this.$store
            .listen('app')
            .subscribe((app) => (this.app = app));

        if (
            this.data.averageTimePassed === 0 &&
            this.dataVs.averageTimePassed === 0
        )
            this.emptyState = true;
        else {
            this._value = Object.values(this.data.timePassedByLevel);
            this._valueVs = Object.values(this.dataVs.timePassedByLevel);
            this._labels = Object.keys(this.data.timePassedByLevel);
            this._labelsVs = Object.keys(this.dataVs.timePassedByLevel);
        }

        let currentInt = Math.floor(Math.abs(this.data.averageTimePassed));
        let currentDecimal = Math.round(
            (Math.abs(this.data.averageTimePassed) * 60) % 60
        );

        this.averageDisplay.curent =
            currentInt + 'min ' + currentDecimal + 'sec';

        let versusInt = Math.floor(Math.abs(this.dataVs.averageTimePassed));
        let versusDecimal = Math.round(
            (Math.abs(this.dataVs.averageTimePassed) * 60) % 60
        );

        this.averageDisplay.versus = versusInt + 'min ' + versusDecimal + 'sec';
    }

    async ngAfterViewInit() {
        if (this._labels) this.initDataLabels(this._labels);
    }

    private initDataLabels(labelsList: string[]) {
        labelsList.forEach((label, index) => {
            this.charts.push(
                new Chart(
                    document.getElementById(
                        'timePassedInStore' + index
                    ) as HTMLCanvasElement,
                    {
                        data: {
                            datasets: [
                                {
                                    data: [this._value[index]],
                                    borderColor: '#e3a719',
                                    backgroundColor: '#e3a719',
                                    barThickness: 12,
                                    borderRadius: {
                                        bottomRight: 25,
                                        topRight: 25,
                                    },
                                },
                                {
                                    data: [this._valueVs[index]],
                                    borderColor: '#024F5D',
                                    backgroundColor: '#024F5D',
                                    barThickness: 12,
                                    borderRadius: {
                                        bottomRight: 25,
                                        topRight: 25,
                                    },
                                },
                            ],
                            labels: [label],
                        },
                        type: 'bar',
                        options: {
                            indexAxis: 'y',
                            elements: {
                                bar: {
                                    borderWidth: 2,
                                },
                            },
                            responsive: true,
                            scales: {
                                y: {
                                    ticks: {
                                        display: false,
                                        autoSkip: false,
                                        stepSize: 20,
                                    },
                                },
                                x: {
                                    beginAtZero: true,
                                    display: false,
                                    max: Math.max(
                                        ...this._value,
                                        ...this._valueVs
                                    ),
                                },
                            },
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                title: {
                                    display: false,
                                },
                                datalabels: {
                                    display: false,
                                },
                                tooltip: {
                                    enabled: false,
                                },
                            },
                        },
                    }
                )
            );
        });
    }

    ngOnDestroy(): void {
        this.$storeSubs?.unsubscribe();
        this.charts.splice(0, this.charts.length);
    }
}
