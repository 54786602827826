<div class="data-main center h-100 w-100">
    <div
        class="col-12 m-0 p-0 h-100 w-100 position-absolute center h-100 _z-i-1"
    >
        <app-data-main
            class="col-12"
            [showRedBg]="variation < 0"
            [current]="current"
            [versus]="versus"
            [variation]="variation"
            [icon]="icon"
        ></app-data-main>
    </div>
</div>
