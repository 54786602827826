<div class="bg-black border-none fixed-bottom _h-92" style="color: white">
    <div class="modal-body d-flex flex-column align-items-center p-2 h-100">
        <div class="w-100 h-5">
            <button (click)="activeModal.dismiss()" class="btn">
                {{ 'button.back' | translate }}
            </button>
        </div>
        <div
            class="w-100 h-40 position-relative d-flex flex-column align-items-center"
        >
            <app-journey-detail-zone
                [dataZone]="dataZone"
                [customers]="customers"
                [customersVs]="customersVs"
                [customersVariation]="customersVariation"
            ></app-journey-detail-zone>
        </div>
        <div
            class="w-100 h-48 d-flex flex-column align-items-center justify-content-center position-relative"
        >
            <app-journey-background
                [dates]="dates"
                [boxId]="dataZone.boxId"
                [zoneId]="dataZone.zoneId"
            ></app-journey-background>
        </div>
    </div>
</div>
