import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { CustomDateObject } from '@app/models/Calendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dropdown-horizontal-default',
    templateUrl: './dropdown-horizontal.component.html',
    styleUrls: ['./dropdown-horizontal.component.scss'],
})
export class DropdownHorizontalComponent implements OnInit {
    @Input() data: any[] | any;
    @Input() dates: CustomDateObject | any;
    @Input() selected: any;
    @Input() _i18nKey: string = '';
    @Input() folder: string = '';
    public disabledData: any = [];

    @Output() selectedItem = new EventEmitter();

    constructor(
        private cdRef: ChangeDetectorRef,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.initData();
    }

    public initData() {
        if (this.data.length < 4) {
            this.disabledData.push('day');
        }
        if (this.data.length < 3) {
            this.disabledData.push('week');
        }
        if (this.data.length < 2) {
            this.disabledData.push('month');
        }
    }

    public onSelect(selected: any) {
        this.selected = selected;
        this.selectedItem.emit(this.selected);
        this.modalService.dismissAll();
    }

    open(content: any, name: string) {
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-calandar',
            windowClass: 'custom-modal-calendar-' + name,
            animation: false,
        });
    }
}
