import { Component, Input, OnInit } from '@angular/core';
import {
    Range,
    ExceptionData,
    AgeGenderData,
    CALENDAR_SELECT,
    LocalBusinesses,
} from '@models';
import { DateProvider } from '@providers/date.provider';
import { CountingService } from '@app/services';

@Component({
    selector: 'app-age-gender-recognition',
    templateUrl: './age-gender-recognition.component.html',
})
export class AgeGenderRecognitionComponent implements OnInit {
    @Input() calendarSelect!: CALENDAR_SELECT;
    @Input() range!: Range;
    @Input() localBusiness!: LocalBusinesses;

    public $data!: AgeGenderData;

    constructor(
        private service: CountingService,
        private dateProvider: DateProvider
    ) {}

    ngOnInit() {
        // Empêche l'appel API si l'utilisateur n'a pas souscrit à l'option
        if (this.checkIfSubscribe()) {
            this.getData();
        }
    }

    public getData() {
        let beginHour = this.dateProvider.getOpeningTime(
            this.range.begin,
            this.localBusiness.openingCalendar
        );

        let begin = this.dateProvider.setHourInTimeStamp(
            this.range.begin,
            beginHour
        );
        let beginVs = this.dateProvider.setHourInTimeStamp(
            this.range.begin,
            beginHour
        );

        this.service
            .getAgeGenderData(this.localBusiness.id!, this.calendarSelect, {
                begin,
                beginVs,
            })
            .subscribe((data: AgeGenderData) => (this.$data = data));
    }

    public isAgeGender(x: any): x is AgeGenderData {
        return (
            Object.keys(x).includes('current') &&
            Object.keys(x).includes('current')
        );
    }

    public checkIfException(data: AgeGenderData | ExceptionData) {
        let res = false;

        if (Object.keys(data).includes('exception')) res = true;

        if (this.isAgeGender(data)) {
            if (data.current.nbPerson === 0 || data.versus.nbPerson === 0) {
                res = true;
            }
        }

        return res;
    }

    public checkIfSubscribe() {
        return this.localBusiness.ageGenderEnabled ? true : false;
    }
}
