import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStore } from '@app/models';
import { CustomDateObject } from '@app/models/Calendar';
import { ModalProvider, StoreProvider } from '@app/providers';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit {
    @Input() dates?: CustomDateObject;
    @Input() localBusiness: any;
    @Output() dateSelection: EventEmitter<CustomDateObject> =
        new EventEmitter();

    @Input() route!: string;
    @Input() lightMode?: boolean = false;

    public app!: AppStore;
    private $appSubscription!: Subscription;

    public show = true;

    public dataInfoPath?: string;
    public dataInfoIcon?: string;

    constructor(
        private modalProvider: ModalProvider,
        private $store: StoreProvider
    ) {}

    ngOnInit() {
        this.$appSubscription = this.$store
            .listen('app')
            .subscribe((app: AppStore) => {
                this.app = app;
                this.dataInfoPath = this.app.props?.detailNav?.active?.name;
                this.dataInfoIcon = this.app.props?.detailNav?.active?.icon;
                if (!this.dataInfoIcon?.includes('-light')) {
                    this.dataInfoIcon =
                        this.dataInfoIcon === 'home-dark'
                            ? this.dataInfoIcon.replace('-dark', '-light')
                            : this.dataInfoIcon + '-light';
                }
            });
    }

    public setSelectedDate(dates: CustomDateObject, force?: boolean) {
        if (force) {
            this.show = false;
        }
        this.dateSelection.emit(dates);
        setTimeout(() => {
            this.show = true;
        });
    }

    public openSelectLocalBusiness() {
        this.modalProvider.open('localBusinesses', {
            scrollable: false,
            modalDialogClass: 'localbusinesses-modal-content h-100',
        });
    }
}
