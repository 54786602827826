import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreProvider } from '@app/providers';
import { UserServices } from '@app/services';
import { of, switchMap, take } from 'rxjs';
import { AbstractResetComponent } from '..';

@Component({
    selector: 'app-confirm-reset',
    templateUrl: './confirm-reset.component.html',
    styleUrls: ['./confirm-reset.component.scss'],
})
export class ConfirmResetComponent
    extends AbstractResetComponent
    implements OnInit
{
    constructor(
        router: Router,
        store: StoreProvider,
        userServices: UserServices
    ) {
        super(store, router, userServices);
    }

    ngOnInit(): void {
        this.store
            .listen('reset-steps')
            .pipe(
                take(1),
                switchMap((value) => {
                    if (value.props['id'] && value.props['token']) {
                        this.isSuccess = true;
                        setTimeout(() => {
                            this.$showWaitingToken.next(false);
                        }, 2000);
                        this.setStep(1);
                        this.setUserID(value.props.id);
                        this.setToken(value.props.token);
                        return this.checkToken();
                    } else {
                        return of(value);
                    }
                })
            )
            .subscribe(
                (value) => {
                    this.checkResetStatus(value);

                    if (value['new_token']) {
                        console.warn('no here steps new');
                        this.setToken(value.new_token);
                        this.nextStep();
                    }
                },
                () => {
                    this.isWaiting = false;
                    this.isSuccess = false;
                    this.setError('confirm reset : bad token');
                }
            );
    }
}
