import { Component, OnInit } from '@angular/core';
import { AppStore, User } from '@app/models';
import { ModalProvider, StoreProvider } from '@app/providers';
import { Observable } from 'rxjs';
import { getUser } from './../../../tests/unit/mock';
@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
    public $user!: Observable<User>;

    public $app: Observable<AppStore> = this.$store.listen('app');

    constructor(
        private modalProvider: ModalProvider,
        private $store: StoreProvider
    ) {}

    ngOnInit(): void {
        this.$user = getUser();
    }
}
