import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LayoutProvider {
    public scrollToSelected(elementiD: string): void {
        document
            ?.getElementById(elementiD)
            ?.scrollIntoView({ behavior: 'smooth' });
    }
}
