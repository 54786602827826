import { Component, Input, OnInit } from '@angular/core';
import { AppStore, LocalBusinesses, PrincipalData } from '@app/models';
import { MathProvider, StoreProvider } from '@app/providers';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-counting-main',
    templateUrl: './counting-main.component.html',
    styleUrls: ['./counting-main.component.scss'],
})
export class CountingMainComponent implements OnInit {
    @Input() selectedLocalBusiness!: LocalBusinesses;
    @Input() principalData!: PrincipalData;

    public displayRegisterDataInfo = true;
    public variationAttractivity: any;

    public app!: AppStore;
    private $appSubscription!: Subscription;
    public nameOfStorageItem!: string;

    constructor(
        public mathProvider: MathProvider,
        private $store: StoreProvider
    ) {}

    ngOnInit() {
        this.init();
    }

    public init() {
        this.$appSubscription = this.$store
            .listen('app')
            .subscribe((app: AppStore) => {
                this.app = app;
            });

        this.nameOfStorageItem = 'registerDataInfo_' + this.app.props?.user.id;

        if (localStorage.getItem(this.nameOfStorageItem)) {
            this.displayRegisterDataInfo = !(
                localStorage.getItem(this.nameOfStorageItem) === '0'
            );
        }
    }

    public deleteInfo() {
        this.displayRegisterDataInfo = false;
        localStorage.setItem(this.nameOfStorageItem, '0');
    }
}
