<div class="iframeContainer">
    <iframe
        class="iframeDemo"
        src="https://www.youtube.com/embed/SOpnr2pg7A8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
    ></iframe>
</div>

<a
    href="https://docs.newivs.fr/DOCUMENTATION%20APPLICATION%20IVSTORE.pdf"
    target="_blank"
>
    <div class="donwloadLink">
        <p>Télécharger la documentation</p>
        <div class="imgContainer">
            <img src="../../../../assets/icons/pdf.png" alt="pdfIcon" />
        </div>
    </div>
</a>
