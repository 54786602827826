import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserServices } from '@app/services';

@Component({
    selector: 'app-edit-password',
    templateUrl: './edit-password.component.html',
    styleUrls: ['./edit-password.component.scss'],
})
export class EditPasswordComponent implements OnInit {
    public resetPasswordform!: UntypedFormGroup;
    public submitted = false;
    public loading = false;
    public isError = false;
    public messageError = '';
    public updatePasswordDone = false;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserServices,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.resetPasswordform = this.formBuilder.group({
            // oldPassword: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        });
    }

    public updateFormByKey(key: string, value: any) {
        this.resetPasswordform.patchValue({ [key]: value });
    }

    public onSubmit(): void {
        this.submitted = true;
        this.loading = true;
        if (this.resetPasswordform.invalid) {
            this.messageError = 'invalidForm';
            this.isError = true;
            this.loading = false;
            return;
        } else {
            let newpassword = this.resetPasswordform.value.password;
            let confirmnewpassword =
                this.resetPasswordform.value.confirmPassword;
            if (newpassword !== confirmnewpassword) {
                this.messageError = 'passwordError';
                this.isError = true;
                this.loading = false;
                return;
            } else {
                this.userService.postForceNewPassword(newpassword).subscribe(
                    () => {
                        this.isError = false;
                        this.loading = false;
                        this.updatePasswordDone = true;
                        setTimeout(() => {
                            this.router.navigate(['/setting']);
                        }, 4000);
                    },
                    (error) => {
                        switch (error.status) {
                            case 500:
                                this.messageError = 'internError';
                                this.isError = true;
                                this.loading = false;
                                return;
                            default:
                                this.messageError = 'internError';
                                this.isError = true;
                                this.loading = false;
                                return;
                        }
                    }
                );
            }
        }
    }
}
