<div>
    <div class="modal-header bg-dark rounded">
        <h4 class="modal-title text-white">
            {{ view | translate }}
        </h4>
        <div
            class="modal-calendar-close pointer"
            (click)="activeModal.dismiss()"
        >
            <img src="assets/svg/close-light.svg" height="25px" />
        </div>
    </div>
    <div class="modal-body calendar_body">
        <div class="row">
            <div class="col-12 text-center">
                <div>
                    {{ 'errors.invalidDateWeek' | translate }}
                </div>
            </div>

            <div class="col-12 text-center d-flex flex-row">
                <app-button-default
                    content="button.no"
                    (btnClick)="activeModal.dismiss()"
                    _contentClass="red"
                ></app-button-default>

                <app-button-default
                    class="ms-auto"
                    content="button.yes"
                    _contentClass="red"
                    (btnClick)="adjusteDate()"
                ></app-button-default>
            </div>
        </div>
    </div>
</div>
