import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegularExpressionConstant } from '@app/const';
import { StoreProvider } from '@app/providers';
import { UserServices } from '@app/services';
import { Subscription } from 'rxjs';
import { AbstractResetComponent } from '..';

@Component({
    selector: 'app-do-reset',
    templateUrl: './do-reset.component.html',
    styleUrls: ['./do-reset.component.scss'],
})
export class DoResetComponent
    extends AbstractResetComponent
    implements OnInit, OnDestroy
{
    public doResetForm!: UntypedFormGroup;
    public submitted = false;
    public doRestFieldType = 'password';

    public isPasswordChanged = false;
    private props: any = {};
    private store$!: Subscription;

    public alreadySubmited = false;
    constructor(
        router: Router,
        storeProvider: StoreProvider,
        userServices: UserServices,
        private formBuilder: UntypedFormBuilder
    ) {
        super(storeProvider, router, userServices);
    }

    ngOnInit(): void {
        this.store$ = this.store
            .listen('reset-steps')
            .subscribe((value) => (this.props = value.props));

        this.doResetForm = this.formBuilder.group(
            {
                newPlainPassword: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(20),
                        Validators.minLength(8),
                        Validators.pattern(RegularExpressionConstant.PASSWORD),
                    ]),
                ],
                newPlainPasswordConfirmation: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(20),
                        Validators.minLength(8),
                        Validators.pattern(RegularExpressionConstant.PASSWORD),
                    ]),
                ],
            },
            {
                validators: (fg: UntypedFormGroup) => {
                    const first: any = fg.get('newPlainPassword');
                    const second: any = fg.get('newPlainPasswordConfirmation');

                    return first.value === second.value
                        ? null
                        : { passwordDissmiss: true };
                },
            }
        );
    }

    public updateFormByKey(key: string, value: any) {
        this.doResetForm.patchValue({ [key]: value });
    }

    public onSubmit() {
        this.alreadySubmited = true;
        if (this.alreadySubmited) {
            setTimeout(() => {
                this.alreadySubmited = false;
            }, 6000);
        }
        if (this.doResetForm.valid) {
            this.setNewPassword(
                this.doResetForm.controls['newPlainPassword'].value
            );
            this.updatePassword(
                this.doResetForm.controls['newPlainPassword'].value,
                this.props['id'],
                this.props['token']
            ).subscribe(
                () => {
                    this.isPasswordChanged = true;
                    setTimeout(() => {
                        this.quit();
                    }, 5000);
                },
                () => {
                    this.setError('do-reset');
                }
            );
        }
    }

    public override goLogin() {
        this.doResetForm.patchValue({
            newPlainPassword: '',
            newPlainPasswordConfirmation: '',
        });
        this.router.navigate(['/login']);
        this.store.emit({
            'reset-steps': { props: { steps: 0 } },
        });
    }

    public override ngOnDestroy(): void {
        this.store$.unsubscribe();
        this.removeError();
    }
}
