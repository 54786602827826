import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternSettingsRouter } from '@app/const';
import { AuthService } from '@app/services';

@Component({
    template: '',
})
export class AbstractSettingsComponent {
    public settingsList = Object.keys(InternSettingsRouter);
    public accountSettingsList = Object.keys(
        InternSettingsRouter.accountSettings.children
    );
    public helpSettingsList = Object.keys(InternSettingsRouter.help.children);
    public understandDataList = Object.keys(
        InternSettingsRouter.help.children.understandData.children
    );
    public compareDataList = Object.keys(
        InternSettingsRouter.help.children.compareData.children
    );
    public understandMapsList = Object.keys(
        InternSettingsRouter.help.children.understandMaps.children
    );

    public goHome = '';
    public _InternSettingsRouter = InternSettingsRouter;

    public getBasePath = (path: string) => `/setting/${path}`;

    public subKey = this.route.snapshot.paramMap.get('itemKey') as string;

    constructor(
        private auth: AuthService,
        public router: Router,
        private route: ActivatedRoute
    ) {}

    public editFavoriteBusiness() {
        this.auth.logout();
    }

    public logout() {
        this.auth.logout();
    }

    public onItemSelect(
        itemKey: keyof typeof InternSettingsRouter,
        router: any
    ) {
        this.router.navigate([this.getBasePath(router[itemKey].path)]);
    }
}
