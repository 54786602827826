<div>
    <div class="modal-header bg-dark rounded">
        <h4 class="modal-title text-white">
            {{ app.props?.dates?.current?.start?.asHuman }}
        </h4>
        <div
            class="modal-calendar-close pointer"
            (click)="activeModal.dismiss()"
        >
            <img src="assets/svg/close-light.svg" height="25px" />
        </div>
    </div>
    <div class="modal-body calendar_body">
        <div class="row">
            <div class="col-12">
                <app-calendar-default
                    [dates]="app.props?.dates"
                    (dateSelect)="selectAndClose($event)"
                ></app-calendar-default>
            </div>
        </div>
    </div>
</div>
