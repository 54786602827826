<div class="d-flex flex-column p-0 col-12">
    <div class="dark h3">{{ 'resetPassword' | translate }}</div>
    <form
        *ngIf="!isPasswordChanged; else passwordIsChanged"
        [formGroup]="doResetForm"
        (ngSubmit)="onSubmit()"
        autocomplete="new-password"
        class="text-center row justify-content-start"
    >
        <div class="col-12">
            <div class="form-group position-relative">
                <app-input-form-default
                    placeHolder="input.newPassword"
                    [type]="
                        this.doResetForm.controls['newPlainPassword'].value
                            .length === 0
                            ? 'text'
                            : doRestFieldType
                    "
                    (inputChange)="updateFormByKey('newPlainPassword', $event)"
                    [isValid]="
                        !doResetForm.controls['newPlainPassword'].valid &&
                        alreadySubmited
                    "
                    [isInvalid]="
                        !doResetForm.controls['newPlainPassword'].valid &&
                        alreadySubmited
                    "
                    isInvalidFeedBack="invalidPassword"
                    [showIsValidLabel]="false"
                ></app-input-form-default>
            </div>
        </div>

        <div class="col-12">
            <div class="form-group position-relative">
                <app-input-form-default
                    placeHolder="input.confirmPassword"
                    [type]="
                        this.doResetForm.controls[
                            'newPlainPasswordConfirmation'
                        ].value.length === 0
                            ? 'text'
                            : doRestFieldType
                    "
                    (inputChange)="
                        updateFormByKey('newPlainPasswordConfirmation', $event)
                    "
                    [isValid]="
                        !doResetForm.controls['newPlainPasswordConfirmation']
                            .valid && alreadySubmited
                    "
                    [isInvalid]="
                        !doResetForm.controls['newPlainPasswordConfirmation']
                            .valid && alreadySubmited
                    "
                    isInvalidFeedBack="invalidConfirmPassword"
                    [showIsValidLabel]="false"
                ></app-input-form-default>
            </div>
        </div>
        <div class="py-2 px-0 mx-0">
            <div
                class="alert long-text text-start p-0 mx-0 bg-transparent"
                role="alert"
            >
                {{ 'conditionsPassword' | translate }}
            </div>
        </div>

        <div class="col-12">
            <div class="py-1 w-auto">
                <button type="button" class="btn btn_black" (click)="goLogin()">
                    {{ 'back' | translate }}
                </button>
            </div>
            <div class="py-1 w-auto ms-auto">
                <button
                    type="button"
                    class="btn btn_black"
                    (click)="onSubmit()"
                >
                    {{ 'continue' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>

<ng-template #passwordIsChanged>
    <div class="col-12 d-flex flex-column p-1 w-100 text-center">
        <div class="col-12 py-2">{{ 'passwordChanged' | translate }}</div>

        <div class="col-12 py-2">
            {{ 'automaticRedirectToLogin' | translate }}
        </div>

        <div class="row justify-content-start">
            <div class="py-1 w-auto ms-auto">
                <button type="button" class="btn btn_black" (click)="quit()">
                    {{ 'backToLogin' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
