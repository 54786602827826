import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import moment, { Moment } from 'moment';
import {
    LocalBusinessesServices,
    OrganizationsServices,
    UserServices,
} from '.';
import { DateProvider } from '@app/providers';
import { AppStore } from '@app/models';
import { LocalBusinesses } from '@app/models/LocalBusiness';

@Injectable({ providedIn: 'root' })
export class MainService {
    constructor(
        private userService: UserServices,
        private localBusinesseService: LocalBusinessesServices,
        private organizationsService: OrganizationsServices,
        private dateProvider: DateProvider
    ) {}

    public fetchAllMainData(app: AppStore | undefined) {
        if (app?.props?.mainDataIsFeteched) return of(app?.props);

        return this.mapMainDataToStore(
            forkJoin([
                this.userService.getUser(),
                this.addInstalationDateToBusiness(
                    this.localBusinesseService.getBusinesses(true)
                ),
                this.organizationsService.getOrganizations(),
            ]),
            app
        );
    }

    public addInstalationDateToBusiness(
        businesses: Observable<any>
    ): Observable<any> {
        return businesses.pipe(
            map((_businesses) => {
                return (_businesses as any).localBusiness.map(
                    (_business: any) => {
                        let boxesHolder = (_businesses as any).boxes.filter(
                            (box: any) => box.localBusiness === _business.id
                        );

                        const _boxWithDate = boxesHolder.filter(
                            (subValue: any) => subValue.activationDate
                        );
                        const orderedDates = _boxWithDate.sort(function (
                            a: any,
                            b: any
                        ): number {
                            return (
                                Date.parse(a.activationDate) -
                                Date.parse(b.activationDate)
                            );
                        });

                        let instalationDate;

                        if (orderedDates[0]) {
                            instalationDate = moment(
                                orderedDates[0].activationDate
                            );
                        } else {
                            instalationDate = moment();
                        }

                        _business.instalationDate =
                            this.dateProvider.fromDateToStore(instalationDate);

                        return _business;
                    }
                );
            })
        );
    }

    private mapMainDataToStore = (obs: Observable<any>, app: any) =>
        obs.pipe(
            switchMap((data: any) => {
                var favoriteLocalBusinessOrDefault =
                    data[0][0]?.favorite_localBusiness || data[1][0];
                var indexOFLocalBusinessOrDefault = (
                    data[1] as LocalBusinesses[]
                ).findIndex(
                    (val) => val.id === favoriteLocalBusinessOrDefault.id
                );

                return this.localBusinesseService
                    .getHours(data[1][indexOFLocalBusinessOrDefault])
                    .pipe(
                        map((hours) => {
                            if (
                                data[1][indexOFLocalBusinessOrDefault]
                                    ?.favorite_localBusiness
                            ) {
                                data[1][
                                    indexOFLocalBusinessOrDefault
                                ].favorite_localBusiness.openingCalendar = hours;
                            } else {
                                data[1][
                                    indexOFLocalBusinessOrDefault
                                ].openingCalendar = hours;
                            }

                            return data;
                        })
                    );
            }),
            map((data) => {
                var favoriteLocalBusinessOrDefault =
                    data[0][0]?.favorite_localBusiness || data[1][0];
                var indexOFLocalBusinessOrDefault = (
                    data[1] as LocalBusinesses[]
                ).findIndex(
                    (val) => val.id === favoriteLocalBusinessOrDefault.id
                );

                return {
                    ...app.props,
                    user: data[0][0],
                    favorite: data[1][indexOFLocalBusinessOrDefault],
                    selectedLocalBusiness:
                        data[1][indexOFLocalBusinessOrDefault],
                    localBusinesses: data[1].sort((a: any, b: any) =>
                        a.legalName > b.legalName
                            ? 1
                            : b.legalName > a.legalName
                            ? -1
                            : 0
                    ),
                    organizations: data[2],
                    mainDataIsFeteched: true,
                };
            })
        );
}
