import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserApi } from '@app/models';
import { LocalBusinesses } from '@app/models/LocalBusiness';
import { map, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserServices {
    constructor(private http: HttpClient) {}

    public getUserDetailById(id?: string | number): Observable<any> {
        return this.http
            .get<UserApi>(`${environment.backendDomain}/users/details/${id}`)
            .pipe(
                map((value) => {
                    return value;
                })
            );
    }

    public getUser(): Observable<any> {
        return this.http
            .get<UserApi>(`${environment.backendDomain}/me`)
            .pipe(switchMap((me) => this.getUserDetailById(me.id)));
    }

    public getUserFavoriteLobalBusiness(id: string | number): Observable<any> {
        return this.http.get<UserApi>(
            `${environment.backendDomain}/user/${id}/settings/localbusiness/favorite`
        );
    }

    public postUserFavoriteLobalBusiness(
        user: UserApi,
        localBusiness: LocalBusinesses
    ): Observable<any> {
        return this.http.post<UserApi>(
            `${environment.backendDomain}/user/${user.id}/settings/localbusiness/favorite/${localBusiness.id}`,
            {}
        );
    }

    public postAskNewPassword(mailOrNickname: string): Observable<any> {
        return this.http.post<any>(
            `${environment.backendDomain}/dashboardV2/ask/password`,
            { mailOrNickname: mailOrNickname, service: 'appmobile' } as never
        );
    }

    public getStatusForResetPassword(
        username: string,
        confirmToken: string
    ): Observable<any> {
        return this.http.post<any>(
            `${environment.backendDomain}/dashboardV2/status/reset/password`,
            {
                username: username,
                token: confirmToken,
            } as never
        );
    }

    /**
     * To change user password if forgot, only self
     *
     * @param password
     * @returns
     */
    public postResetPassword(
        userId: string,
        password: string,
        token: string
    ): Observable<any> {
        return this.http.post<any>(
            `${environment.backendDomain}/dashboardV2/action/reset/password`,
            {
                userId: userId,
                password: password,
                token: token,
            } as never
        );
    }

    /**
     * To change user password directly, only self
     *
     * @param password
     * @returns
     */
    public postForceNewPassword(password: string): Observable<any> {
        return this.http.post<any>(
            `${environment.backendDomain}/dashboardV2/action/reset/password/force`,
            {
                password: password,
            } as never
        );
    }

    public postNewPasswordToken(id: number, token: string): Observable<any> {
        return this.http.post<any>(
            `${environment.backendDomain}/dashboardV2/action/confirmation/token`,
            { id: id, token: token } as never
        );
    }
}
