import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalBusinesses } from '@app/models/LocalBusiness';

@Component({
    selector: 'app-localbusinesses-list',
    templateUrl: './localbusinesses-list.component.html',
    styleUrls: ['./localbusinesses-list.component.scss'],
})
export class LocalbusinessesListComponent {
    @Input() localBusinesses?: LocalBusinesses[];
    @Input() localBusinessesFavorite?: LocalBusinesses;
    @Input() localbusinessSelected?: LocalBusinesses;

    @Input() mode!: 'list' | 'select-new-favorite';

    @Input() fieldToShowAndSearch: keyof LocalBusinesses = 'legalName';

    @Output() itemIsSelected = new EventEmitter();

    public searchValue: string = '';
}
