import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { Calendar } from '@app/lib';
import { AppStore } from '@app/models';
import { CALENDAR_TYPE, CustomDateObject } from '@app/models/Calendar';
import { DateProvider, StoreProvider } from '@app/providers';

import moment from 'moment';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-calendar-default',
    templateUrl: './default-calendar.component.html',
    styleUrls: ['./default-calendar.component.scss'],
})
export class DefaultCalendarComponent implements AfterViewInit, OnDestroy {
    @ViewChild('calendarContainer') calendarContainer!: ElementRef;

    @Input() dates?: CustomDateObject;
    @Output() dateSelect: EventEmitter<CustomDateObject> = new EventEmitter();

    private $storeSub!: Subscription;

    private calendar!: Calendar;

    constructor(
        private dateProvider: DateProvider,
        private storeProvider: StoreProvider
    ) {}

    ngAfterViewInit(): void {
        this.$storeSub = this.storeProvider
            .listen('app')
            .subscribe((app: AppStore) => {
                this.calendar = new Calendar(
                    this.calendarContainer.nativeElement,
                    {
                        format: 'dddd DD/MM/YYYY',
                        bound: false,
                        theme: `custom-calendar-theme ${
                            this.getTheme()[this.dates?.select as never]
                        }`,
                        onSelect: this.onSelect.bind(this),
                        setDefaultDate: true,
                        defaultDate:
                            this.dates?.current?.start.asMoment.toDate(),
                        maxDate: moment().toDate(),
                        minDate:
                            app.props?.selectedLocalBusiness?.instalationDate?.asMoment.toDate(),
                        firstDay: 1,
                        showDaysInNextAndPreviousMonths: true,
                        pickWholeWeek: this.dates?.select === 'week',
                        i18n: {
                            previousMonth: 'Mois précédent',
                            nextMonth: 'Mois prochain',
                            months: [
                                'Janvier',
                                'Février',
                                'Mars',
                                'Avril',
                                'Mai',
                                'Juin',
                                'Juillet',
                                'Août',
                                'Septembre',
                                'Octobre',
                                'Novembre',
                                'Décembre',
                            ],
                            weekdays: [
                                'Dimanche',
                                'Lundi',
                                'Mardi',
                                'Mercredi',
                                'Jeudi',
                                'Vendredi',
                                'Samedi',
                            ],
                            weekdaysShort: [
                                'Dim',
                                'Lun',
                                'Mar',
                                'Mer',
                                'Jeu',
                                'Ven',
                                'Sam',
                            ],
                        },
                    }
                );
            });
    }

    private getTheme() {
        return {
            [CALENDAR_TYPE.DAY]: '',
            [CALENDAR_TYPE.WEEK]: '',
            [CALENDAR_TYPE.MONTH]: '',
            [CALENDAR_TYPE.YEAR]: '',
        };
    }

    private onSelect(date: Date) {
        this.calendar.destroy();
        this.dateSelect.emit(this.fromCalendarToEvent(date));
    }

    private fromCalendarToEvent = (date?: Date) => {
        const instanciedDate = moment(date);
        const range = this.dateProvider.onDateSelect(
            instanciedDate,
            this.dates?.select as string,
            this.dates?.type as string,
            true
        );

        return {
            current: {
                start: this.dateProvider.fromDateToStore(range.current.start),
                end: this.dateProvider.fromDateToStore(range.current.end, true),
            },
            versus: {
                start: this.dateProvider.fromDateToStore(range.versus.start),
                end: this.dateProvider.fromDateToStore(range.versus.end, true),
            },
        };
    };

    public goToToday() {
        this.calendar.goToday();
    }

    ngOnDestroy(): void {
        this.$storeSub?.unsubscribe();
    }
}
