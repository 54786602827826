import {
    Component,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnChanges,
    OnDestroy,
    Input,
} from '@angular/core';
import { Chart } from 'chart.js';
import dataLabelsPlugin, { Context } from 'chartjs-plugin-datalabels';
import { AgeData } from '@models/Counting/AgeGender';

@Component({
    selector: 'app-pie-chart-age',
    templateUrl: './pie-chart-age.component.html',
    styleUrls: ['./pie-chart-age.component.scss'],
})
export class PieChartAgeComponent
    implements AfterViewInit, OnChanges, OnDestroy
{
    @Input() ageData!: AgeData;

    @ViewChild('ageCanvas', { static: false })
    canvasAge!: ElementRef<HTMLCanvasElement>;
    private ageChart!: Chart;

    ngAfterViewInit() {
        this.register();
        this.initAgeChart();
    }

    ngOnChanges() {
        setTimeout(() => {
            this.ageChart.update();
        }, 50);
    }

    private register() {
        Chart.register(dataLabelsPlugin);
    }

    private initAgeChart() {
        this.ageChart = new Chart(this.canvasAge.nativeElement, {
            type: 'bar',
            data: {
                labels: ['Adultes/Enfants'],
                datasets: [
                    {
                        data: [this.ageData.adult],
                        // Adultes
                        backgroundColor: ['#008BAD'],
                        borderColor: ['#008BAD'],
                        borderWidth: 1,
                        datalabels: {
                            labels: {
                                value: {
                                    color: 'white',
                                },
                            },
                        },
                    },
                    {
                        data: [this.ageData.child],
                        //  Enfants
                        backgroundColor: ['#024F5D'],
                        borderColor: ['#024F5D'],
                        borderWidth: 1,
                        datalabels: {
                            labels: {
                                value: {
                                    color: 'white',
                                },
                            },
                        },
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                indexAxis: 'y',
                scales: {
                    x: { stacked: true, display: false },
                    y: { stacked: true, display: false },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    datalabels: {
                        font: {
                            weight: 'bold',
                            size: 17,
                            family: 'Helvetica',
                        },
                        // Permet de ne peut pas afficher les % en dehors du graphique
                        align:
                            // Si 0% < % d'adulte > 7% on aligne sur la droite
                            this.ageData.adult >= 0 && this.ageData.adult <= 7
                                ? 'end'
                                : // Si 0% < % d'enfants > 7% on aligne sur la gauche
                                this.ageData.child >= 0 &&
                                  this.ageData.child <= 7
                                ? 'start'
                                : // Sinon on centre le pourcentage %
                                  'center',

                        formatter: (value: any, ctx: Context) => {
                            return value + '%';
                        },
                    },
                },
            },
        });
    }

    ngOnDestroy() {
        this.ageChart.destroy();
    }
}
