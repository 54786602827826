import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RefreshProvider, StoreProvider } from '@app/providers';
import { InactivityProvider } from './providers/inactivity.provider';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'ivstore-mobile';

    constructor(
        private translate: TranslateService,
        private refreshProvider: RefreshProvider
    ) {
        translate.setDefaultLang('fr');
    }

    ngOnInit(): void {
        if ('serviceWorker' in navigator) {
            caches.keys().then(function (cacheNames) {
                cacheNames.forEach(function (cacheName) {
                    caches.delete(cacheName);
                });
            });
        }
        this.setSizeAndIgnoreResize();
    }

    private initPullToRefresh() {
        this.translate
            .get([
                'pullToRefresh.instructionsRefreshing',
                'pullToRefresh.instructionsPullToRefresh',
                'pullToRefresh.instructionsReleaseToRefresh',
            ])
            .subscribe((translation: any) => {
                this.refreshProvider.init(translation);
            });
    }

    private setSizeAndIgnoreResize() {
        var attribute = `height=${window.innerHeight}px,width=${window.innerWidth}px,initial-scale=1.0`;
        var meta = document.querySelector('meta[name=viewport]');
        (meta as HTMLMetaElement).setAttribute('content', attribute);
    }
}
