import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Jwt, UserData } from '@app/models';

import jwt_decode from 'jwt-decode';
import { StorageProvider, StoreProvider, UserProvider } from '@app/providers';
import { isTokenValid } from '@app/lib';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private router: Router,
        private http: HttpClient,
        private userProvider: UserProvider,
        private $store: StoreProvider,
        private storageProvider: StorageProvider
    ) {}

    public login(username: string, password: string): any {
        return this.http
            .post<string>(`${environment.backendDomain}/token`, {
                username,
                password,
            })
            .pipe(
                map((req: any) => {
                    if (req && req?.token) {
                        this.userProvider.updateUserdata({ isLogged: true });
                        this.$store.emit({
                            app: {
                                props: {
                                    user: {
                                        isLogged: true,
                                    },
                                },
                            },
                        });
                    }
                    return req;
                })
            );
    }

    public askPasswordChange(username: string): Observable<any> {
        return this.http.post<string>(
            `${environment.backendDomain}/PasswordForgottenType`,
            {
                email: username,
            }
        );
    }

    public passwordChange(username: string): Observable<any> {
        return this.http.post<string>(
            `${environment.backendDomain}/PasswordForgottenChangeType`,
            {
                username,
            }
        );
    }

    public logout(): void {
        this.$store.emit({
            app: {
                props: {
                    user: {
                        isLogged: false,
                    },
                },
            },
        });

        this.router.navigate(['/login']);
    }

    public isTokenValid() {
        return this.userProvider.getData().pipe(
            map((userData: UserData) => {
                if (userData.jwt) {
                    const JWT = jwt_decode<Jwt>(userData.jwt.toString());
                    const isValid = isTokenValid(JWT?.exp as number);
                    return isValid;
                }
                return false;
            })
        );
    }
}
