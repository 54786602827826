import { environment } from '@environments/environment';

import CryptoES from 'crypto-es';

// Encrypt
const encrypt = (value: string): string => {
    return CryptoES.AES.encrypt(value, environment.secret).toString();
};

// Decrypt
const decrypt = (value: string): string => {
    const bytes = CryptoES.AES.decrypt(value, environment.secret);
    return bytes.toString(CryptoES.enc.Utf8);
};

// check if jwt timestamp is expired or no
// True = expired , false = ok
const isTokenValid = (expireAt: number) => {
    return typeof expireAt !== 'undefined' &&
        expireAt < Date.now().valueOf() / 1000
        ? true
        : false;
};

export { encrypt, decrypt, isTokenValid };
