<div class="col-12 center h-100">
    <div
        class="col-12 center-bg"
        [ngClass]="{ 'positive-data': !showRedBg, 'negative-data': showRedBg }"
    >
        <div class="h-100 center">
            <div class="bg-content-data center-bg center">
                <div
                    class="content d-flex flex-column align-items-center justify-content-around"
                >
                    <img
                        src="{{ icon }}"
                        height="40px"
                        style="transform: translateX(6px)"
                    />
                    <div class="d-flex flex-column align-items-center">
                        <div class="ref-data-main">
                            {{ current | numberSuffix }}
                        </div>
                        <div class="comp-data-main">
                            vs : {{ versus | numberSuffix }}
                        </div>
                        <div
                            [ngClass]="{
                                'variation-main-positive': !showRedBg,
                                'variation-main-negative': showRedBg
                            }"
                            class="center"
                        >
                            <span *ngIf="showRedBg" class="center"
                                ><img
                                    src="/assets/svg/arrow/arrow-negative.svg"
                                    width="20px"
                            /></span>
                            <span *ngIf="!showRedBg" class="center"
                                ><img
                                    src="/assets/svg/arrow/arrow-positive.svg"
                                    width="20px"
                            /></span>
                            <span>{{ variation | numberSuffix: 1 }}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
