import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Chart, ChartItem } from 'chart.js';
import annotationPlugin, {
    AnnotationOptions,
    AnnotationTypeRegistry,
} from 'chartjs-plugin-annotation';

import dataLabelsPlugin, { Context } from 'chartjs-plugin-datalabels';

//@ts-ignore
import SVGPassage from '!!raw-loader?!@app/../assets/svg/static-passage-light_scatter.svg';
//@ts-ignore
import SVGStopsByPassages from '!!raw-loader?!@app/../assets/svg/stops-by-passages.svg';
import { MathProvider } from '@app/providers';

@Component({
    selector: 'app-data-scatter',
    templateUrl: './data-scatter.component.html',
    styleUrls: ['./data-scatter.component.scss'],
})
export class DataScatterComponent
    implements AfterViewInit, OnDestroy, OnChanges
{
    @ViewChild('scatterCanvas', { static: false })
    canvasHRef!: ElementRef<HTMLCanvasElement>;

    @Input() data: any;
    @Input() visibleRows: any[] = [];

    private chart!: Chart;

    public emptyState = false;

    private colors = {
        red: {
            visible: '#AC1A4A',
            hidden: 'rgba(173, 26, 75, 0.3)',
        },
        orange: {
            visible: '#ffbe00',
            hidden: 'rgba(255, 190, 0, 0.3)',
        },
        green: {
            visible: '#34A76E',
            hidden: 'rgba(52, 167, 110, 0.3)',
        },
    };

    private xAxis: AnnotationOptions<keyof AnnotationTypeRegistry> = {
        type: 'line',
        borderColor: '#FFFFFF',
        borderWidth: 2,
        label: {
            content: this.svgToImgElement(SVGPassage),
            color: '#FFFFFF',
            enabled: true,
            drawTime: 'afterDatasetsDraw',
            position: 'end',
            width: 25,
            height: 25,
            backgroundColor: '#FFFFFF00',
        },
        scaleID: 'y',
        value: -5,
        arrowHeads: {
            backgroundColor: '#FFFFFF',
            enabled: true,
            start: { enabled: false },
            end: { enabled: true },
        },
    };

    private yAxis: AnnotationOptions<keyof AnnotationTypeRegistry> = {
        type: 'line',
        borderColor: '#FFFFFF',
        borderWidth: 2,
        label: {
            content: this.svgToImgElement(SVGStopsByPassages),
            color: '#FFFFFF',
            enabled: true,
            drawTime: 'afterDatasetsDraw',
            position: 'start',
            width: 25,
            height: 25,
            backgroundColor: '#FFFFFF00',
        },
        scaleID: 'x',
        value: -5,
        arrowHeads: {
            backgroundColor: '#FFFFFF',
            enabled: true,
            start: { enabled: true },
            end: { enabled: false },
        },
    };

    constructor(private mathProvider: MathProvider) {}

    ngAfterViewInit() {
        this.register();
        this.initChart();
    }

    ngOnChanges(): void {
        setTimeout(() => {
            this.chart.update();
        }, 50);
    }

    private register() {
        Chart.register(annotationPlugin);
        Chart.register(dataLabelsPlugin);
    }

    private svgToImgElement(svg: string): HTMLImageElement {
        const svgWithoutFirstBl = svg.substring(4, svg.length);
        // Create a non-visible node to render the SVG string
        const SVGContainer = document.createElement('div');
        SVGContainer.style.display = 'none';
        SVGContainer.innerHTML = ''.concat(
            '<svg width="200" height="200" ',
            svgWithoutFirstBl
        );

        const svgNode = SVGContainer.firstElementChild;
        const svgXml = new XMLSerializer().serializeToString(svgNode as Node);
        const svgBase64 = 'data:image/svg+xml;base64,' + btoa(svgXml);

        const img = new Image();
        img.src = svgBase64;
        return img;
    }

    private pluginsColorLogic(ctx: any) {
        const row: any = ctx.dataset.data[ctx.dataIndex];

        const showRedBg =
            row.isPassageVariationNegative &&
            row.isStopByPassageVariationNegative;

        const colorOpacity = this.visibleRows.find(
            (val) => val.rank === row.rank
        )?.isVisible
            ? 'visible'
            : 'hidden';

        if (showRedBg) return this.colors.red[colorOpacity as never];
        if (
            row.isPassageVariationNegative ||
            row.isStopByPassageVariationNegative
        ) {
            return this.colors.orange[colorOpacity as never];
        }
        return this.colors.green[colorOpacity as never];
    }

    private defaultColorLogic(ctx: any) {
        if (ctx.type === 'data') {
            const row: any = ctx.raw;
            const showRedBg =
                row.isPassageVariationNegative &&
                row.isStopByPassageVariationNegative;
            const colorOpacity =
                this.visibleRows.find((val) => val.rank === row.rank)
                    ?.isVisible === true
                    ? 'visible'
                    : 'hidden';

            if (showRedBg) return this.colors.red[colorOpacity as never];
            if (
                row.isPassageVariationNegative ||
                row.isStopByPassageVariationNegative
            ) {
                return this.colors.orange[colorOpacity as never];
            }
            return this.colors.green[colorOpacity as never];
        }
        return this.colors.green.visible;
    }

    private initChart() {
        var maxXValue = this.mathProvider.getPercentOf(
            5,
            Math.max(...this.data.map((val: any) => val.x))
        );
        var maxYValue = this.mathProvider.getPercentOf(
            5,
            Math.max(...this.data.map((val: any) => val.y))
        );

        this.chart = new Chart(this.canvasHRef.nativeElement, {
            data: {
                datasets: [
                    {
                        // do something else, just cause value are fixed to axis
                        data: this.data.map((val: any) => {
                            return {
                                ...val,
                                x: val.x + maxXValue,
                                y: val.y + maxYValue,
                                rank: val.rank,
                                isPassageVariationNegative:
                                    val.isPassageVariationNegative,
                                isStopByPassageVariationNegative:
                                    val.isStopByPassageVariationNegative,
                            };
                        }),
                        borderColor: (ctx: any) => {
                            return this.defaultColorLogic(ctx);
                        },
                        backgroundColor: (ctx: any) => {
                            return this.defaultColorLogic(ctx);
                        },
                    },
                ],
            },
            type: 'scatter',
            options: {
                indexAxis: 'x',
                responsive: false,
                scales: {
                    y: { min: -5, beginAtZero: false, display: false },
                    x: { min: -5, beginAtZero: false, display: false },
                },
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    annotation: {
                        clip: false,
                        drawTime: 'afterDatasetsDraw',
                        annotations: { xAxis: this.xAxis, yAxis: this.yAxis },
                    },
                    datalabels: {
                        align: function (context) {
                            return 'left'; //change position of data
                        },
                        clamp: true,
                        display: 'auto',
                        color: (ctx: any) => {
                            return this.pluginsColorLogic(ctx);
                        },
                        font: {
                            size: 20,
                        },
                        formatter: (value: any, ctx: Context) => {
                            return value.rank;
                        },
                    },
                },
            },
        });
    }

    ngOnDestroy(): void {
        this.chart.destroy();
    }
}
