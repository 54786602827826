<table
    id="journey-table"
    data-sticky-header="true"
    class="table-bordered shadow-primary"
>
    <thead class="radius-primary border-0">
        <tr class="d-flex border-0 fixed">
            <th class="smallSize border-0"></th>
            <th class="largeSize"><div class="zoneName">Zones</div></th>
            <th>
                <div style="height: 1.1rem" class="center">
                    <img
                        src="/assets/svg/static-passage-light.svg"
                        height="100%"
                    />
                </div>
            </th>
            <th>
                <div style="height: 1.1rem" class="center">
                    <img
                        src="/assets/svg/stops-by-passages.svg"
                        height="100%"
                    />
                </div>
            </th>
            <th>
                <div style="height: 1.1rem" class="center">
                    <img
                        src="/assets/svg/static-stops-light.svg"
                        height="100%"
                    />
                </div>
            </th>
            <th>
                <div style="height: 1.1rem" class="center">
                    <img src="/assets/svg/foot-zone.svg" height="100%" />
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let journeyData of tableData; index as i"
            [id]="'journey_table_row_' + i"
            class="d-flex"
        >
            <th class="smallSize border-0">{{ journeyData.rank }}</th>

            <td
                class="largeSize align-items-start"
                (click)="openModal(journeyData)"
            >
                {{ journeyData.zoneName }}
            </td>
            <td
                class="neutralContent"
                [ngClass]="{
                    positiveContent: journeyData.passageVariation > 0,
                    negativeContent: journeyData.passageVariation < 0
                }"
            >
                <p>
                    {{
                        journeyData.currentZonePassage
                            | roundedWithRules : 0
                            | numberSuffix
                    }}
                </p>
                <p class="variation-journey-table darkOpacity">
                    {{ journeyData.passageVariation > 0 ? '+' : ''
                    }}{{
                        journeyData.passageVariation
                            | roundedWithRules : 0
                            | numberSuffix : 0 : { limit: 1000 }
                    }}
                    %
                </p>
            </td>
            <td
                class="neutralContent"
                [ngClass]="{
                    positiveContent: journeyData.stopByPassageVariation > 0,
                    negativeContent: journeyData.stopByPassageVariation < 0
                }"
            >
                <p>
                    {{
                        journeyData.currentStopByPassage
                            | roundedWithRules : 0
                            | numberSuffix
                    }}
                    %
                </p>
                <p class="variation-journey-table darkOpacity">
                    {{ journeyData.stopByPassageVariation > 0 ? '+' : ''
                    }}{{
                        journeyData.stopByPassageVariation
                            | roundedWithRules : 0
                            | numberSuffix : 0 : { limit: 1000 }
                    }}Pt
                </p>
            </td>
            <td>
                <p>
                    {{
                        journeyData.currentStopCount
                            | roundedWithRules : 0
                            | numberSuffix
                    }}
                </p>
                <p
                    class="variation-journey-table darkOpacity"
                    [ngClass]="{
                        green: journeyData.stopVariation > 0,
                        red: journeyData.stopVariation < 0
                    }"
                >
                    {{ journeyData.stopVariation > 0 ? '+' : ''
                    }}{{
                        journeyData.stopVariation
                            | roundedWithRules : 0
                            | numberSuffix : 0 : { limit: 1000 }
                    }}
                    %
                </p>
            </td>
            <td>
                <p>
                    {{
                        journeyData.currentAttractivity
                            | roundedWithRules : 0
                            | numberSuffix
                    }}
                    %
                </p>
                <p
                    class="variation-journey-table darkOpacity"
                    [ngClass]="{
                        green: journeyData.attractivityVariation > 0,
                        red: journeyData.attractivityVariation < 0
                    }"
                >
                    {{ journeyData.attractivityVariation > 0 ? '+' : ''
                    }}{{
                        journeyData.attractivityVariation
                            | roundedWithRules : 0
                            | numberSuffix : 0 : { limit: 1000 }
                    }}Pt
                </p>
            </td>
        </tr>
    </tbody>
</table>
