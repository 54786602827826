import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-input-groups',
    templateUrl: './input-groups.component.html',
    styleUrls: ['./input-groups.component.scss'],
})
export class InputGroupsComponent {
    @Input() label: string | undefined;
    @Input() value: string | undefined = '';
    @Input() _index: number | any = 0;
    @Input() showIsValidLabel: boolean = true;
    @Input() showIsInValidLabel: boolean = true;
    @Input() isValid: boolean = false;
    @Input() isInvalid: boolean = false;
    @Input() isValidFeedBack: string = '';
    @Input() isInvalidFeedBack: string = '';
    @Input() type: string = 'text';
    @Input() classCustom: string = '';
    @Input() icon: string = '';

    @Input() placeHolder = '';

    @Output() inputChange = new EventEmitter();

    public getValue(event: Event): string {
        return (event.target as HTMLInputElement).value;
    }

    public emit(value: any): void {
        this.inputChange.emit(value);
    }
}
