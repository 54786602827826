<div class="col-12 p-0 m-auto pb-5 h-100" style="overflow-y: hidden">
    <div
        class="position-absolute _close_modal"
        (click)="close()"
        style="overflow: hidden"
    >
        <img
            src="/assets/svg/close-dark.svg"
            alt="close modal"
            height="100%"
            width="100%"
        />
    </div>

    <app-localbusinesses-list
        *ngIf="$app | async; let app"
        [localBusinesses]="app.props?.localBusinesses"
        [localBusinessesFavorite]="app.props?.favorite"
        [localbusinessSelected]="app.props?.selectedLocalBusiness"
        [localBusinesses]="app.props?.localBusinesses"
        (itemIsSelected)="selectLocalBusiness($event)"
    ></app-localbusinesses-list>
</div>
