import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import moment, { Moment } from 'moment';

@Injectable({ providedIn: 'root' })
export class BackgroundService {
    constructor(private http: HttpClient) {}

    public getBackgroundDyDate(
        boxId: number,
        dateAsMoment: Moment
    ): Observable<any> {
        return this.http.get<any>(
            `${
                environment.backendDomain
            }/boxes/${boxId}/heatmaps/static/${dateAsMoment.year()}/${dateAsMoment.month()}/${dateAsMoment.date()}/content`,
            //@ts-ignore
            { responseType: 'blob' }
        );
    }

    public getLastBackground(boxId: any, timestamp: number | undefined) {
        return this.http.get<any>(
            `${environment.backendDomain}/boxes/${boxId}/backgrounds/lastmidday?_format=json&data=1&end=${timestamp}`
        );
    }
}
