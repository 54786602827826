import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { StoreProvider } from '@app/providers';
import { UserServices } from '@app/services';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    template: '',
})
export class AbstractResetComponent implements OnDestroy {
    public $usernameOrEmail: BehaviorSubject<string> = new BehaviorSubject('');
    public $isError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    public $error: BehaviorSubject<string> = new BehaviorSubject('');

    public $showWaitingToken: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    public autoBack = true;

    private $token: BehaviorSubject<string> = new BehaviorSubject('');
    private $newPassword: BehaviorSubject<string> = new BehaviorSubject('');
    private $userId: BehaviorSubject<number> = new BehaviorSubject(0);

    private $resetStep: BehaviorSubject<number> = new BehaviorSubject(0);

    public resetStatus = 0;
    public intervalId!: any;

    public isWaiting = true;
    public isSuccess = false;

    constructor(
        public store: StoreProvider,
        public router: Router,
        private userService: UserServices
    ) {}

    public checkToken() {
        return this.userService.postNewPasswordToken(
            this.$userId.value,
            this.$token.value
        );
    }

    public checkStatus(username: string, confirmToken: string) {
        return this.userService.getStatusForResetPassword(
            username,
            confirmToken
        );
    }

    public askNewPassword(): Observable<any> {
        return this.userService.postAskNewPassword(this.$usernameOrEmail.value);
    }

    public updatePassword(
        newPassword: string,
        userId: string,
        confirmToken: string
    ) {
        return this.userService.postResetPassword(
            userId,
            newPassword,
            confirmToken
        );
    }

    public goLogin() {
        this.router.navigate(['/login']);
        this.store.emit({
            'reset-steps': { props: { steps: 0 } },
        });
    }

    public backStep() {
        this.$resetStep.next(this.$resetStep.value - 1);
        this.store.emit({
            'reset-steps': { props: { step: this.$resetStep.value } },
        });
    }

    public setStep(value: number) {
        this.$resetStep.next(value);
    }

    public quit() {
        this.autoBack = false;
        setTimeout(() => {
            this.autoBack = true;
        }, 6000);
        this.router.navigate(['login']).then(() => {
            this.$usernameOrEmail.next('');
            this.$isError.next(false);
            this.$error.next('');
            this.$showWaitingToken.next(true);
            this.$token.next('');
            this.$newPassword.next('');
            this.$userId.next(0);
            this.$resetStep.next(0);
            this.store.emit({
                'reset-steps': { props: { step: 0, token: null, id: null } },
            });
        });
    }

    public nextStep() {
        this.$resetStep.next(this.$resetStep.value + 1);
        this.store.emit({
            'reset-steps': {
                props: {
                    step: this.$resetStep.value,
                    username: this.$usernameOrEmail.value,
                    userId: this.$userId.value,
                    token: this.$token.value,
                },
            },
        });
    }

    public setNewPassword(password: string) {
        this.$newPassword.next(password);
    }

    public setToken(token: string) {
        this.$token.next(token);
    }

    public setUserID(id: number) {
        this.$userId.next(id);
    }

    public setError(error: string) {
        this.$isError.next(true);
        this.$error.next(error);
    }

    public removeError() {
        this.$isError.next(false);
        this.$error.next('');
    }

    // Todo rework
    public checkResetStatus(value: any) {
        this.intervalId = setInterval(
            (value: any) => {
                this.checkStatus(
                    value.props.username,
                    value.props.token
                ).subscribe(
                    (value: any) => {
                        if (value.resetStatus === 2) {
                            this.resetStatus = value.resetStatus;
                            this.router.navigate(['/login']);
                            clearInterval(this.intervalId);
                        }
                        if (value.resetStatus === 3) {
                            this.resetStatus = value.resetStatus;
                            clearInterval(this.intervalId);
                        }
                    },
                    () => {
                        clearInterval(this.intervalId);
                        this.isWaiting = false;
                        this.isSuccess = false;
                        this.setError('confirm reset : bad token');
                    }
                );
                this.resetStatus = 1;
            },
            2000,
            value
        );
    }

    ngOnDestroy() {
        this.removeError();
    }
}
