import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-settings-list',
    templateUrl: './settings-list.component.html',
    styleUrls: ['./settings-list.component.scss'],
})
export class SettingsListComponent {
    @Input() list!: any[];

    @Output() selectSetting = new EventEmitter();
}
