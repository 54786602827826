<div class="container-fluid p-0 w-100 h-100" *ngIf="subKey.length > 0">
    <div
        class="row m-0 p-0 h-100"
        *ngIf="translateService.get(subKey) | async; let translations"
    >
        <div class="col-12 m-0 p-0">
            <div [innerHTML]="translations.title | translate"></div>
        </div>
        <div class="col-12 p-0 m-0 h-100">
            <div
                *ngFor="let translation of translations.autoGenerate | keyvalue"
                [innerHTML]="translation.value"
            ></div>
        </div>
    </div>
</div>
