<div class="col-12 p-0 m-0 h-100">
    <div class="row p-0 m-0 w-100">
        <div class="col-12 center p-0 m-0 _first_chart_container_ h-100">
            <div
                class="_second_chart_container_ position-relative center w-100 h-100 d-flex"
            >
                <canvas
                    #scatterCanvas
                    id="scatter_canvas"
                    class="_chart_data_compare_x_axis_counting w-100 h-100"
                ></canvas>
            </div>
        </div>
    </div>
</div>
