import { Component } from '@angular/core';

@Component({
  selector: 'app-select-period',
  templateUrl: './select-period.component.html',
  styleUrls: ['./select-period.component.scss']
})
export class SelectPeriodComponent {

  constructor() { }

}
