import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CALENDAR_SELECT } from '../models/Calendar';
import {
    RangeCustom,
    RangeBegin,
    PrincipalData,
    GroupsRepartitionData,
    AgeGenderData,
    VisitsRepartitionData,
    TimePassedInStoreData,
} from '@app/models/';

@Injectable({ providedIn: 'root' })
export class CountingService {
    constructor(private http: HttpClient) {}

    public getPrincipalDataCustom(businessId: number,
        format: CALENDAR_SELECT,
        range: RangeCustom) {
        return this.http.get<PrincipalData>(
            `${environment.backendDomain}/appmobile/counting/localBusiness/${businessId}/principalData?format=custom&range=${format}&begin=${range.begin}&end=${range.end}&beginVs=${range.beginVs}&endVs=${range.endVs}`
        );
    }

    public getVisitsRepartitionDataCustom(
        businessId: number,
        range: RangeCustom
    ) {
        return this.http.get<PrincipalData>(
            `${environment.backendDomain}/appmobile/counting/localBusiness/${businessId}/visitsRepartitionData?format=custom&begin=${range.begin}&end=${range.end}&beginVs=${range.beginVs}&endVs=${range.endVs}`
        );
    }

    public getGroupsRepartitionDataCustom(
        businessId: number,
        range: RangeCustom
    ) {
        return this.http.get<PrincipalData>(
            `${environment.backendDomain}/appmobile/counting/localBusiness/${businessId}/groupsRepartitionData?format=custom&begin=${range.begin}&end=${range.end}&beginVs=${range.beginVs}&endVs=${range.endVs}`
        );
    }

    public getPrincipalData(
        businessId: number,
        format: CALENDAR_SELECT,
        range: RangeBegin
    ) {
        return this.http.get<PrincipalData>(
            `${environment.backendDomain}/appmobile/counting/localBusiness/${businessId}/principalData?format=${format}&begin=${range.begin}&beginVs=${range.beginVs}`
        );
    }

    public getVisitsRepartitionData(
        businessId: number,
        format: CALENDAR_SELECT,
        range: RangeBegin
    ) {
        return this.http.get<VisitsRepartitionData>(
            `${environment.backendDomain}/appmobile/counting/localBusiness/${businessId}/visitsRepartitionData?format=${format}&begin=${range.begin}&beginVs=${range.beginVs}`
        );
    }

    public getAgeGenderData(
        businessId: number,
        format: CALENDAR_SELECT,
        range: RangeBegin
    ) {
        return this.http.get<AgeGenderData>(
            `${environment.backendDomain}/appmobile/counting/localBusiness/${businessId}/ageGenderData?format=${format}&begin=${range.begin}&beginVs=${range.beginVs}`
        );
    }

    public getGroupsRepartitionData(
        businessId: number,
        format: CALENDAR_SELECT,
        range: RangeBegin
    ) {
        return this.http.get<GroupsRepartitionData>(
            `${environment.backendDomain}/appmobile/counting/localBusiness/${businessId}/groupsRepartitionData?format=${format}&begin=${range.begin}&beginVs=${range.beginVs}`
        );
    }

    public getTimePassedInStoreData(
        businessId: number,
        format: CALENDAR_SELECT,
        range: RangeBegin
    ) {
        return this.http.get<TimePassedInStoreData>(
            `${environment.backendDomain}/appmobile/counting/localBusiness/${businessId}/timePassedInStoreData?format=${format}&begin=${range.begin}&beginVs=${range.beginVs}`
        );
    }
}
