import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionBarStore, AppStore } from '@app/models';
import { StoreProvider } from '@app/providers';
import { MainService } from '@app/services/main.service';
import { Subscription, tap } from 'rxjs';

@Component({
    template: '',
})
export class AbstractThemeComponent implements OnInit, OnDestroy {
    public route = '';
    public defaultBackground: any = {
        counting: 'positive_radiant',
        setting: 'positive_radiant',
        journey: 'theme_dark',
    };

    public globalTheme: any = {
        counting: 'light_theme',
        login: 'light_theme',
        setting: 'light_theme',
        journey: 'dark_theme',
    };

    public app!: AppStore;
    private $appSubscription?: Subscription;

    public actionBar!: ActionBarStore;
    private $actionBarSubscription?: Subscription;

    public $mainData: any = this.mainService
        .fetchAllMainData(this.$store.$.value.app)
        .pipe(
            tap((mainData) => this.$store.emit({ app: { props: mainData } }))
        );

    public redBg!: boolean;
    public hideBg!: boolean;

    public redBgSub$!: Subscription;

    constructor(
        public router: Router,
        public $store: StoreProvider,
        public mainService: MainService
    ) {}

    ngOnInit(): void {
        this.$appSubscription = this.$store
            .listenActionBar()
            .subscribe((actionBarStore) => {
                this.actionBar = actionBarStore;
            });
        this.route = this.router.url.replace('/', '');

        this.$appSubscription = this.$store.listen('app').subscribe((app) => {
            this.app = app;
        });

        if (this.globalTheme[this.route] === 'light_theme') {
            this.lightThemeLogic();
        }

        if (this.globalTheme[this.route] === 'dark_theme') {
            this.darkThemeLogic();
        }
    }

    public lightThemeLogic() {
        this.redBgSub$ = this.$store.listenRedBg().subscribe((redBg: any) => {
            if (this.redBg !== redBg) {
                this.hideBg = true;
                this.redBg = redBg;

                setTimeout(() => {
                    this.hideBg = false;
                }, 250);
            }
        });
    }

    public darkThemeLogic() {
        this.redBg = true;
        this.hideBg = true;
    }

    ngOnDestroy(): void {
        this?.$appSubscription?.unsubscribe();
        this?.$actionBarSubscription?.unsubscribe();
    }
}
