import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@app/services/auth.service';
import { RegularExpressionConstant } from '@app/const';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.error instanceof ErrorEvent) {
                        console.error(`error event : ${err.name}`);
                    } else {
                        switch (err.status) {
                            case 401: //login
                                this.authenticationService.logout();
                                break;
                            case 403: //forbidden
                                this.authenticationService.logout();
                                break;
                            case RegularExpressionConstant.SERVER_INTERNAL_ERROR.test(
                                err.status as any
                            )
                                ? err.status
                                : null:
                                break;
                        }
                    }
                } else {
                    console.error('some thing else happened');
                }
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                }
                return throwError(err);
            })
        );
    }
}
