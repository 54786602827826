import { AppStore, NavDetailTabs } from '@app/models';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { StoreProvider, SwiperProvider } from '@app/providers';
import { Subscription } from 'rxjs';
import Swiper from 'swiper';

@Component({
    selector: 'app-detail-nav',
    templateUrl: './detail-nav.component.html',
    styleUrls: ['./detail-nav.component.scss'],
})
export class DetailNavComponent implements AfterViewInit, OnDestroy {
    public app!: AppStore;
    @Input() items?: NavDetailTabs[];
    @Input() active?: NavDetailTabs;
    private $appSubscription!: Subscription;
    private swiper: any = null;

    @ViewChild('swiper') swiperRef!: ElementRef;

    // Cause swiper is bugged.
    private realIndex = 0;
    private isCreated = false;

    constructor(
        private swiperProvider: SwiperProvider,
        public $store: StoreProvider
    ) {}

    ngAfterViewInit(): void {
        this.$appSubscription = this.$store
            .listen('app')
            .subscribe((app: AppStore) => {
                this.app = app;
                this.realIndex = Math.trunc(
                    app!.props!.detailNav!.items!.length / 2
                );

                if (
                    app!.props!.detailNav!.items!.length > 0 &&
                    !this.isCreated
                ) {
                    this.isCreated = true;
                    this.createSwiper();
                    setTimeout(() => {
                        this.resetSwipper();
                        this.createSwiper();
                        this.listenSwiper();
                    });
                }
            });
    }

    private createSwiper() {
        this.swiper = this.swiperProvider.create(this.swiperRef.nativeElement, {
            direction: 'horizontal',
            pagination: { clickable: true },
            navigation: true,
            slidesPerView: 5,
            spaceBetween: 25,
            normalizeSlideIndex: true,
            loop: true,
            centeredSlides: true,
            allowSlideNext: true,
            allowSlidePrev: true,
            allowTouchMove: true,
            slideToClickedSlide: true,
            initialSlide: this.realIndex,
        });
    }

    private listenSwiper() {
        this.swiperRef.nativeElement.swiper.on(
            'slideChange',
            this.swipperClick.bind(this)
        );
    }

    private swipperLogic(direction: 'prev' | 'next') {
        if (direction === 'prev') {
            this.realIndex = this.realIndex + 1;
        }
        if (direction === 'next') {
            this.realIndex = this.realIndex + 1;
        }
    }

    private sliderLogic(direction: 'prev' | 'next', amount: number) {
        const numberOfTurns =
            Math.floor(amount / this.app!.props!.detailNav!.items!.length) - 1;

        const sign = direction === 'next' ? 1 : -1;

        if (numberOfTurns === 0) {
            this.realIndex = this.realIndex + amount;
        } else {
            const numberOfIndexToSlides =
                (amount % this.app!.props!.detailNav!.items!.length) * sign;

            this.realIndex = this.realIndex + numberOfIndexToSlides;
        }
    }

    private swipperClick(ctx: Swiper) {
        // if ((ctx as any).swipeDirection) {
        //     const numberOfSlidesSwipped = Math.abs(
        //         ctx.activeIndex - ctx.previousIndex
        //     );

        //     this.sliderLogic(
        //         (ctx as any).swipeDirection,
        //         numberOfSlidesSwipped
        //     );
        // }

        const direction = ctx.realIndex > ctx.previousIndex ? 'next' : 'prev';
        if (
            Math.abs(this.realIndex) >=
            Math.trunc(this.app!.props!.detailNav!.items!.length / 2) + 1
        ) {
            this.realIndex = 0;
        } else {
            this.swipperLogic(direction);
        }
        if (this.items) {
            this.$store.emit({
                app: {
                    props: {
                        detailNav: {
                            active: this.items[ctx.realIndex],
                        },
                    },
                },
            });
        }
    }

    private resetSwipper() {
        if (this.swiper !== null) {
            this.swiper.destroy();
            this.swiper = null;
        }
    }

    ngOnDestroy(): void {
        this?.$appSubscription.unsubscribe();
        this.realIndex = Math.trunc(
            this.app!.props!.detailNav!.items!.length / 2
        );
        if (this.items) {
            this.$store.emit({
                app: {
                    props: {
                        detailNav: {
                            active: this.items[this.realIndex],
                        },
                    },
                },
            });
        }
    }
}
