<canvas #genderCanvas id="gender_canvas"></canvas>

<img
    src="/assets/svg/age-gender/gender/women.svg"
    alt="Pictogramme Femme"
    class="position-absolute start-0"
    style="bottom: 12%; width: 15px"
/>

<img
    src="/assets/svg/age-gender/gender/man.svg"
    alt="Pictogramme Homme"
    class="position-absolute end-0 mr-5"
    style="bottom: 12%; width: 15px"
/>

<img
    src="/assets/svg/age-gender/gender/gender-picto.svg"
    alt="Logo Homme-Femme"
    class="position-absolute start-50 translate-middle"
    style="bottom: 5%; width: 25px"
/>
