import { Component } from '@angular/core';
import { AppStore } from '@app/models';
import { CustomDateObject } from '@app/models/Calendar';
import { DateProvider, StoreProvider } from '@app/providers';
import { Moment } from 'moment';
import {
    animate,
    animation,
    style,
    transition,
    trigger,
    useAnimation,
} from '@angular/animations';
import { Router } from '@angular/router';
import { AbstractThemeComponent } from './../theme-layout';
import { MainService } from '@app/services/main.service';

export const fadeIn = animation([
    style({ opacity: 0 }), // start state
    animate('250ms', style({ opacity: 1 })),
]);

export const fadeOut = animation([animate('250ms', style({ opacity: 0 }))]);

@Component({
    selector: 'app-layout-data',
    templateUrl: './layout-data.component.html',
    styleUrls: ['./layout-data.component.scss'],
    animations: [
        trigger('backgroundIsChange', [
            transition('void => *', [useAnimation(fadeIn)]),
            transition('* => void', [useAnimation(fadeOut)]),
        ]),
    ],
})
export class LayoutDataComponent extends AbstractThemeComponent {
    constructor(
        private dateProvider: DateProvider,
        router: Router,
        $store: StoreProvider,
        mainService: MainService
    ) {
        super(router, $store, mainService);
    }

    public onDateChange(dates: CustomDateObject) {
        var rangeOfDates: any = {};

        if (dates.type) {
            this.app.props!.dates!.type = dates.type;

            rangeOfDates = this.dateProvider.onTypeSelect(
                this.app.props?.dates as CustomDateObject
            );
        }

        if (dates.select) {
            this.app.props!.dates!.select = dates.select;

            this.app.props!.dates!.type = dates.select as any;

            rangeOfDates = this.dateProvider.onDateSelect(
                this.app.props?.dates?.current?.start.asMoment as Moment,
                dates.select as string,
                this.app.props?.dates?.type as string,
                true
            );
            dates.type = dates.select as any;
        }

        this.$store.emitReloadAll(true);

        this.$store.emit({
            app: {
                props: {
                    dates: {
                        ...this.app.props?.dates,
                        ...dates,
                        current: {
                            start: this.dateProvider.fromDateToStore(
                                rangeOfDates.current.start
                            ),
                            end: this.dateProvider.fromDateToStore(
                                rangeOfDates.current.end,
                                true
                            ),
                        },
                        versus: {
                            start: this.dateProvider.fromDateToStore(
                                rangeOfDates.versus.start
                            ),
                            end: this.dateProvider.fromDateToStore(
                                rangeOfDates.versus.end,
                                true
                            ),
                        },
                    },
                },
            },
        } as AppStore);
    }
}
