import { Component, Input, OnInit } from '@angular/core';
import { StoreProvider } from '@app/providers';

@Component({
    selector: 'app-counting-circle',
    templateUrl: './counting-circle.component.html',
    styleUrls: ['./counting-circle.component.scss'],
})
export class CountingCircleComponent implements OnInit {
    @Input() current!: any;
    @Input() versus!: any;
    @Input() variation!: any;
    @Input() icon!: any;

    constructor(private $store: StoreProvider) {}

    ngOnInit() {
        this.adaptBg();
    }

    public adaptBg() {
        const isNegative = (this.variation + '').includes('-');
        this.$store.emitRedBg(isNegative);
    }
}
