<div class="container-fluid p-0 w-100 h-100">
    <div
        class="row m-0 p-0 h-100"
        *ngIf="translateService.get('about') | async; let translations"
    >
        <div class="col-12 m-0 p-0 pb-2">
            <div [innerHTML]="translations.title | translate"></div>
        </div>
        <div class="col-12 p-0 m-0 h-100">
            <div class="fw-bold">Version</div>
            <div>{{ version.version }}</div>
            <div
                *ngFor="let translation of translations.autoGenerate | keyvalue"
                [innerHTML]="translation.value + '' | translate: { version: 3 }"
            ></div>
        </div>
    </div>
</div>
