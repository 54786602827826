<div class="container-fluid p-0 h-100 w-100">
    <div class="row h-100 p-0 m-0">
        <div class="col-12 p-0 m-0">
            <app-settings-list
                [list]="settingsList"
                (selectSetting)="onItemSelect($event, _InternSettingsRouter)"
            ></app-settings-list>
        </div>
        <div
            class="col-12 p-0 m-0 d-flex align-items-start justify-content-end flex-column"
        >
            <button
                class="bottom-0 btn btn-light p-0 m-0 text-decoration-underline red"
                (click)="logout()"
            >
                {{ 'logout' | translate }}
            </button>
        </div>
    </div>
</div>
