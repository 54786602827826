export const NavDetailTabsCounting = [
    {
        name: 'groups',
        path: 'groups',
        icon: 'repartition-group',
    },
    {
        name: 'repartition',
        path: 'repartition',
        icon: 'time',
    },
    {
        name: 'main',
        path: 'main',
        icon: 'home-dark',
    },
    {
        name: 'customers-counting',
        path: 'customers-counting',
        icon: 'repartition',
    },
    {
        name: 'age-gender-recognition',
        path: 'age-gender-recognition',
        icon: 'age-gender-recognition',
    },
];

export const NavDetailTabsJourney = [
    {
        name: 'animated-passage-heatmap',
        path: 'animated-passage-heatmap',
        icon: 'animated-passage-heatmap-light',
        apiFn: 'getPassageHeatmap',
        type: 'video',
    },
    {
        name: 'animated-stops-heatmap',
        path: 'animated-stops-heatmap',
        icon: 'animated-stops-heatmap-light',
        apiFn: 'getAnimatedStopHeatmap',
        type: 'video',
    },
    {
        name: 'passage-animation',
        path: 'passage-animation',
        icon: 'passage-animation-light',
        apiFn: 'getTrajectories',
        type: 'video',
    },
    {
        name: 'dominant-orientations-heatmap',
        path: 'dominant-orientations-heatmap',
        icon: 'dominant-orientations-heatmap-light',
        apiFn: 'getDominantOrientationsHeatmap',
        type: 'image',
    },
    {
        name: 'zone-to-zone',
        path: 'zone-to-zone',
        icon: 'zone-to-zone-light',
        apiFn: 'getZoneToZone',
        type: 'image_open_cv',
        fabricFn: 'zoneToZone',
    },
    {
        name: 'main',
        path: 'main',
        icon: 'home-light',
        apiFn: '',
        type: '',
    },
    {
        name: 'heatmap-static',
        path: 'heatmap-static',
        icon: 'heatmap-static-light',
        apiFn: 'getHeatmapStatic',
        type: 'image',
    },
    {
        name: 'static-passage',
        path: 'static-passage',
        icon: 'static-passage-light',
        apiFn: 'getStaticPassage',
        type: 'image',
    },
    {
        name: 'static-stops',
        path: 'static-stops',
        icon: 'static-stops-light',
        apiFn: 'getStaticStops',
        type: 'image',
    },
    {
        name: 'heatmap-animation',
        path: 'heatmap-animation',
        icon: 'heatmap-animation-light',
        apiFn: 'getHeatmapAnimation',
        type: 'video',
    },
];

export const InternRouter = [
    {
        name: 'settings',
        img: 'param',
        url: '/setting',
    },
    {
        name: 'business',
        img: 'search',
        modalName: 'localBusinesses',
        url: 'modal-business',
    },
    {
        name: 'counting',
        img: 'comptage',
        url: '/counting',
        navDetail: [...NavDetailTabsCounting],
    },
    {
        name: 'journey',
        img: 'parcours',
        url: '/journey',
        navDetail: [...NavDetailTabsJourney],
    },
];

export const InternSettingsRouter = {
    accountSettings: {
        path: 'account-settings',
        children: {
            editPassword: { path: 'edit-password' },
            editFavoriteBusiness: { path: 'edit-favorite-business' },
        },
    },
    documentation: {
        path: 'documentation',
    },
    help: {
        path: 'help',
        children: {
            understandData: {
                path: 'help/understand-data',
                children: {
                    entry: {
                        path: 'help/understand-data-detail/entry/content',
                    },
                    passage: {
                        path: 'help/understand-data-detail/passage/content',
                    },
                    attractivity: {
                        path: 'help/understand-data-detail/attractivity/content',
                    },
                    transformationRateData: {
                        path: 'help/understand-data-detail/transformationRateData/content',
                    },
                    repartition: {
                        path: 'help/understand-data-detail/repartition/content',
                    },
                    attendanceTime: {
                        path: 'help/understand-data-detail/attendanceTime/content',
                    },
                    zonePassage: {
                        path: 'help/understand-data-detail/zonePassage/content',
                    },
                    zoneStops: {
                        path: 'help/understand-data-detail/zoneStops/content',
                    },
                    zoneAttractivity: {
                        path: 'help/understand-data-detail/zoneAttractivity/content',
                    },
                    zoneStopByPassage: {
                        path: 'help/understand-data-detail/zoneStopByPassage/content',
                    },
                    ageGender: {
                        path: 'help/understand-data-detail/ageGender/content',
                    },
                },
            },
            compareData: {
                path: 'help/compare-data',
                children: {
                    cumulData: { path: 'help/compare-data/cumulData/content' },
                    compareData: {
                        path: 'help/compare-data/compareData/content',
                    },
                },
            },
            understandColors: {
                path: 'help/understand-colors/understandColor/content',
            },
            understandMaps: {
                path: 'help/understand-maps',
                children: {
                    heatmapStatic: {
                        path: 'help/understand-maps/heatmapStatic/content',
                    },
                    heatmapAnimated: {
                        path: 'help/understand-maps/heatmapAnimated/content',
                    },
                    staticPassageHeatmap: {
                        path: 'help/understand-maps/staticPassageHeatmap/content',
                    },
                    passageHeatmapAnimated: {
                        path: 'help/understand-maps/passageHeatmapAnimated/content',
                    },

                    staticStopsHeatmap: {
                        path: 'help/understand-maps/staticStopsHeatmap/content',
                    },
                    animatedStopsHeatmap: {
                        path: 'help/understand-maps/animatedStopsHeatmap/content',
                    },
                    passageAnimated: {
                        path: 'help/understand-maps/passageAnimated/content',
                    },
                    dominantOrientationsHeatmap: {
                        path: 'help/understand-maps/dominantOrientationsHeatmap/content',
                    },
                    zoneToZone: {
                        path: 'help/understand-maps/zoneToZone/content',
                    },
                },
            },
        },
    },
    about: {
        path: 'abouts',
    },
    legals: {
        path: 'legals',
    },
};
