<div
    class="counting-data d-flex align-items-center justify-content-between my-1 row p-0 mx-0"
>
    <div class="col-2 d-flex center">
        <img src="/assets/svg/{{ img }}.svg" height="25px" class="{{ img }}" />
    </div>
    <div class="col-6 d-flex">
        <div
            class="d-flex flex-column align-items-start justify-content-center w-100"
        >
            <div class="m-0 ref-data">
                {{ (current | numberSuffix: decimalPosition) + ' ' + unit }}
            </div>
            <div class="comp-data">
                vs :
                {{ (versus | numberSuffix: decimalPosition) + ' ' + unit }}
            </div>
        </div>
    </div>

    <div
        class="col-4 rounded p-1 px-2 center d-flex flex-row variation-neutral"
        [ngClass]="{
            'variation-positive': variation > 0,
            'variation-negative': variation < 0
        }"
    >
        <div>
            {{ variation > 0 ? '+' : '' }}{{ variation | numberSuffix: 1 }}
        </div>
        <div class="ps-1">
            <small>{{ variationUnit }}</small>
        </div>
    </div>
</div>
