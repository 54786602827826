import PullToRefresh from 'pulltorefreshjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RefreshProvider {
    public init(translation: any) {
        const keys = Object.keys(translation);
        PullToRefresh.init({
            mainElement: 'body',
            onRefresh: this.refresh.bind(this),
            instructionsRefreshing: translation[keys[0]],
            instructionsPullToRefresh: translation[keys[1]],
            instructionsReleaseToRefresh: translation[keys[2]],
        });
    }

    public refresh() {
        window.location.reload();
    }
}
