import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InternRouter } from '@app/const';
import { ModalProvider, StoreProvider } from '@app/providers';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
    selector: 'app-nav-bar-tabs',
    templateUrl: './nav-bar-tabs.component.html',
    styleUrls: ['./nav-bar-tabs.component.scss'],
})
export class NavBarTabsComponent implements OnInit {
    public pageActive: any = false;
    public oldPageActive: string = '';

    public modal: NgbModalRef | boolean = false;

    @Input() isLogged: boolean = true;

    public navItems: {
        name?: string;
        img?: string;
        url: string;
        isHome?: boolean;
        modalName?: string;
        urlAlias?: string;
    }[] = InternRouter;

    private onCloseSub$!: Subscription;

    constructor(
        private router: Router,
        private modalProvider: ModalProvider,
        private store: StoreProvider
    ) {}

    public isActive(url: any) {
        return this.pageActive && this.pageActive.includes(url);
    }

    ngOnInit(): void {
        this.pageActive = this.router.url;
        this.oldPageActive = this.router.url;
        this.onCloseSub$ = this.modalProvider
            .listenClose()
            .subscribe((event) => {
                if (event) {
                    this.pageActive = this.router.url;
                }
            });
    }

    public goPage(item: any) {
        if (!this.isLogged) return;
        this.pageActive = item.url;
        this.oldPageActive = item.url;
        this.router.navigate([item.url]);
        this.store.emitReloadAll(true);

        if (item.navDetail) {
            this.store.emit({
                app: {
                    props: {
                        detailNav: {
                            items: item.navDetail,
                            active: item.navDetail[
                                Math.trunc(item.navDetail.length / 2)
                            ],
                        },
                    },
                },
            });
        }
    }

    public openModal(item: any) {
        if (!this.isLogged) return;
        this.pageActive = item.url;
        this.modal = this.modalProvider.open(item.modalName as any, {
            scrollable: false,
            modalDialogClass: 'localbusinesses-modal-content h-100',
        });

        firstValueFrom(this.modal.dismissed).then((closed) => {
            this.pageActive = this.oldPageActive;
        });
    }
}
