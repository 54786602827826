import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractSettingsComponent } from '@app/pages';
import { AuthService } from '@app/services';

@Component({
    selector: 'app-understand-data',
    templateUrl: './understand-data.component.html',
    styleUrls: ['./understand-data.component.scss'],
})
export class UnderstandDataComponent extends AbstractSettingsComponent {
    constructor(auth: AuthService, router: Router, route: ActivatedRoute) {
        super(auth, router, route);
    }
}
