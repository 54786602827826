import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    AppStore,
    BucketData,
    ChartApi,
    CountingApiData,
    LocalBusinesses,
    RangeBegin,
    PrincipalData,
    VisitsRepartitionData,
    GroupsRepartitionData,
    TimePassedInStoreData,
} from '@app/models';

import { CALENDAR_SELECT } from '@app/models/Calendar';
import {
    DateProvider,
    MathProvider,
    StoreProvider,
    UserProvider,
} from '@app/providers';

import { DataService } from '@app/services';
import { CountingService } from '@app/services';
import moment from 'moment-timezone';
import { forkJoin, map, Observable, switchMap, tap, filter } from 'rxjs';
import merge from 'ts-deepmerge';

@Component({
    templateUrl: './counting.component.html',
    styleUrls: ['./counting.component.scss'],
})
export class CountingComponent implements OnInit {
    public loading = true;

    public $app = this.$store.listen('app');
    public app!: AppStore;

    public page = 'main';

    public isToday!: boolean;

    public isDayOrWeek!: boolean;

    /* ------------ new route appMobile ------------ */

    private range!: RangeBegin;
    private format!: CALENDAR_SELECT;
    private localBusiness!: LocalBusinesses;
    private timezone = 'Europe/Paris';

    private init() {
        this.$store.refreshRoutingSubNav(false);
        this.$app.subscribe((app) => {
            this.$store.emitActionBar({ isFull: true });
            this.app = app;

            this.range = {
                begin: app.props.dates.current.start.asUnix,
                beginVs: app.props.dates.versus.start.asUnix,
            };

            this.localBusiness = app.props.selectedLocalBusiness;
            this.timezone = app.props.selectedLocalBusiness.timezone;
            this.format = app.props.dates.select;
        });
    }

    /* --------------------------------------------- */

    public $bucketData: Observable<BucketData> = this.$app.pipe(
        tap((app) => {
            this.app = app;
        }),
        filter(() => this.$store._getReloadAll()),

        switchMap((app) => {
            this.loading = true;

            let range = {
                begin: app.props.dates.current.start.asUnix,
                end: app.props.dates.current.end.asUnix,
                beginVs: app.props.dates.versus.start.asUnix,
                endVs: app.props.dates.versus.end.asUnix,
            };

            let hour = {
                openingBegin: this.dateProvider.getOpeningTime(
                    range.begin,
                    app.props.selectedLocalBusiness.openingCalendar
                ),
                openingBeginVs: this.dateProvider.getOpeningTime(
                    range.beginVs,
                    app.props.selectedLocalBusiness.openingCalendar
                ),
                closingEnd: this.dateProvider.getClosingTime(
                    range.end,
                    app.props.selectedLocalBusiness.openingCalendar
                ),
                closingEndVs: this.dateProvider.getClosingTime(
                    range.endVs,
                    app.props.selectedLocalBusiness.openingCalendar
                ),
            };

            this.isToday = this.dateProvider.checkIfToday(
                app.props.dates.select,
                app.props.dates.current.start.asUnix
            );
            this.isDayOrWeek = this.dateProvider.checkTypeIfDayOrWeek(
                app.props.dates.type
            );

            if (this.isToday) {
                let rangeAdapt = {
                    begin: app.props.dates.current.start.asUnix,
                    end: app.props.dates.current.end.asUnix,
                    beginVs: app.props.dates.versus.start.asUnix,
                    endVs: app.props.dates.versus.end.asUnix,
                };

                // rangeAdapt.begin adapt for opening hour
                rangeAdapt.begin = this.dateProvider.setHourInTimeStamp(
                    rangeAdapt.begin,
                    hour.openingBegin
                );
                // range.begin adapt for opening hour
                range.begin = this.dateProvider.setHourInTimeStamp(
                    range.begin,
                    hour.openingBegin
                );

                // rangeAdapt.beginVs adapt for opening hour
                rangeAdapt.beginVs = this.dateProvider.setHourInTimeStamp(
                    rangeAdapt.beginVs,
                    hour.openingBeginVs
                );
                // range.beginVs adapt for opening hour
                range.beginVs = this.dateProvider.setHourInTimeStamp(
                    range.beginVs,
                    hour.openingBeginVs
                );

                // rangeAdapt.end adapt for closing hour
                rangeAdapt.end = this.dateProvider.setHourInTimeStampCustom(
                    moment().tz(this.timezone).hour()
                );

                if (this.isDayOrWeek) {
                    rangeAdapt.endVs = this.dateProvider.dayLessOneType(
                        moment(rangeAdapt.end / 1000).tz(this.timezone),
                        app.props.dates.type
                    );
                    if (
                        app.props.dates.type === 'week' &&
                        app.props.dates.select === 'week'
                    ) {
                        let tmp = moment(rangeAdapt.endVs / 1000).tz(
                            this.timezone
                        );
                        rangeAdapt.endVs = tmp.set('hour', 23).unix() * 1000000;
                    }
                } else if (
                    (app.props.dates.type === 'month' &&
                        app.props.dates.select === 'week') ||
                    (app.props.dates.type === 'year' &&
                        app.props.dates.select === 'week')
                ) {
                    rangeAdapt.endVs = range.endVs;
                } else if (
                    app.props.dates.type === 'year' &&
                    app.props.dates.select === 'month'
                ) {
                    let tmp = moment(rangeAdapt.endVs / 1000).tz(this.timezone);
                    rangeAdapt.endVs = tmp.set('hour', 23).unix() * 1000000;
                } else if (
                    app.props.dates.type === 'month' &&
                    app.props.dates.select === 'month'
                ) {
                    let today = moment().tz(this.timezone).unix() * 1000000;
                    let tmp = moment(today / 1000).tz(this.timezone);
                    rangeAdapt.endVs =
                        tmp.subtract(1, 'month').set('hour', 23).unix() *
                        1000000;
                } else if (
                    app.props.dates.type === 'year' &&
                    app.props.dates.select === 'year'
                ) {
                    let today = moment().tz(this.timezone).unix() * 1000000;
                    let endOfMonth = moment(today / 1000)
                        .tz(this.timezone)
                        .endOf('month');
                    rangeAdapt.endVs =
                        endOfMonth.clone().subtract(1, 'year').unix() * 1000000;
                } else {
                    let endvs = range.endVs;
                    rangeAdapt.endVs = this.dateProvider.setDayHourOfDay(
                        moment(endvs / 1000).tz(this.timezone),
                        moment(rangeAdapt.end / 1000).tz(this.timezone)
                    );
                }

                return forkJoin([
                    this.dataService.getKpiData(
                        app.props.selectedLocalBusiness.id,
                        range,
                        app.props.dates.select
                    ),
                    this.dataService.getKpiData(
                        app.props.selectedLocalBusiness.id,
                        rangeAdapt,
                        app.props.dates.select
                    ),
                    this.dataService.getCountingDatasByType(
                        app.props.selectedLocalBusiness.id,
                        range,
                        app.props.dates.select
                    ),

                    /* ------------ new route appMobile ------------ */
                    this.countingService.getPrincipalDataCustom(
                        this.localBusiness.id!,
                        this.format,
                        rangeAdapt
                    ),
                    this.countingService.getVisitsRepartitionData(
                        this.localBusiness.id!,
                        this.format,
                        range
                    ),
                    this.countingService.getGroupsRepartitionData(
                        this.localBusiness.id!,
                        this.format,
                        range
                    ),
                    this.countingService.getTimePassedInStoreData(
                        this.localBusiness.id!,
                        this.format,
                        range
                    ),
                    /* --------------------------------------------- */
                ]);
            } else {
                // range.begin adapt for opening hour
                range.begin = this.dateProvider.setHourInTimeStamp(
                    range.begin,
                    hour.openingBegin
                );
                // range.beginVs adapt for opening hour
                range.beginVs = this.dateProvider.setHourInTimeStamp(
                    range.beginVs,
                    hour.openingBeginVs
                );
                // range.end adapt for closing hour
                range.end = this.dateProvider.setHourInTimeStamp(
                    range.end,
                    hour.closingEnd
                );
                // range.endVs adapt for closing hour
                range.endVs = this.dateProvider.setHourInTimeStamp(
                    range.endVs,
                    hour.closingEndVs
                );

                return forkJoin([
                    this.dataService.getKpiData(
                        app.props.selectedLocalBusiness.id,
                        range,
                        app.props.dates.select
                    ),
                    this.dataService.getCountingDatasByType(
                        app.props.selectedLocalBusiness.id,
                        range,
                        app.props.dates.select
                    ),
                    /* ------------ new route appMobile ------------ */
                    this.countingService.getPrincipalData(
                        this.localBusiness.id!,
                        this.format,
                        range
                    ),
                    this.countingService.getVisitsRepartitionData(
                        this.localBusiness.id!,
                        this.format,
                        range
                    ),
                    this.countingService.getGroupsRepartitionData(
                        this.localBusiness.id!,
                        this.format,
                        range
                    ),
                    this.countingService.getTimePassedInStoreData(
                        this.localBusiness.id!,
                        this.format,
                        range
                    ),
                    /* --------------------------------------------- */
                ]);
            }
        }),
        map((blockOfData: any) => {
            let kpiData!: CountingApiData;
            let kpiDataAdapt!: CountingApiData;
            let navDetailsData!: ChartApi;
            /* ------------ new route appMobile ------------ */
            let principalData: PrincipalData;
            let visitsRepartitionData: VisitsRepartitionData;
            let groupsRepartitionData: GroupsRepartitionData;
            let timePassedInStoreData: TimePassedInStoreData;
            /* --------------------------------------------- */

            if (this.isToday) {
                kpiData = blockOfData[0] as CountingApiData;
                kpiDataAdapt = blockOfData[1] as CountingApiData;
                navDetailsData = blockOfData[2] as ChartApi;
                /* ------------ new route appMobile ------------ */
                principalData = blockOfData[3] as PrincipalData;
                visitsRepartitionData = blockOfData[4] as VisitsRepartitionData;
                groupsRepartitionData = blockOfData[5] as GroupsRepartitionData;
                timePassedInStoreData = blockOfData[6] as TimePassedInStoreData;
                /* --------------------------------------------- */
            } else {
                kpiData = blockOfData[0] as CountingApiData;
                kpiDataAdapt = kpiData;
                navDetailsData = blockOfData[1] as ChartApi;
                /* ------------ new route appMobile ------------ */
                principalData = blockOfData[2] as PrincipalData;
                visitsRepartitionData = blockOfData[3] as VisitsRepartitionData;
                groupsRepartitionData = blockOfData[4] as GroupsRepartitionData;
                timePassedInStoreData = blockOfData[5] as TimePassedInStoreData;
                /* --------------------------------------------- */
            }

            const averageAllTimeSpend = {
                curent: kpiDataAdapt?.current?.timespent,
                versus: kpiDataAdapt?.versus?.timespent,
            };

            const averageEntry = {
                curent: navDetailsData?.current?.averageEntry,
                versus: navDetailsData?.versus?.averageEntry,
            };
            const averageEntryGroup = {
                curent: navDetailsData?.current?.averageEntryGroup,
                versus: navDetailsData?.versus?.averageEntryGroup,
            };

            const isNegative = (
                this.mathProvider.getVariationRate(
                    kpiDataAdapt.versus?.customers,
                    kpiDataAdapt.current?.customers
                ) + ''
            ).includes('-');

            this.$store.emitRedBg(isNegative);

            const isAttractivity =
                this.app.props?.selectedLocalBusiness?.attractivityEnabled;

            const isGroup =
                this.app.props?.selectedLocalBusiness?.groupsCountingEnabled;

            const isFinancialData = !(
                this.app.props?.selectedLocalBusiness?.localBusinessFiancial ===
                    undefined ||
                this.app.props?.selectedLocalBusiness?.localBusinessFiancial ===
                    false
            );

            const isAttractivityKeys = ['wifi', 'video'];
            const dataKeys = ['current', 'versus'];

            dataKeys.forEach((_dataKey) => {
                (kpiDataAdapt as any)[_dataKey].visits = (kpiDataAdapt as any)[
                    _dataKey
                ][isAttractivityKeys[Number(isAttractivity)] + 'Visits'];
                (kpiDataAdapt as any)[_dataKey].passages = (
                    kpiDataAdapt as any
                )[_dataKey][
                    isAttractivityKeys[Number(isAttractivity)] + 'Passages'
                ];

                (kpiDataAdapt as any)[_dataKey].attractivenessRate =
                    this.mathProvider.getPercent(
                        (kpiDataAdapt as any)[_dataKey].visits,
                        (kpiDataAdapt as any)[_dataKey].passages
                    );

                if (isGroup) {
                    (kpiData as any)[_dataKey].transformRate =
                        this.mathProvider.getPercent(
                            (kpiData as any)[_dataKey].ticket,
                            (kpiData as any)[_dataKey]['videoGroupVisits']
                        );
                } else {
                    (kpiData as any)[_dataKey].transformRate =
                        this.mathProvider.getPercent(
                            (kpiData as any)[_dataKey].ticket,
                            (kpiData as any)[_dataKey].customers
                        );
                }
            });

            this.loading = false;

            this.$store.emitReloadAll(false);

            let wifiPassageEstimate = { current: 0, versus: 0 };
            let attractivityWifiEstimate = { current: 0, versus: 0 };
            if (!isAttractivity) {
                let wifiPassagesCurrent =
                    kpiDataAdapt.current.wifiPassages !== undefined
                        ? kpiDataAdapt.current.wifiPassages
                        : 0;
                let wifiPassagesVersus =
                    kpiDataAdapt.versus.wifiPassages !== undefined
                        ? kpiDataAdapt.versus.wifiPassages
                        : 0;

                let customersCurrent =
                    kpiDataAdapt.current.customers !== undefined
                        ? kpiDataAdapt.current.customers
                        : 0;

                let customersVersus =
                    kpiDataAdapt.versus.customers !== undefined
                        ? kpiDataAdapt.versus.customers
                        : 0;

                wifiPassageEstimate.current = wifiPassagesCurrent / 0.295;
                wifiPassageEstimate.versus = wifiPassagesVersus / 0.295;

                attractivityWifiEstimate.current =
                    wifiPassageEstimate.current === 0
                        ? 0
                        : (customersCurrent * 100) /
                          wifiPassageEstimate.current;

                attractivityWifiEstimate.versus =
                    wifiPassageEstimate.versus === 0
                        ? 0
                        : (customersVersus * 100) / wifiPassageEstimate.versus;
            }

            return merge(
                { countingDatasByType: navDetailsData },
                { averageAllTimeSpend: averageAllTimeSpend },
                { averageEntry: averageEntry },
                { averageEntryGroup: averageEntryGroup },
                { kpiData: kpiData },
                { kpiDataAdapt: kpiDataAdapt },
                { attractivityEnabled: isAttractivity },
                { groupsCountingEnabled: isGroup },
                { financialDataEnabled: isFinancialData },
                { attractivityWifi: attractivityWifiEstimate },
                { wifiPassage: wifiPassageEstimate },
                /* ------------ new route appMobile ------------ */
                { principalData: principalData },
                { visitsRepartitionData: visitsRepartitionData },
                { groupsRepartitionData: groupsRepartitionData },
                { timePassedInStoreData: timePassedInStoreData }
                /* --------------------------------------------- */
            ) as never as BucketData;
        })
    );

    constructor(
        public $store: StoreProvider,
        public mathProvider: MathProvider,
        private userProvider: UserProvider,
        private router: Router,
        private dataService: DataService,
        private dateProvider: DateProvider,
        private countingService: CountingService
    ) {}

    ngOnInit(): void {
        this.init();

        this.$store.refreshRoutingSubNav(false);
        this.$app.subscribe((app) => {
            this.$store.emitActionBar({ isFull: true });
            this.app = app;
        });
    }

    // Fast coded just for demo
    public disconect() {
        this.userProvider.updateUserdata({
            login: '',
            password: '',
            rememberOption: false,
            isLogged: false,
            isAllMainDataFetched: false,
            isFirstStart: true,
            disableAutoLogin: false,
            jwt: '',
        });

        this.router.navigate(['login'], { preserveFragment: false });
    }
}
