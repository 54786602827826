import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findItemByTerm',
})
export class ArrayFilterPipe implements PipeTransform {
    transform(data: any[], term: any, field: string): any {
        if (!data) return '';

        if (term === undefined) return data;

        return data.filter(function (activity: { [key: string]: any }) {
            return activity[field]?.toLowerCase()?.includes(term.toLowerCase());
        });
    }
}
