import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-nested-card-default',
    templateUrl: './nested-card.component.html',
    styleUrls: ['./nested-card.component.scss'],
})
export class NestedCardComponent {
    @Input() logoIvstore!: boolean;
    @Input() class?: string = '';
}
