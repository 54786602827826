export * from './counting/counting.component';
export * from './journey/journey.component';
export * from './settings/settings.component';
export * from './settings/about/about.component';
export * from './settings/help/help.component';
export * from './settings/documentation/documentation.component';
export * from './settings/legals/legals.component';
export * from './settings/user-settings/user-settings.component';
export * from './settings/abstract-settings';
export * from './login/login.component';
export * from './reset-password/reset-password.component';

export * from './settings/user-settings/edit-favorite-localbusiness/edit-favorite-localbusiness.component';
export * from './settings/user-settings/edit-password/edit-password.component';
export * from './layouts';

export * from './settings/help/understand-data/understand-data.component';
export * from './settings/help/understand-data/understand-data-detail/understand-data-detail.component';
export * from './settings/help/compare-data/compare-data.component';
export * from './settings/help/compare-data/compare-data-detail/compare-data-detail.component';
export * from './settings/help/understand-colors/understand-colors.component';
export * from './settings/help/understand-maps/understand-maps.component';
export * from './settings/help/understand-maps/understand-maps-detail/understand-maps-detail.component';
export * from './journey/journey.component';
