import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services';
import { AbstractSettingsComponent } from '../abstract-settings';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
})
export class HelpComponent extends AbstractSettingsComponent {
    constructor(auth: AuthService, router: Router, route: ActivatedRoute) {
        super(auth, router, route);
    }
}
