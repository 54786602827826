import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StoreProvider } from '@app/providers';
import { UserServices } from '@app/services';
import { AbstractResetComponent } from '..';

@Component({
    selector: 'app-ask-reset',
    templateUrl: './ask-reset.component.html',
    styleUrls: ['./ask-reset.component.scss'],
})
export class AskResetComponent
    extends AbstractResetComponent
    implements OnInit
{
    public askPasswordForm!: UntypedFormGroup;
    public submitted = false;

    constructor(
        router: Router,
        store: StoreProvider,
        userServices: UserServices,
        private formBuilder: UntypedFormBuilder
    ) {
        super(store, router, userServices);
    }

    ngOnInit() {
        this.askPasswordForm = this.formBuilder.group({
            usernameOrEmail: [this.$usernameOrEmail.value, Validators.required],
        });
    }

    public back() {
        this.router.navigate(['/login']);
    }

    public onSubmit() {
        this.submitted = true;
        if (this.askPasswordForm.valid) {
            this.$usernameOrEmail.next(
                this.askPasswordForm.controls['usernameOrEmail'].value
            );

            this.store.emit({
                'user-login': {
                    props: {
                        usernameOrEmail:
                            this.askPasswordForm.controls['usernameOrEmail']
                                .value,
                    },
                },
            });

            this.askNewPassword().subscribe({
                next: (value) => {
                    this.setToken(value.token);
                },
                complete: () => {
                    this.$showWaitingToken.next(true);
                    this.nextStep();
                },
                error: () => {
                    this.setError('ask-reset-sending-email');
                },
            });
        }
    }

    public updateFormByKey(key: string, value: any) {
        this.askPasswordForm.patchValue({ [key]: value });
    }

    public reset() {
        this.submitted = false;
        this.$showWaitingToken.next(false);
        this.removeError();
    }
}
