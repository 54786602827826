import { Component } from '@angular/core';

@Component({
    selector: 'app-legals',
    templateUrl: './legals.component.html',
    styleUrls: ['./legals.component.scss'],
})
export class LegalsComponent {
    constructor() {}
}
