import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CollisionProvider {
    public points: any = [];
    // Detect collisions beetwen 2 obj
    detectCollisions = async () => {
        let obj1;
        let obj2;

        for (let i = this.points.length; i--; ) {
            obj1 = this.points[i];
            for (let j = this.points.length - 1; j--; ) {
                obj2 = this.points[j];
                if (this.rectIntersect(obj1, obj2)) {
                    obj1.isColliding = true;
                    obj2.isColliding = true;
                }
            }
        }
    };

    // Reset collision state for all objects
    resetCollisionState = () => {
        for (let i = this.points.length; i--; ) {
            this.points[i].isColliding = false;
        }
    };

    // Check if 2 rect are in the same position
    rectIntersect = (obj1: any, obj2: any) => {
        if (
            obj2.x > obj1.w + obj1.x ||
            obj1.x > obj2.w + obj2.x ||
            obj2.y > obj1.h + obj1.y ||
            obj1.y > obj2.h + obj2.y
        ) {
            return false;
        }
        return true;
    };
}
