import { Injectable } from '@angular/core';
import { UserData } from '@app/models';
import { StorageProvider } from './storage.provider';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserProvider {
    private userData: BehaviorSubject<UserData> = new BehaviorSubject<UserData>(
        {
            login: '',
            password: '',
            rememberOption: false,
            isLogged: false,
            isAllMainDataFetched: false,
            isFirstStart: true,
            disableAutoLogin: false,
            jwt: false,
        }
    );
    private userData$: Observable<UserData> = this.userData.asObservable();

    constructor(private storageProvider: StorageProvider) {}

    /** Update local data for all view */
    public updateUserdata(userData: UserData, fn?: any): any {
        this.isUserDataExist()
            .toPromise()
            .then((_userData) => {
                const combined = { ..._userData, ...userData };
                this.userData.next(combined);
                this.storageProvider
                    .set('userData', combined)
                    .subscribe((e) => {
                        if (fn) {
                            setTimeout(() => {
                                fn();
                            });
                        }
                    });
            });
    }

    /**
     *
     * @returns Client local storage value, if not exist provide default object
     */
    public isUserDataExist(): Observable<UserData> {
        return this.storageProvider.get('userData').pipe(
            map((value) => {
                if (!value) {
                    return {
                        login: '',
                        password: '',
                        rememberOption: false,
                        isLogged: false,
                        isAllMainDataFetched: false,
                        isFirstStart: true,
                        jwt: false,
                    };
                }
                return value;
            })
        );
    }

    /**  Return user data */
    public getData(): Observable<UserData> {
        return this.userData$;
    }
}
