import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-counting-data',
    templateUrl: './counting-data.component.html',
    styleUrls: ['./counting-data.component.scss'],
})
export class CountingDataComponent {
    @Input() img?: string;
    @Input() imgWidth?: number;
    @Input() current?: number;
    @Input() versus?: number;
    @Input() variation!: number;
    @Input() unit?: string = '';
    @Input() variationUnit?: string = '%';
    @Input() decimalPosition?: number = 0;
    @Input() decimalVariationPosition?: number = 2;
}
