import { Injectable } from '@angular/core';
import {
    ModalCalendarDefaultComponent,
    ModalJourneyDetailComponent,
    ModalLocalbusinessesSearchComponent,
    ModalProposalDateComponent,
} from '@app/components/modals';
import { ModalProposalDayComponent } from '@app/components/modals/modal-proposal-day/modal-proposal-day.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalProvider {
    private onClose$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    private onClose: Observable<any> = this.onClose$.asObservable();

    public emitClose(isRed: boolean) {
        this.onClose$.next(isRed);
    }
    public listenClose() {
        return this.onClose;
    }

    private modals = {
        calendar: ModalCalendarDefaultComponent,
        localBusinesses: ModalLocalbusinessesSearchComponent,
        journeyDetail: ModalJourneyDetailComponent,
        proposeValidDate: ModalProposalDateComponent,
        proposeDay: ModalProposalDayComponent,
    };

    private openedModals: any = {
        calendar: null,
        localBusinesses: null,
        journeyDetail: null,
    };
    constructor(private modalService: NgbModal) {}

    public open(
        modal:
            | 'calendar'
            | 'localBusinesses'
            | 'journeyDetail'
            | 'proposeValidDate'
            | 'proposeDay',
        opts?: NgbModalOptions
    ) {
        const modalInstance = this.modalService.open(
            this.modals[modal],
            opts || {}
        );

        this.openedModals[modal] = {
            isOpen: true,
            instance: modalInstance,
        };

        return modalInstance;
    }

    public close(
        modal:
            | 'calendar'
            | 'localBusinesses'
            | 'journeyDetail'
            | 'proposeValidDate'
            | 'proposeDay'
    ) {
        if (this.openedModals[modal].isOpen && modal === 'localBusinesses') {
            this.onClose$.next(true);
        }

        if (this.openedModals[modal].isOpen) {
            this.openedModals[modal].instance.close();
            this.openedModals[modal] = null;
        }
    }
}
