import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ZoneServices {
    constructor(private http: HttpClient) {}

    public getZonesByBox(boxId: number): Observable<any> {
        return this.http.get<any>(
            `${environment.backendDomain}/boxes/${boxId}/zones`
        );
    }

    public getZoneById(zoneId: number): Observable<any> {
        return this.http.get<any>(
            `${environment.backendDomain}/zones/${zoneId}`
        );
    }
}
