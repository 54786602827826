<div
    class="h-100 w-100 position-relative row p-0 m-0 mt-2 h-100"
    *ngIf="componentCanLoad; else componentCantLoad"
>
    <div class="col-12 h-20 p-0 m-0 flex-column center">
        <app-dropdown-default
            [boxes]="boxes"
            [name]="this.data.name || ''"
            [box]="selectedBox"
            (selected)="setBoxSelected($event)"
        ></app-dropdown-default>
    </div>
    <div
        class="text white d-flex center flex-column"
        *ngIf="app?.props?.detailNav?.active?.name === 'zone-to-zone'"
    >
        <small *ngIf="selectedZone; else noZoneSelect">{{
            selectedZone
        }}</small>
        <small *ngIf="noTransitionForThisZone && !errorNotTransitions"
            >Aucune transition disponible pour cette zone pour cette
            periode.</small
        >
        <ng-template #noZoneSelect>
            <small *ngIf="errorNotTransitions"
                >Aucune transition disponible pour le moment</small
            >
            <small *ngIf="!errorNotTransitions"
                >Vous pouvez sélectionner une zone pour plus de détails.</small
            >
        </ng-template>
    </div>
    <div
        class="text-center dark"
        *ngIf="this.boxes?.length !== 1"
        (click)="switchBoxSelected(selectedBox)"
    >
        <img
            src="/assets/svg/arrow/arrow-bottom-light.svg"
            alt=""
            width="20px"
        />
    </div>
    <div class="col-12 h-80">
        <div class="row h-100">
            <div
                class="align-self-center fadeInFast m-auto position-absolute text-white text-center"
                [ngStyle]="{ 'z-index': 99 }"
                *ngIf="state.loading"
            >
                <app-loader-default
                    [label]="'loading.file.' + data.type"
                    [state]="state"
                ></app-loader-default>
            </div>

            <div
                class="align-self-center fadeInFast m-auto position-absolute text-white text-center"
                [ngStyle]="{ 'z-index': 99 }"
                *ngIf="state.error"
            >
                <app-loader-default
                    [label]="'errors.file.' + data.type"
                    [state]="state"
                ></app-loader-default>
            </div>
            <div
                *ngIf="blob"
                class="col-12 fadeInFast center p-0 m-0 _first_chart_container_ h-100 w-100"
                [ngSwitch]="blob.type"
            >
                <div
                    *ngSwitchCase="type.video"
                    class="w-100 h-100 position-relative"
                    (click)="toogleVideo()"
                >
                    <video
                        id="video-player"
                        preload="metadata"
                        class="video-js vjs-default-skin w-100 h-100"
                        controls
                    ></video>
                </div>
                <div
                    *ngSwitchDefault
                    #JourneyDetailCanvasWrap
                    class="journey_background_wrap h-100 w-100"
                >
                    <div
                        class="h-100 w-100 d-flex position-absolute _z-i-1 top-0 start-0"
                    >
                        <canvas #JourneyDetailCanvas width="100%" height="100%">
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #componentCantLoad>
    <div class="w-100 position-relative row p-0 m-0 mt-2 h-100">
        <div
            class="col-12 h-20 p-0 m-0 mb-1 flex-column text-center center text-white"
        >
            {{ 'errors.indaliveDateJourney' | translate }}
        </div>
    </div></ng-template
>
