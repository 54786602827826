import Pikaday, { PikadayOptions } from 'pikaday';
import moment from 'moment';
export class Calendar {
    private pikaday!: Pikaday;
    constructor(element: HTMLElement, ops?: PikadayOptions) {
        this.pikaday = new Pikaday({
            field: element,
            showWeekNumber: true,
            ...ops,
        });
    }

    public goToday(): void {
        this.pikaday.gotoToday();
    }
    public goToMonth(index: number): void {
        this.pikaday.gotoMonth(index);
    }
    public goToYear(index: number): void {
        this.pikaday.gotoYear(index);
    }

    public slideLeft(): void {
        this.pikaday.prevMonth();
    }
    public slideRight(): void {
        this.pikaday.nextMonth();
    }

    public getDate(): Date | null {
        return this.pikaday.getDate();
    }

    public setStartRange(date?: Date): void {
        return this.pikaday.setStartRange(date as Date);
    }

    public setEndRange(date?: Date): void {
        return this.pikaday.setEndRange(date as Date);
    }

    public destroy(): void {
        this.pikaday.destroy();
    }
}
