import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { CustomDateObject } from '@app/models/Calendar';
import { ZonesApi } from '@app/models/Zones';
import { ZonesProvider } from '@app/providers';
import { ZoneServices } from '@app/services';
import { BackgroundService } from '@app/services/background.service';
import { Canvas } from 'fabric/fabric-impl';
import { Subscription, forkJoin } from 'rxjs';

@Component({
    selector: 'app-journey-background',
    templateUrl: './journey-background.component.html',
    styleUrls: ['./journey-background.component.scss'],
})
export class JourneyBackgroundComponent implements AfterViewInit, OnDestroy {
    @Input() boxId!: any;
    @Input() zoneId!: any;
    @Input() background!: string;
    @Input() dates?: CustomDateObject;
    @Input() type?: 'by-date' | 'last-background' = 'last-background';

    public loading = true;

    @ViewChild('JourneyBackground')
    JourneyBackground!: ElementRef<HTMLCanvasElement>;

    @ViewChild('JourneyBackgroundWrap')
    Wrap!: ElementRef<HTMLElement>;

    private _canvas!: Canvas;

    private background$!: Subscription;

    private scale = 0;

    constructor(
        private zoneProvider: ZonesProvider,
        private backgroundService: BackgroundService,
        private zoneServices: ZoneServices
    ) {}

    ngAfterViewInit(): void {
        this.loading = true;
        this._canvas = this.zoneProvider.generate(
            this.JourneyBackground.nativeElement,
            {
                containerClass:
                    'w-100 h-100 position-relative d-flex align-content-center justify-content-center',
            }
        );

        const date = this.dates!.current!.start.asMoment.clone();

        date.hour(23);
        date.minute(0);
        date.second(0);
        date.millisecond(0);

        this.background$ = this.getByBackground();
    }

    private getByBackground() {
        return forkJoin([
            this.backgroundService.getLastBackground(
                this.boxId,
                this.dates?.current?.end.asUnix
            ),
            this.zoneServices.getZonesByBox(this.boxId),
        ]).subscribe((bucketData) => {
            const background = bucketData[0];
            const zones: ZonesApi[] = bucketData[1];

            this.zoneProvider.background(background.data).then(() => {
                const bgSize = this.zoneProvider.getBackgroundSize();
                this.scale = Math.min(
                    (this.Wrap.nativeElement.clientWidth as number) /
                        bgSize.width,
                    (this.Wrap.nativeElement.clientHeight as number) /
                        bgSize.height
                );

                this.zoneProvider.setBackgroundScale(this.scale);

                const zone = zones.find((_zone) => _zone.id === this.zoneId);
                this.addZonesToCanvas(zone);
                setTimeout(() => {
                    this.zoneProvider.setCanvasSize(
                        bgSize.width,
                        bgSize.height,
                        this.scale
                    );
                    this._canvas.renderAll();

                    this.loading = false;
                });
            });
        });
    }

    private addZonesToCanvas(zone: any) {
        // Zone data init part
        zone!.data!.push(zone!.data![0]);
        zone.data = zone!.data!.map((val: any) => {
            return { x: val.x * this.scale, y: val.y * this.scale };
        });
        const polyline = this.zoneProvider.addPolyline(
            zone!.data,
            true,
            zone.color,
            zone.color,
            zone.id
        );

        this.zoneProvider.addAndRender(polyline);
    }

    ngOnDestroy(): void {
        if (this.background$) {
            this.background$.unsubscribe();
        }
        if (this._canvas) {
            this.zoneProvider.destroyAndClean();
        }
    }
}
