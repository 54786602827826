<div
    class="nav-bar-tabs bg-dark light d-flex flex-row row w-100 p-0 m-0 position-fixed bottom-0 h-10"
    [ngStyle]="{ opacity: isLogged ? 1 : 0 }"
>
    <div
        *ngFor="let item of navItems"
        class="col-3 h-100 d-flex flex-column p-0 m-0"
        (click)="item?.modalName ? openModal(item) : goPage(item)"
    >
        <div *ngIf="!isActive(item.url); else itemSelected" class="h-100">
            <div
                class="center d-flex flex-column _nav_icon h-55 position-relative nav-item-icon"
            >
                <img
                    src="/assets/svg/nav/{{ item.img }}-white.svg"
                    height="20px"
                />
            </div>

            <div class="w-100 center h-45 nav-link">
                {{ 'nav.' + item.name | translate }}
            </div>
        </div>
        <ng-template #itemSelected>
            <div
                class="center d-flex flex-column _nav_icon h-55 position-relative nav-item-icon"
            >
                <img
                    src="/assets/svg/nav/{{ item.img }}-select.svg"
                    height="20px"
                />
            </div>

            <div class="w-100 center h-45 activ-nav">
                {{ 'nav.' + item.name | translate }}
            </div>
        </ng-template>
    </div>
</div>
