import {
    Component,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnChanges,
    OnDestroy,
    Input,
} from '@angular/core';
import { Chart } from 'chart.js';
import dataLabelsPlugin, { Context } from 'chartjs-plugin-datalabels';
import { GenderData } from '@models/Counting/AgeGender';

@Component({
    selector: 'app-pie-chart-gender',
    templateUrl: './pie-chart-gender.component.html',
    styleUrls: ['./pie-chart-gender.component.scss'],
})
export class PieChartGenderComponent
    implements AfterViewInit, OnChanges, OnDestroy
{
    @Input() genderData!: GenderData;

    @ViewChild('genderCanvas', { static: false })
    canvasGender!: ElementRef<HTMLCanvasElement>;
    private genderChart!: Chart;

    ngAfterViewInit() {
        this.register();
        this.initGenderChart();
    }

    ngOnChanges() {
        setTimeout(() => {
            this.genderChart.update();
        }, 50);
    }

    private register() {
        Chart.register(dataLabelsPlugin);
    }

    private initGenderChart() {
        //@ts-ignore
        this.genderChart = new Chart(this.canvasGender.nativeElement, {
            type: 'pie',
            data: {
                datasets: [
                    {
                        data: [this.genderData.women, this.genderData.men],
                        backgroundColor: ['#C75F9B', '#024F5D'],
                        borderColor: ['#C75F9B', '#024F5D'],
                        borderWidth: 1,
                        datalabels: {
                            labels: {
                                value: {
                                    color: 'white',
                                },
                            },
                        },
                    },
                ],
            },
            options: {
                responsive: true,
                circumference: 180,
                rotation: -90,
                cutout: '50%',
                scales: {
                    y: { beginAtZero: false, display: false },
                    x: { beginAtZero: false, display: false },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    title: {
                        display: false,
                    },
                    datalabels: {
                        font: {
                            weight: 'bold',
                            size: 20,
                            family: 'Helvetica',
                        },
                        // Permet de ne peut pas afficher les % en dehors du graphique
                        align:
                            (this.genderData.men >= 0 &&
                                this.genderData.men < 2) ||
                            (this.genderData.women >= 0 &&
                                this.genderData.women < 2)
                                ? 'top'
                                : 'center',
                        formatter: (value: any, ctx: Context) => {
                            return value + '%';
                        },
                    },
                },
            },
        });
    }

    ngOnDestroy() {
        this.genderChart.destroy();
    }
}
