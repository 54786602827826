import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LocalBusinessesServices {
    constructor(private http: HttpClient) {}

    public getBusinesses(boxDetail?: boolean): Observable<any> {
        return this.http.get<any>(
            `${environment.backendDomain}/localbusinesses`,
            {
                params: { boxDetails: boxDetail || false } as never,
            }
        );
    }

    public getHours(localBusiness: any) {
        return this.http
            .get<any>(
                `${environment.backendDomain}/localbusinesses/${localBusiness.id}/settings`
            )
            .pipe(
                map((val) => val.openingCalendar),
                map((calendar) => {
                    const _calendar: any = {};
                    Object.keys(calendar).forEach((day) => {
                        _calendar[day as never] =
                            calendar[day] !== 'closed'
                                ? calendar[day]
                                : { openingTime: false, closingTime: false };
                    });
                    return _calendar;
                })
            );
    }
}
