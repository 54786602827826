<div class="col-12 p-2">
    <div
        ngbDropdown
        class="center w-100 mb-4"
        *ngIf="this.boxes?.length !== 1; else nameOfBox"
    >
        <button
            class="btn btn-outline-primary px-5 py-1"
            id="dropdownBasic1"
            ngbDropdownToggle
            (click)="openDropdown()"
        >
            {{ boxSelected.name }}
            <img src="/assets/svg/arrow/arrow-{{ dropDownArrow }}.svg" alt="" />
        </button>
        <div
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
            class="mt-2 dropdownBasic"
        >
            <button
                ngbDropdownItem
                *ngFor="let box of boxes"
                (click)="changeBoxSelected(box)"
                class="center w-100"
            >
                {{ box.name }}
            </button>
        </div>
    </div>
    <ng-template #nameOfBox>
        <p class="boxName text-center dark">
            {{ boxSelected.name }}
        </p>
    </ng-template>
</div>
