import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox-default',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
    @Input() checked: boolean = false;
    @Input() label: string = '';
    @Output() cliked = new EventEmitter();

    public onClick(value: any) {
        this.cliked.emit(this.checked);
    }
}
