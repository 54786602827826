import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LocalBusinessServices {
    constructor(private http: HttpClient) {}

    public getExemple(id: string | number): Observable<any[]> {
        return this.http.get<any[]>(`${environment.backendDomain}/`);
    }
}
