import { Component } from '@angular/core';

@Component({
    selector: 'app-helps',
    templateUrl: './helps.component.html',
    styleUrls: ['./helps.component.scss'],
})
export class HelpsComponent {
    constructor() {}
}
