import { Component, OnInit } from '@angular/core';
import { AppStore } from '@app/models';
import {
    DateProvider,
    MathProvider,
    ModalProvider,
    StoreProvider,
    SwiperProvider,
} from '@app/providers';
import { DataService } from '@app/services';
import { CountingService } from '@app/services';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { filter, forkJoin, map, Observable, switchMap, tap } from 'rxjs';

@Component({
    templateUrl: './journey.component.html',
    styleUrls: ['./journey.component.scss'],
})
export class JourneyComponent implements OnInit {
    private timezone = 'Europe/Paris';

    public loading = true;
    public $app = this.$store.listen('app');
    public app!: AppStore;

    public page = 'main';

    public isEmpty: boolean = false;

    public visibleRows: any[] = [];

    public isToday!: boolean;

    public $bucketData: Observable<any> = this.$app.pipe(
        filter(() => this.$store._getReloadAll()),
        switchMap((app) => {
            this.loading = true;
            const range = {
                begin: app.props.dates.current.start.asUnix,
                end: app.props.dates.current.end.asUnix,
                beginVs: app.props.dates.versus.start.asUnix,
                endVs: app.props.dates.versus.end.asUnix,
            };

            function changerHoursTimestamp(timestamp: number, hours: number) {
                var date = new Date(timestamp);
                date.setUTCHours(hours);
                date.setUTCMinutes(0);
                date.setUTCSeconds(0);
                var nouveauTimestamp = date.getTime();
                return nouveauTimestamp;
            }

            const range2 = {
                begin: changerHoursTimestamp(range.begin, 2),
                end: changerHoursTimestamp(range.end, 23),
                beginVs: changerHoursTimestamp(range.beginVs, 2),
                endVs: changerHoursTimestamp(range.endVs, 23),
            };

            let rangeAdapt = {
                begin: app.props.dates.current.start.asUnix,
                end: app.props.dates.current.end.asUnix,
                beginVs: app.props.dates.versus.start.asUnix,
                endVs: app.props.dates.versus.end.asUnix,
            };

            let hour = {
                openingBegin: this.dateProvider.getOpeningTime(
                    range.begin,
                    app.props.selectedLocalBusiness.openingCalendar
                ),
                openingBeginVs: this.dateProvider.getOpeningTime(
                    range.beginVs,
                    app.props.selectedLocalBusiness.openingCalendar
                ),
                closingEnd: this.dateProvider.getClosingTime(
                    range.end,
                    app.props.selectedLocalBusiness.openingCalendar
                ),
                closingEndVs: this.dateProvider.getClosingTime(
                    range.endVs,
                    app.props.selectedLocalBusiness.openingCalendar
                ),
            };

            this.isToday = this.dateProvider.checkIfToday(
                app.props.dates.select,
                app.props.dates.current.start.asUnix
            );

            if (this.isToday) {
                range.endVs = this.dateProvider.dayLessOneType(
                    moment(),
                    app.props.dates.type
                );

                // rangeAdapt.begin adapt for opening hour
                rangeAdapt.begin = this.dateProvider.setHourInTimeStamp(
                    rangeAdapt.begin,
                    hour.openingBegin
                );
                // rangeAdapt.beginVs adapt for opening hour
                rangeAdapt.beginVs = this.dateProvider.setHourInTimeStamp(
                    rangeAdapt.beginVs,
                    hour.openingBeginVs
                );

                // rangeAdapt.end adapt for closing hour
                rangeAdapt.end = this.dateProvider.setHourInTimeStampCustom(
                    moment().tz(this.timezone).hour()
                );
                rangeAdapt.endVs = this.dateProvider.dayLessOneType(
                    moment(rangeAdapt.end / 1000).tz(this.timezone),
                    app.props.dates.type
                );
                return forkJoin([
                    this.dataService.getJourney(
                        app.props.selectedLocalBusiness.id,
                        range2
                    ),
                    this.countingService.getPrincipalDataCustom(
                        app.props.selectedLocalBusiness.id,
                        app.props.dates.select,
                        rangeAdapt
                    ),
                    this.dataService.getBoxesByBusiness(
                        app.props.selectedLocalBusiness.id
                    ),
                ]);
            } else {
                // range.begin adapt for opening hour
                range.begin = this.dateProvider.setHourInTimeStamp(
                    range.begin,
                    hour.openingBegin
                );
                // range.beginVs adapt for opening hour
                range.beginVs = this.dateProvider.setHourInTimeStamp(
                    range.beginVs,
                    hour.openingBeginVs
                );
                // range.end adapt for closing hour
                range.end = this.dateProvider.setHourInTimeStamp(
                    range.end,
                    hour.closingEnd
                );
                // range.endVs adapt for closing hour
                range.endVs = this.dateProvider.setHourInTimeStamp(
                    range.endVs,
                    hour.closingEndVs
                );

                return forkJoin([
                    this.dataService.getJourney(
                        app.props.selectedLocalBusiness.id,
                        range2
                    ),
                    this.countingService.getPrincipalData(
                        app.props.selectedLocalBusiness.id,
                        app.props.dates.select,
                        range
                    ),
                    this.dataService.getBoxesByBusiness(
                        app.props.selectedLocalBusiness.id
                    ),
                ]);
            }
        }),
        map((data: any) => {
            const journeyData = data[0];
            const boxes = data[2];
            Object.keys(journeyData?.current).length === 0
                ? (this.isEmpty = true)
                : (this.isEmpty = false);

            const numberOfVisits = data[1].current.nbPerson;
            const numberOfVisitsVs = data[1].versus.nbPerson;

            const customersVariation = this.mathProvider.getVariationRate(
                numberOfVisitsVs,
                numberOfVisits
            );

            var journeyDataToArray: any[] = [];
            const journeyDataToChartsScatter: any[] = [];

            if (!this.isEmpty) {
                Object.keys(journeyData.current).forEach((key) => {
                    journeyDataToArray.push({
                        id: key,
                        ...journeyData.current[key],

                        // versus passage
                        versusZonePassage:
                            journeyData.versus[key].currentZonePassage,
                        passageVariation: this.mathProvider.getVariationRate(
                            journeyData.versus[key].currentZonePassage,
                            journeyData.current[key].currentZonePassage
                        ),
                        // versus stop
                        versusStopCount:
                            journeyData.versus[key].currentStopCount,
                        stopVariation: this.mathProvider.getVariationRate(
                            journeyData.versus[key].currentStopCount,
                            journeyData.current[key].currentStopCount
                        ),

                        // stop by passage
                        currentStopByPassage: this.mathProvider.getPercent(
                            journeyData.current[key].currentStopCount,
                            journeyData.current[key].currentZonePassage
                        ),
                        versusStopByPassage: this.mathProvider.getPercent(
                            journeyData.versus[key].currentStopCount,
                            journeyData.versus[key].currentZonePassage
                        ),
                        stopByPassageVariation: this.mathProvider.getSubstract(
                            this.mathProvider.getPercent(
                                journeyData.versus[key].currentStopCount,
                                journeyData.versus[key].currentZonePassage
                            ),
                            this.mathProvider.getPercent(
                                journeyData.current[key].currentStopCount,
                                journeyData.current[key].currentZonePassage
                            )
                        ),

                        // attractivity
                        currentAttractivity: this.mathProvider.getPercent(
                            journeyData.current[key].currentZonePassage,
                            numberOfVisits
                        ),
                        versusAttractivity: this.mathProvider.getPercent(
                            journeyData.versus[key].currentZonePassage,
                            numberOfVisitsVs
                        ),
                        attractivityVariation: this.mathProvider.getSubstract(
                            this.mathProvider.getPercent(
                                journeyData.versus[key].currentZonePassage,
                                numberOfVisitsVs
                            ),
                            this.mathProvider.getPercent(
                                journeyData.current[key].currentZonePassage,
                                numberOfVisits
                            )
                        ),

                        timeInZoneHuman:
                            this.dateProvider.unixDurationToHumanFromSecond(
                                journeyData.current[key].stopsDurationInZone /
                                    journeyData.current[key].currentStopCount
                            ),
                    });
                });

                journeyDataToArray.sort((journey: any, journeyVs: any) =>
                    journey.currentZonePassage < journeyVs.currentZonePassage
                        ? 1
                        : -1
                );

                journeyDataToArray = journeyDataToArray.map(
                    (journey: any, index: number) => {
                        const isPassageVariationNegative =
                            journey.passageVariation < 0;
                        const isStopByPassageVariationNegative =
                            journey.stopByPassageVariation < 0;

                        journeyDataToChartsScatter.push({
                            x: journey.currentZonePassage,
                            y: journey.currentStopByPassage,
                            isPassageVariationNegative,
                            isStopByPassageVariationNegative,
                            rank: index + 1,
                        });

                        return {
                            ...journey,
                            rank: index + 1,
                            isPassageVariationNegative,
                            isStopByPassageVariationNegative,
                        };
                    }
                );
            }

            this.loading = false;

            return {
                tableData: journeyDataToArray,
                scatterData: journeyDataToChartsScatter,
                customers: numberOfVisits,
                customersVs: numberOfVisitsVs,
                customersVariation: customersVariation,
                boxes: boxes,
            };
        })
    );

    private modal!: NgbModalRef;

    constructor(
        public $store: StoreProvider,
        public mathProvider: MathProvider,
        private dataService: DataService,
        private countingService: CountingService,
        private dateProvider: DateProvider,
        private modalProvider: ModalProvider,
        private swiperProvider: SwiperProvider
    ) {}

    ngOnInit(): void {
        this.$store.refreshRoutingSubNav(false);
        this.$app.subscribe((app) => {
            this.setActionBarMode(
                app?.props?.detailNav?.active?.name === 'main'
            );
            this.app = app;
        });
    }

    public proposeGoodDate(type: string) {
        this.modal = this.modalProvider.open(
            type === 'date' ? 'proposeValidDate' : 'proposeDay',
            {
                centered: true,
            }
        );

        this.modal.componentInstance.view =
            this.app?.props?.detailNav?.active?.name;

        this.modal.dismissed.subscribe(() => {
            this.$store.refreshRoutingSubNav(false);
            this.swiperProvider.setIndex(
                Math.trunc(this.app.props!.detailNav!.items!.length / 2)
            );
        });
    }

    public setActionBarMode(fullActionBar: boolean) {
        this.$store.emitActionBar({ isFull: fullActionBar });
    }

    public setVisiblesRows(visibleRows: any[]) {
        this.visibleRows = visibleRows;
    }
}
