import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { CustomDateObject } from '@app/models/Calendar';
import { MathProvider, ModalProvider, ScrollProvider } from '@app/providers';
import { NgbModalRef, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-journey-table',
    templateUrl: './journey-table.component.html',
    styleUrls: ['./journey-table.component.scss'],
})
export class JourneyTableComponent implements AfterViewInit {
    @Input() tableData: any;
    @Input() customers: any;
    @Input() customersVs: any;
    @Input() customersVariation: any;
    @Input() dates: CustomDateObject | any;

    public modal: NgbModalRef | boolean = false;

    @Output() isScroll: EventEmitter<any> = new EventEmitter();

    private visibleRows!: any;

    constructor(
        public mathProvider: MathProvider,
        public modalProvider: ModalProvider,
        private scrollProvider: ScrollProvider
    ) {}

    ngAfterViewInit(): void {
        this.checkIfRowIsVisible();
        this.emitVisibleTableData();
    }

    public openModal(dataZone: any) {
        this.modal = this.modalProvider.open('journeyDetail' as any, {
            scrollable: false,
            modalDialogClass: 'h-100 p-0 m-0',
        });

        this.modal.componentInstance.dates = this.dates;
        this.modal.componentInstance.dataZone = dataZone;
        this.modal.componentInstance.customers = this.customers;
        this.modal.componentInstance.customersVs = this.customersVs;
        this.modal.componentInstance.customersVariation =
            this.customersVariation;
    }

    @HostListener('scroll', ['$event'])
    public onScroll(): void {
        this.checkIfRowIsVisible();
        this.emitVisibleTableData();
    }

    private emitVisibleTableData() {
        this.isScroll.emit(this.visibleRows);
    }

    private checkIfRowIsVisible() {
        this.visibleRows = [];
        this.visibleRows = this.tableData.map(
            (tableData: any, index: number) => {
                return {
                    isVisible: this.elementIsVisible(index),
                    id: tableData.id,
                    rank: tableData.rank,
                };
            }
        );
    }

    private elementIsVisible(index: number) {
        return this.scrollProvider.isElementInViewPort(
            document.getElementById(
                'journey_table_row_' + index
            ) as HTMLElement,
            document.getElementById('scroll-container') as HTMLElement
        );
    }
}
