import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services';
import { AbstractSettingsComponent } from './abstract-settings';
import { environment } from '@environments/environment';
@Component({
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent extends AbstractSettingsComponent {
    constructor(auth: AuthService, router: Router, route: ActivatedRoute) {
        super(auth, router, route);
    }
}
