import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    AskResetComponent,
    ConfirmResetComponent,
    DoResetComponent,
} from '@app/components';
import { DefaultStore } from '@app/models';
import { StoreProvider, UserProvider } from '@app/providers';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    public resetStepsComponents = [
        AskResetComponent,
        ConfirmResetComponent,
        DoResetComponent,
    ];

    public actualStep = 0;

    private store$!: Subscription;

    constructor(
        private route: ActivatedRoute,
        private storeProvider: StoreProvider,
        private userProvider: UserProvider
    ) {}

    ngOnInit(): void {
        if (
            this.route.snapshot.queryParams['id'] &&
            this.route.snapshot.queryParams['token']
        ) {
            this.storeProvider.emit({
                'reset-steps': {
                    props: {
                        step: 2,
                        id: this.route.snapshot.queryParams['id'],
                        token: this.route.snapshot.queryParams['token'],
                    },
                },
            });
        } else {
            this.storeProvider.emit({
                'reset-steps': {
                    props: {
                        step: 0,
                    },
                },
            });
        }

        this.store$ = this.storeProvider
            .listen('reset-steps')
            .subscribe((resetData) => {
                this.actualStep = resetData.props.step;
            });

        this.userProvider.updateUserdata({ disableAutoLogin: true });
    }

    ngOnDestroy(): void {
        this.store$.unsubscribe();
    }
}
