import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/services';
import { TemplateComponent } from './components/template/template.component';

// pages
import {
    CountingComponent,
    ResetPasswordComponent,
    LoginComponent,
    SettingsComponent,
    LayoutDataComponent,
    LayoutWithoutDataComponent,
    JourneyComponent,
    HelpComponent,
    AboutComponent,
    LegalsComponent,
    AccountSettingsComponent,
    EditFavoriteLocalbusinessComponent,
    EditPasswordComponent,
    UnderstandDataComponent,
    UnderstandDataDetailComponent,
    CompareDataComponent,
    CompareDataDetailComponent,
    UnderstandColorsComponent,
    UnderstandMapsComponent,
    UnderstandMapsDetailComponent,
    DocumentationComponent,
} from './pages';

const routes: Routes = [
    {
        path: '',
        component: LayoutDataComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'counting',
                pathMatch: 'full',
            },
            {
                path: 'counting',
                component: CountingComponent,
            },
            {
                path: 'counting/counting-detail',
                component: CountingComponent,
            },
        ],
    },
    {
        path: 'journey',
        component: LayoutDataComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: JourneyComponent,
            },
        ],
    },
    {
        path: 'login',
        component: LayoutWithoutDataComponent,
        children: [
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
                pathMatch: 'full',
            },
            {
                path: '',
                component: LoginComponent,
            },
        ],
    },
    {
        path: 'setting',
        component: LayoutWithoutDataComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: SettingsComponent },
            {
                path: 'account-settings',
                component: AccountSettingsComponent,
            },
            {
                path: 'edit-password',
                component: EditPasswordComponent,
            },
            {
                path: 'edit-favorite-business',
                component: EditFavoriteLocalbusinessComponent,
            },
            {
                path: 'help',
                component: HelpComponent,
            },
            {
                path: 'help/understand-data',
                component: UnderstandDataComponent,
            },
            {
                path: 'help/understand-data-detail/:itemKey/content',
                component: UnderstandDataDetailComponent,
            },
            {
                path: 'help/compare-data',
                component: CompareDataComponent,
            },
            {
                path: 'help/compare-data/:itemKey/content',
                component: CompareDataDetailComponent,
            },
            {
                path: 'help/understand-colors/:itemKey/content',
                component: UnderstandColorsComponent,
            },
            {
                path: 'help/understand-maps',
                component: UnderstandMapsComponent,
            },
            {
                path: 'help/understand-maps/:itemKey/content',
                component: UnderstandMapsDetailComponent,
            },
            { path: 'abouts', component: AboutComponent },
            { path: 'legals', component: LegalsComponent },
            { path: 'documentation', component: DocumentationComponent },
        ],
    },
    { path: 'template', component: TemplateComponent },
    { path: '**', redirectTo: 'counting' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
