<div class="h-100 w-100 position-relative row p-0 m-0 mt-2">
    <div
        class="h-100 w-100 position-relative row p-0 m-0"
        *ngIf="!emptyState; else emptyStateTemplate"
    >
        <div class="col-12 p-0 m-0 flex-column center">
            <div class="text">
                <div class="labelDisplay ref-text">
                    <div [ngSwitch]="calendarSelect">
                        <div *ngSwitchCase="'day'">
                            <span
                                >Nb de visiteurs / heure :
                                {{ averageDisplay.curent }}</span
                            >
                        </div>
                        <div *ngSwitchCase="'week'">
                            <span
                                >Nb de visiteurs / jour :
                                {{ averageDisplay.curent }}</span
                            >
                        </div>
                        <div *ngSwitchCase="'month'">
                            <span
                                >Nb de visiteurs / jour :
                                {{ averageDisplay.curent }}</span
                            >
                        </div>
                        <div *ngSwitchCase="'year'">
                            <span
                                >Nb de visiteurs / mois :
                                {{ averageDisplay.curent }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="text">
                <div class="labelDisplay comp-text">
                    vs: {{ averageDisplay.versus }}
                </div>
            </div>
        </div>

        <div
            class="col-12 h-100 overflow-scroll"
            style="height: 73% !important"
        >
            <div
                class="row"
                *ngFor="let label of getMaxArray(); let i = index"
                style="height: 3em"
            >
                <div class="col-2 center p-0 m-0 h-100 labelContainer">
                    <div class="text center h-100 flex-column">
                        <div
                            [ngSwitch]="calendarSelect"
                            class="text center h-100 flex-column"
                        >
                            <div *ngSwitchCase="'day'" class="labelXAxisVs">
                                {{ _labels[i] }}
                            </div>
                            <div *ngSwitchCase="'week'">
                                <div class="labelXAxis">
                                    {{ _labels[i] }}
                                </div>
                                <div class="labelXAxisVs">
                                    {{ _labelsVs[i] }}
                                </div>
                            </div>
                            <div *ngSwitchCase="'month'">
                                <div class="labelXAxis">
                                    {{ _labels[i] }}
                                </div>
                                <div class="labelXAxisVs">
                                    {{ _labelsVs[i] }}
                                </div>
                            </div>
                            <div *ngSwitchCase="'year'">
                                <div class="labelXAxis">
                                    {{ _labels[i] }}
                                </div>
                                <div class="labelXAxisVs">
                                    {{ _labelsVs[i] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 center p-0 m-0 _first_chart_container_ h-100">
                    <div class="_second_chart_container_ w-100 h-100">
                        <canvas
                            [id]="'chartRepartition' + i"
                            class="_chart_data_compare_x_axis_counting h-100 w-100"
                        ></canvas>
                    </div>
                </div>
                <div class="col-4 d-flex flex-row justify-content-around py-1">
                    <div class="row">
                        <div
                            class="col-5 d-flex flex-column justify-content-around align-items-center py-1"
                        >
                            <div
                                class="ref-text d-flex align-items-center"
                                style="height: 0px"
                            >
                                {{ _value[i] | roundedWithRules : 1 }}
                            </div>
                        </div>
                        <div
                            class="col-5 border-start d-flex flex-column justify-content-around align-items-center py-1"
                        >
                            <div
                                class="comp-text d-flex align-items-center"
                                style="height: 0px"
                            >
                                {{ _valueVs[i] | roundedWithRules : 1 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #emptyStateTemplate>
        <div class="col-12">
            <div class="center indic bg-greyOpacity radius-secondary p-3 m-3">
                {{ 'noDataToShow' | translate }}
            </div>
        </div>
    </ng-template>
</div>
