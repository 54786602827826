<div class="container_age">
    <canvas id="age_canvas" class="position-absolute" #ageCanvas> </canvas>
</div>

<img
    src="/assets/svg/age-gender/age/adult.svg"
    alt="Pictogramme Adulte"
    style="width: 10px; position: absolute; top: 30%; right: 3px"
/>

<img
    src="/assets/svg/age-gender/age/adult.svg"
    alt="Pictogramme Enfant"
    style="width: 15px; position: absolute; top: 25%; left: 0%"
/>

<div class="position-absolute bottom-0 py-3">
    <img
        src="/assets/svg/age-gender/age/age-picto.svg"
        alt="Pictogramme Genre/Age"
        style="width: 35px"
    />
</div>
