<div #JourneyBackgroundWrap class="journey_background_wrap h-100 w-100">
    <div
        class="h-100 w-100 d-flex position-absolute _z-i-1 top-0 start-0"
        *ngIf="loading"
    >
        <div class="align-self-center m-auto text-white">
            <app-loader-default label="loading.background"></app-loader-default>
        </div>
    </div>

    <canvas #JourneyBackground width="100%" height="100%"> </canvas>
</div>
