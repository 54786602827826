import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractSettingsComponent } from '@app/pages';
import { AuthService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-understand-data-detail',
    templateUrl: './understand-data-detail.component.html',
    styleUrls: ['./understand-data-detail.component.scss'],
})
export class UnderstandDataDetailComponent extends AbstractSettingsComponent {
    constructor(
        auth: AuthService,
        router: Router,
        route: ActivatedRoute,
        public translateService: TranslateService
    ) {
        super(auth, router, route);
    }
}
