<div
    class="input-group border-0 mb-3 p-1 ms-0 ps-0 text-start position-relative h-100 align-items-center"
>
    <div
        class="input-group-text p-0 m-0 px-1"
        style="border: none; background: transparent; width: 2rem; height: 2rem"
    >
        <img [src]="icon" width="100%" height="100%" />
    </div>
    <input
        [id]="'static_' + label + '_' + _index"
        type="text"
        class="form-control input_default ps-1"
        [ngClass]="classCustom"
        aria-describedby="emailHelp"
        [type]="type"
        [placeholder]="placeHolder | translate"
        [value]="value"
        (input)="emit(getValue($event))"
    />
    <ng-template>
        <div
            class="valid-tooltip"
            *ngIf="showIsValidLabel"
            [innerHtml]="isValidFeedBack || label + '_valid'"
        ></div>
        <div
            class="invalid-tooltip"
            *ngIf="showIsInValidLabel"
            [innerHtml]="isInvalidFeedBack || label + '_invalid'"
        ></div>
    </ng-template>
</div>
