/* tslint:disable */
declare global {
    interface String {
        _Capitalize(): string;
        _HoursToObject(): { hour: string; min: string };
    }
}

String.prototype._Capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype._HoursToObject = function () {
    const time = this.split(':');
    return { hour: time[0], min: time[1] };
};

export {};
