import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-journey-detail',
    templateUrl: './modal-journey-detail.component.html',
    styleUrls: ['./modal-journey-detail.component.scss'],
})
export class ModalJourneyDetailComponent {
    @Input() dataZone: any;
    @Input() customers: any;
    @Input() customersVs: any;
    @Input() customersVariation: any;
    @Input() dates: any;

    constructor(public activeModal: NgbActiveModal) {}
}
